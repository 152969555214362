import { CustomPaymentComponent } from './pages/custom-payment/custom-payment.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { CookieService } from 'ngx-cookie-service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SegmentModule } from 'ngx-segment-analytics';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../environments/environment';

import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { effects } from './shared/store/effects';
import { reducers } from './shared/store/reducers';
import { SocialModule } from './shared/social.module';
import { entryComponents } from './shared/entryComponents';
import { MaterialModule } from './shared/material.module';
import { BsModule } from './shared/bs.module';
import { BlockerComponent } from './pages/blocker/blocker.component';
import { InviteOnlyComponent } from './pages/blocker/invite-only/invite-only.component';
import { PendingRequestComponent } from './pages/blocker/pending-request/pending-request.component';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { MessagingService } from './core/services/messaging.service';
import { AsyncPipe } from '@angular/common';
import { PendingRequestMobileComponent } from './pages/blocker/pending-request-mobile/pending-request-mobile.component';
import { RenewalMembershipComponent } from './pages/blocker/renewal-membership/renewal-membership.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EncodeHttpParamsInterceptor } from './core/interceptors/encoded-interceptor';
import { EmailUnsubscribedComponent } from './pages/email-unsubscribed/email-unsubscribed.component';
import { FeedComponent } from './pages/feed/feed.component';
import { AddRoleComponent } from './shared/components/login/entryComponents/add-role/add-role.component';

declare global {
    interface Window {
        analytics: any;
    }
}

@NgModule({
    declarations: [
        BlockerComponent,
        AppComponent,
        InviteOnlyComponent,
        PendingRequestComponent,
        PendingRequestMobileComponent,
        RenewalMembershipComponent,
        CustomPaymentComponent,
        EmailUnsubscribedComponent,
        AddRoleComponent,
        // FeedComponent,
    ],
    imports: [
        CoreModule,
        BrowserModule,
        AppRoutingModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireStorageModule,
        AngularFireModule.initializeApp(environment.firebaseConfig, 'cloud'),
        HttpClientModule,
        StoreModule.forRoot(reducers),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpTranslateLoader,
                deps: [HttpClient],
            },
        }),
        ServiceWorkerModule.register('ngsw-worker.js, firebase-messaging-sw.js', {
            enabled: environment.production,
        }),
        SegmentModule.forRoot({ apiKey: environment.segMentKey, debug: false, loadOnInitialization: true }),
        EffectsModule.forRoot(effects),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: false,
            features: {
                pause: false,
                lock: true,
                persist: true,
            },
        }),
        SharedModule,
        SocialModule,
        BrowserAnimationsModule,
        MaterialModule,
        BsModule,
        InfiniteScrollModule,
        NgbModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EncodeHttpParamsInterceptor,
            multi: true,
        },
        MessagingService,
        AsyncPipe,
        CookieService,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
