<div class="dialogue-box">
    <div id="plan-arrow"></div>
    <div class="heading">Register for event</div>

    <div class="sub-heading" *ngIf="!data?.isCheckable && data?.membershipState != 1" >
        This event is open for the subscribed members of the community only. Buy a membership plan of your choice to register for this event.
    </div>

    <div class="sub-heading" *ngIf="!data?.isCheckable && data?.membershipState == 1" >
        This event is open for the subscribed members of the community only. Your subscription to “{{data?.community?.name}}” community has expired. Buy a membership plan of your choice to register for this event.
    </div>

    <div class="sub-heading" *ngIf="data?.isCheckable">
        Proceed to make payment to register for event: “{{data?.eventName}}”.
    </div>

    <div class="checkbox-holder">
        <label class="container">
            <div class="label_">Event registration</div>
            <div class="sub-label_" *ngIf="data?.isCheckable" >(&#8377;{{event_cost}} for members, &#8377;{{data?.eventCost?.strike_cost}} for non-members )</div>
            <input type="checkbox" checked="checked" (change)="handleEventCheckbox($event)" (click)="handleCheckBox()">
            <span class="checkmark" *ngIf="data?.isCheckable" [ngStyle]="{'margin-top': data?.isCheckable ? '25px' : '13px'}"></span>
            <span class="checkmark uncheckable" *ngIf="!data?.isCheckable" [ngStyle]="{'margin-top': data?.isCheckable ? '25px' : '13px'}"></span>
        </label>
    </div>

    <div class="checkbox-holder">
        <label class="container">
            <div class="top-heading d-flex justify-content-between" *ngIf="data?.isCheckable">
                <span class="offer">OFFER APPLIED</span>
                <img src="../../../../assets/images/svg/green-info.svg" (click)="openOffers()" alt="">
            </div>
            <div class="top-subheading" *ngIf="data?.isCheckable">Save min. &#8377;{{data?.eventCost?.strike_cost-event_cost}} on Event Registration</div>
            <div class="label_">{{ data?.membershipState==1 ? 'Renew membership' : 'Buy membership'}}</div>
            <input type="checkbox" (change)="handleCommunityCheckbox($event)" (click)="handleCheckBox()" checked="checked">
            <span class="checkmark" *ngIf="data?.isCheckable" [ngStyle]="{'margin-top': data?.isCheckable ? '55px' : '13px'}"></span>
            <span class="checkmark uncheckable" *ngIf="!data?.isCheckable" [ngStyle]="{'margin-top': data?.isCheckable ? '55px' : '13px'}"></span>
        </label>
    </div>

    <div class="payment-preview">
        <div class="payment-preview__box">
            <div class="payment-preview__box__text d-flex justify-content-between position-relative" [ngStyle]="{'text-decoration': !communityIsChecked ? 'line-through' : 'none'}">
                <span>Membership Plan({{selectedCommunityPlan?.name}}) 
                    <i class="arrow down cursor-pointer" *ngIf="!showPlansDropDown" (click)="showPlans()"></i> 
                    <i class="arrow up cursor-pointer" *ngIf="showPlansDropDown" [ngStyle]="{'margin-bottom':showPlansDropDown ? '-1px' : '2px' }" (click)="showPlansDropDown = !showPlansDropDown"></i> 
                </span>
                <span>&#8377;{{selectedCommunityPlan?.cost}}</span>

                <div class="position-absolute all-plans" *ngIf="false">
                    <div class="plans-container d-flex justify-content-between" (click)="changeSelectedPlan(i)" *ngFor="let plan of communityPlanObj?.plans; let i=index;">
                        <div class="plan-div">
                            <div class="plan-div__heading">Membership plan({{plan?.name}})</div>
                            <div class="plan-div__subheading"> <span *ngIf="plan?.strike_cost" class="strike-cost">&#8377;{{plan?.strike_cost}}</span>&#8377;{{plan?.plan_sub_title}}</div>
                         </div>
                         <img src="../../../../assets/images/svg/green-info.svg" alt="">
                    </div>
                </div>

            </div>

            <div class="payment-preview__box__text d-flex justify-content-between" [ngStyle]="{'text-decoration': !eventIsChecked ? 'line-through' : 'none'}">
                <span>Event cost</span> 
                <span> <span *ngIf="event_cost > 0 || data?.eventCost?.strike_cost > 0">&#8377;</span>{{communityIsChecked ? event_cost==0 ? 'FREE' : event_cost : data?.eventCost?.strike_cost ==0 ? 'FREE' : data?.eventCost?.strike_cost}}</span>
            </div>
        </div>
        <div class="total_cost d-flex justify-content-between">
            <span>Total Cost</span>
            <span>&#8377;{{totalCost}}</span>
        </div>
    </div>

    <div class="payment-button" (click)="redirectToPaymentPage()" >
        PROCEED TO PAYMENT
    </div>

</div>

<!-- PLANS DIV -->

<div *ngIf="showPlansDropDown" class="plans-div" [ngStyle]='{ top : distanceToTop }'>

    <div class="renewal-page">
        <main class="container-fluid p-0">
            <div class="d-flex flex-column">
                <div class="sub__header">
                    <div class="sub__header-content">
                        <div class="d-flex justify-content-center align-items-center">
                            <div class="image-div">
                                <img class="cursor-pointer" (click)="showPlansDropDown=false" src="../../../../assets/images/svg/back-arrow-white.svg" alt="back" />
                            </div>
                            <div>
                                <img class="profile-img" src="{{ community?.image_url }}" alt="" />
                            </div>
                            <div class="community-name-div d-flex">
                                <div class="membership-div"><b>Select Membership</b></div>
                                <div class="community-name">{{ community?.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="body__container">
                    <!-- p-3  -->
                    <div class="top__section">
                        <h2 class="text-center top__section--heading">Choose a Membership plan</h2>
                        <p class="text-center top__section-subheading">That best suits you.</p>
                    </div>
    
                    <div class="px-2 main__content">
                        <div class="row h-100 flex-container stretch">
                            <div class="h-100 col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngFor="let plan of communityPlanObj?.plans; let i = index">
                                <div class="h-100 p-4 text-color-white card position-relative">
                                    <div class="d-flex justify-content-between plan-header">
                                        <div class="plan-subheader">
                                            <!-- <h2 class="plan__name" >{{plan.duration_name.split("_")[0].substr()}}</h2> string.charAt(0).toUpperCase() + string.slice(1); -->
                                           <div class="d-flex align-items-center mb-2">
                                            <h2 class="mb-0" *ngIf="plan.duration_name.split('_').length == 1">
                                                {{
                                                    plan?.name
                                                }}
                                            </h2>
                                            <h2 class="mb-0" *ngIf="plan?.duration_name?.split('_')?.length > 1">
                                                {{
                                                    plan?.name
                                                }}
                                            </h2>

                                           </div>
                                            <h3 class="prev__price" *ngIf="plan?.strike_cost || plan?.strike_cost == 0">
                                                &#8377; {{ plan?.strike_cost }}
                                            </h3>
                                            <p class="cost">
                                                <!-- <b>Rs {{ plan.cost }} for {{ plan.duration_in_months }} month</b> -->
                                                <b>Rs.{{plan.plan_sub_title}}</b>
                                            </p>
                                            <!-- <p class="gst">(GST extra)</p> -->
                                        </div>
                                        <div>
                                            <img class="plan-image" [src]="plan.image" alt="Plan Image" />
                                        </div>
                                    </div>
    
                                    <div
                                        class="list-container"
                                        style="margin-bottom: 20px"
                                        [innerHtml]="plan.description"
                                    ></div>
    
                                    <ng-template>
                                        <div #isObject class="list-container">
                                            <ul class="p-2">
                                                <li *ngFor="let item of plan.description">{{ item }}</li>
                                            </ul>
                                        </div>
                                    </ng-template>
    
                                    <div class="razorpay-button-holder">
                                        <div>
                                            <button
                                                class="razorpay-button"
                                                (click)="changeSelectedPlan(i)"
                                            >
                                                SELECT
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
    
</div>    