<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g>
                    <g>
                        <g>
                            <g
                                transform="translate(-125 -466) translate(112 133) translate(0 98) translate(0 225) rotate(45 .186 40.935)">
                                <rect width="20" height="20" fill="#FFF" fill-opacity="0" class="stroke-brand" rx="10" />
                                <path class="fill-brand"
                                    d="M7.407 6.597L10.05 9.24l2.643-2.643c.2-.199.513-.216.733-.065l.078.065c.224.224.223.587 0 .81L10.86 10.05l2.543 2.543c.199.199.216.513.065.732l-.065.078c-.224.224-.587.223-.81 0L10.05 10.86l-2.542 2.543c-.224.224-.593.217-.81 0-.224-.224-.223-.587 0-.81L9.24 10.05 6.597 7.407c-.224-.223-.217-.593 0-.81.224-.224.587-.223.81 0z"
                                    opacity=".9" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>