import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-audio-icon',
  templateUrl: './add-audio-icon.component.html',
  styleUrls: ['./add-audio-icon.component.scss']
})
export class AddAudioIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
