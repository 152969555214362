import { Component, Inject } from "@angular/core";
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { CommunityDetailComponent } from '../../page/community-detail.component';
import { IUser } from 'src/app/shared/models/user.model';

@Component({
    selector: 'your-response-sheet',
    templateUrl: './your-response-sheet.component.html'
})

export class YourResponseSheetComponent {
    constructor(
        public bottomSheetRef: MatBottomSheetRef<CommunityDetailComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: IUser
    ) { }

    close(event) {
        this.bottomSheetRef.dismiss(event);
    }
}