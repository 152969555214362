<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g>
                    <g>
                        <g>
                            <g>
                                <g transform="translate(-96 -177) translate(80 169) translate(2 2) translate(16 8)">
                                    <circle cx="12" cy="12" r="12" class="fill-brand-light" stroke="#FFF" stroke-width="3"/>
                                    <g fill="#FFF" fill-rule="nonzero">
                                        <path d="M2 9L0 9 0 3 2 3zM6 9L4 9 4 0 6 0zM10 9L8 9 8 5 10 5 10 9z" transform="translate(7 8)"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
