<section class="modal-small p-2 position-relative">
    <div class="d-flex justify-content-end close postion-absolute t0 r0 hide-sm"><img class="cursor=pointer" (click)="dialogRef.close()"
            src="assets/images/svg/cross.svg" alt=""></div>
    <div class="body mx-2">
        <p class="heading d-flex justify-content-start fs16 mt-4 fw500">{{ data?.heading }}</p>

        <p class="content text-start">{{ data?.content }}</p>
    </div>
    <div class="fs13 fw500 d-flex justify-content-end text-uppercase mt-5 pe-4">
        <p (click)="leave()" class="link cursor-pointer">
            {{ data?.successBtnText }}</p>
        <p (click)="stay()" class="link cursor-pointer ps-5 cG">
            {{ data?.cancelBtnText }}</p>
    </div>
</section>