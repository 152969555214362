import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommunityCardComponent } from 'src/app/shared/components/community-card/community-card.component';
import { environment } from 'src/environments/environment';
import { MIXPANEL } from 'src/app/shared/enums/mixpanel.enum';
import { PLAYSTORE, ANDROID, IOS, APPSTORE } from 'src/app/shared/constants/app-constant';
import { AnalyticsService } from '../../../../core/services/analytics.service';

@Component({
    selector: 'download-app-pop-up',
    templateUrl: './download-app.component.html',
    styleUrls: ['./download-app.component.scss'],
})
export class DownloadAppComponent {
    constructor(
        public dialogRef: MatDialogRef<CommunityCardComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private analyticsService: AnalyticsService
    ) {}

    close() {
        this.dialogRef.close();
    }

    downloadApp(store: string) {
        const type = store === PLAYSTORE ? ANDROID : IOS;
        this.trackDownloadApp(type);
        if (store === APPSTORE) window.open(environment.appstoreLink, '_blank');
        else if (store === PLAYSTORE) window.open(environment.playstoreLink, '_blank');
    }

    download() {
        var userAgent = navigator.userAgent || navigator.vendor;

        if (/android/i.test(userAgent)) {
            this.trackDownloadApp(ANDROID);
            window.open(environment.playstoreLink, '_blank');
        }

        // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        //     this.trackDownloadApp(IOS);
        //     window.open(environment.appstoreLink, "_blank");
        // }
    }

    trackDownloadApp(type: string) {
        this.analyticsService.sendEvent(MIXPANEL.DOWNLOAD_APP, {
            source: 'pop_up',
            type,
        });
    }
}
