// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseUrl: 'https://betacaravan.likeminds.community/api',
    kettleBaseUrl: 'https://betaauth.likeminds.community',
    caravanBaseUrl: 'https://betacaravan.likeminds.community/api',
    xApiKey: '5f567ca1-9d74-4a1b-be8b-a7a81fef796f',
    subscriptionBaseUrl: 'https://betasubscription.likeminds.community/api',
    versionCode: '1010',
    baseUploadImgUrl: 'https://beta.likeminds.community',
    awsS3MediaUrl: 'https://beta-likeminds-media.s3.amazonaws.com',
    platformCode: 'web',
    gmailLoginBaseUrl: 'https://betaweb.likeminds.community',
    social: {
        googleClientId: '983690302378-vmcfu305q815j0n430t385to742s3epu.apps.googleusercontent.com',
        facebookAppId: '633859857057924',
        facebookSecret: 'f90d662fdb205e53f24a90009318a6d4',
        linkedinKey: '8616kilc3dqagd',
        linkedinSecret: 'g2LdZt5KN9HRF1IB',
        redirect_uri: 'https://betaweb.likeminds.community/linkedinurl',
    },
    firebaseConfig: {
        apiKey: 'AIzaSyBWjDQEiYKdQbQNvoiVvvOn_cbufQzvWuo',
        authDomain: 'collabmates-beta.firebaseapp.com',
        databaseURL: 'https://collabmates-beta.firebaseio.com',
        projectId: 'collabmates-beta',
        storageBucket: 'collabmates-beta.appspot.com',
        messagingSenderId: '983690302378',
        appId: '1:983690302378:web:b2fa2c58f2351d5c1b91d3',
        measurementId: 'G-R2PXYC9F4S',
    },
    firebasePlaceApiKey: 'AIzaSyCQFP-i-IkuIetXxuXXRYNBDimGYhUeFkU',
    firebaseURL: 'https://collabmates-beta.firebaseio.com/',
    vapidKey: 'BH7RhEM3kdPrZy-TDwOp6dPg7wH2nLa17V_c4DO_jfg-ih1L25fi8gNWxXNWWMK4eAC2-RqE8U5jAoCtjwRlmyo',
    playstoreLink: 'https://play.google.com/store/apps/details?id=com.collabmates',
    appstoreLink: 'https://apps.apple.com/us/app/likeminds-community-chat/id1526635028',
    mixpanelToken: 'eb1e03c8be370040278bff61a4857608',
    mixpanelSecret: '96323ac5976cb9f7a99a9f8ad442d702',
    segMentKey: 'XSqQmp7I37VBFr0jyq9sps472rE2JQbR',
    awsBucket: 'beta-likeminds-media',
    poolId: 'ap-south-1:181963ba-f2db-450b-8199-964a941b38c2',
    // webflow_site_id: '60c08a6f881afc050b7d5ba7',
    webflow_site_id: '60c06e2d415a126b462563d0',
    // webflow_domains: 'yuvrajs-amazing-project.webflow.io',
    webflow_domains: 'subscription-model.webflow.io',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
