<div *ngIf="showExpiredCommunityPopup" style="padding:5px 20px;" >
    <h3><b>Membership Expired</b></h3>
    <h4 *ngIf="screenType === 'desktop' || screenType === 'tab'" >Your subscription to "{{community.name}}" <br> community has expired. Buy a membership plan <br> of your choice to regain access to community <br> events, chat rooms and member profiles.</h4>
    <h4 *ngIf="screenType === 'mobile'">Your subscription to "{{community.name}}" community has expired. Buy a membership plan of your choice to regain access to community events, chat rooms and member profiles.</h4>
    <div class="d-flex justify-content-end">
        <div class="d-flex flex-column">
            <!-- <div (click)="openRenewMembership()" class="cursor-pointer" style="color:rgb(20,146,133)"><b>CHOOSE MEMBERSHIP PLAN</b></div>    -->
            <mat-dialog-actions mat-button mat-dialog-close (click)="openRenewMembership()" class="cursor-pointer" style="color:rgb(20,146,133)">
                <div style="color:rgb(20,146,133)" class="cursor-pointer">
                    <b>CHOOSE MEMBERSHIP PLAN</b>
                </div>
            </mat-dialog-actions>
            <div style="margin-bottom: 8px"></div>
            <br>
            <div class="cursor-pointer" style="color:red" (click) = "openLeaveCommunityPopup()" ><b>LEAVE COMMUNITY</b></div>
            <div style="margin-bottom: 5px"></div>
            <mat-dialog-actions mat-button mat-dialog-close>
                <div style="color:gray;" class="cursor-pointer">
                    <b>CANCEL</b>
                </div>
            </mat-dialog-actions>
            <!-- <div style="color: gray"><b>CANCEL</b></div> -->
            <br>
        </div>
    </div>
</div>
    

<div *ngIf="leaveCommunityFlag" style="padding:15px 30px;">
    <div style="padding-right: 15px;">
        <h3><b>Leave community?</b></h3>
        <h4 *ngIf="screenType === 'desktop' || screenType === 'tab'" style="margin-bottom: 0px; color: gray">Are you sure you want to leave "{{community.name}}" <br> permanently? Your community profile <br> would will be removed and all community chatrooms <br> including followed chatrooms would not <br> be accessible</h4>
        <h4 *ngIf="screenType === 'mobile'" style="margin-bottom: 0px; color: gray">Are you sure you want to leave "{{community.name}}" permanently? Your community profile would will be removed and all community chatrooms including followed chatrooms would not be accessible</h4>
        <br>  
    </div>
        
    <div class="d-flex justify-content-end">
        <mat-dialog-actions mat-button mat-dialog-close>
            <div style="color:gray;" class="cursor-pointer">
                <b>CANCEL</b>
            </div>
        </mat-dialog-actions>
        <mat-dialog-actions mat-dialog-close (click)="leaveCommunity()" >
            <div style="color:rgb(20,146,133) ; margin-left : 30px;" (click)="submitPaymentID()" class="cursor-pointer">
                <b>LEAVE NOW</b> 
            </div>
        </mat-dialog-actions>
    </div>
</div>


<div *ngIf="!showExpiredCommunityPopup && !leaveCommunityFlag" class="modal-box">
    <div>
        <h3 class="heading">Join Community</h3>
        <h4 *ngIf="screenType === 'desktop' || screenType === 'tab'" class="sub-heading">Join comunity using the invitation link you have received or by entering the invite code here.</h4>
        <h4 *ngIf="screenType === 'mobile'" class="sub-heading">Join comunity using the invitation link you have received or by entering the invite code here.</h4>
    </div>
 
    <form>
        <input [ngClass]="{'red-border': errorMessage}" [(ngModel)] = "joiningCode" name="joiningCode" id = 'joiningCode' class="input-box" type="text">
    </form> 

    <div class="error-message"  *ngIf="errorMessage" 
    >{{errorMessage}}</div>
    <!-- <br> -->

    <div class="d-flex justify-content-end">
        <!-- mat-button mat-dialog-close -->
        <mat-dialog-actions>
            <div class="button cancle-button" (click)="closeModal()">
                CANCEL
            </div>
        </mat-dialog-actions>
        <mat-dialog-actions >
            <div (click)="submitCode()" class="cursor-pointer button join-button">
                JOIN
            </div>
        </mat-dialog-actions>
    </div>

</div>
