import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { HttpParams } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { ChAddToolStackComponent } from '../../entryComponents/ch-add-tool-stack/ch-add-tool-stack.component';
import { ChEditReachOutComponent } from '../../entryComponents/ch-edit-reach-out/ch-edit-reach-out.component';
import { ChEditInterestComponent } from '../../entryComponents/ch-edit-interest/ch-edit-interest.component';
import { ChEditProfileComponent } from '../../entryComponents/ch-edit-profile/ch-edit-profile.component';
import { ChEditSocialComponent } from '../../entryComponents/ch-edit-social/ch-edit-social.component';
import { ImageCropperComponent } from '../../entryComponents/image-cropper/image-cropper.component';
import { ChEditAboutComponent } from '../../entryComponents/ch-edit-about/ch-edit-about.component';
import { ChToolStackComponent } from '../../entryComponents/ch-tool-stack/ch-tool-stack.component';
import { MemberDirectoryService } from '../../../core/services/member-directory.service';
import { LocalStorageService } from '../../../core/services/localstorage.service';
import { SubscriptionService } from '../../../core/services/subscription.service';
import { CommunityService } from '../../../core/services/community.service';
import { HomeFeedService } from '../../../core/services/home-feed.service';
import { StartLoading, StopLoading } from '../../store/actions/app.action';
import { ChatroomService } from '../../../core/services/chatroom.service';
import { ProfileService } from '../../../core/services/profile.service';
import { AuthService } from '../../../core/services/auth.service';
import { COMMMUNITY_OPENED } from '../../constants/app-constant';
import { STORAGE_KEY } from '../../enums/storage-keys.enum';
import { State } from '../../store/reducers';

const MAX_FILE_SIZE_IN_MBS = 16;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_IN_MBS * 1024 * 1024;

@Component({
    selector: 'app-home-page-profile-drawer',
    templateUrl: './home-page-profile-drawer.component.html',
    styleUrls: ['./home-page-profile-drawer.component.scss'],
})
export class HomePageProfileDrawerComponent implements OnInit {
    public circleColor: string;
    private colors = [
        '#B71C1C', //red
        '#880E4F', //pink
        '#4A148C', //Purple
        '#311B92', //Deep Purple
        '#1A237E', //Indigo
        '#0D47A1', //Blue
        '#01579B', //Light Blue
        '#006064', //Cyan
        '#004D40', //Teal
        '#1B5E20', //Green
        '#33691E', //Light Green
        '#827717', //Lime
        '#F57F17', //Yellow
        '#FF6F00', //Amber
        '#E65100', //Orange
        '#BF360C', //Deep Orange
        '#3E2723', //Brown
    ];

    imgInitShow: boolean = true;
    imgInit1: any;
    memberProfile: any;
    communityId: string;
    user: any;
    image_source: string;
    private destroy$$ = new Subject();
    profileData: any;
    tagData: any = {};
    selfProfile: boolean = false;

    notMentioned = 'Not Mentioned';
    memberState: any;

    // Define an object to store the parsed answers by tags
    parsedAnswersByTags: { [tag: string]: any[] } = {};
    basicQuestionAnswers: any[] = []; // Separate array for 'basic' tag
    aboutQuestionAnswers: any[] = []; // Separate array for 'about' tag
    socialsQuestionAnswers: any[] = []; // Separate array for 'socials' tag
    reachQuestionAnswers: any[] = []; // Separate array for 'reach_out_to_me' tag
    toolQuestionAnswers: any[] = []; // Separate array for 'tool_stack' tag
    interestQuestionAnswers: any[] = []; // Separate array for 'interest_area' tag
    aboutQuestionAnswersObject = {};
    constructor(
        private chatroomService: ChatroomService,
        private homeFeedService: HomeFeedService,
        private store: Store<State>,
        private snackbar: MatSnackBar,
        private subscriptionService: SubscriptionService,
        private authService: AuthService,
        private cookieService: CookieService,
        private profileService: ProfileService,
        private dialog: MatDialog,
        private localStorageService: LocalStorageService,
        private router: Router,
        private memberDirectoryService: MemberDirectoryService,
        private communityService: CommunityService
    ) {}

    ngOnInit(): void {
        this.user = this.localStorageService?.getSavedState(STORAGE_KEY.LIKEMINDS_USER);
        this.communityId = this.cookieService?.get(COMMMUNITY_OPENED);
        this.profileDrawer();
    }

    profileDrawer() {
        this.chatroomService.openHomePageProfileDrawer$$.subscribe((resData) => {
            if (resData.status) {
                this.getProfileData(resData.userId);
                var uuid = this.localStorageService.getSavedState(STORAGE_KEY.LOGGED_IN_MEMBER_UUID);
                if (resData.userId === uuid) this.selfProfile = true;
                else this.selfProfile = false;
            } else {
                this.selfProfile = false;
                this.profileData = '';
                this.tagData = {};
            }
            this.getMemberState();
        });
    }

    // Get member state

    getMemberState() {
        this.communityService.getMemberState().subscribe((resData) => {
            this.memberState = resData.data;
        });
    }

    /**
     * @function acceptRejectMember
     * @param params
     * @description This function is used to logout user
     */
    acceptRejectMember(action: boolean, userId: any) {
        const payload = {
            uuid: userId,
            is_accepted: action,
        };
        this.memberDirectoryService.getMemberJoinReq(payload).subscribe((resData) => {
            this.closeProfileDrawer();
            location.reload();
        });
    }

    /**
     * @function getProfileData
     * @param params
     * @description This function is used to logout user
     */
    getProfileData(userId: string) {
        this.profileService.memberProfile(userId).subscribe((resData) => {
            this.profileData = resData.data;
            this.parseQuestionAnswers(this.profileData.question_answers);

            // this.editInterest();
        });
    }

    /**
     * @function parseData
     * @param params
     * @description This function is used to logout user
     */

    parseQuestionAnswers(questionAnswersData: any[]): void {
        // Iterate through question answers and filter based on 'basic' tag
        let basicObj: any;
        basicObj = questionAnswersData.filter((questionAnswer) => {
            return questionAnswer.question.tag === 'basic';
        });
        this.basicQuestionAnswers = basicObj.sort((a, b) => a.question.rank - b.question.rank);

        // Iterate through question answers and filter based on 'about' tag
        let aboutObj: any;
        aboutObj = questionAnswersData.filter((questionAnswer) => {
            return questionAnswer.question.tag === 'about';
        });
        this.aboutQuestionAnswers = aboutObj.sort((a, b) => a.question.rank - b.question.rank);
        // console.log(this.aboutQuestionAnswers);

        aboutObj.forEach((item) => {
            if (['Scale of Community', 'Industry', 'Community Experience'].includes(item?.question?.question_title)) {
                this.aboutQuestionAnswersObject[item.question.question_title] = item?.question_answer?.answer;
            }
        });
        // this.aboutQuestionAnswers = questionAnswersData.filter((questionAnswer) => {
        //     return questionAnswer.question.tag === 'about';
        // });

        // Iterate through question answers and filter based on 'tool_stack' tag
        this.toolQuestionAnswers = questionAnswersData.filter((questionAnswer) => {
            return questionAnswer.question.tag === 'tool_stack';
        });
        // Iterate through question answers and filter based on 'interest_area' tag
        this.interestQuestionAnswers = questionAnswersData.filter((questionAnswer) => {
            return questionAnswer.question.tag === 'interest_area';
        });
        // Iterate through question answers and filter based on 'reach_out_to_me' tag
        this.reachQuestionAnswers = questionAnswersData.filter((questionAnswer) => {
            return questionAnswer.question.tag === 'reach_out_to_me';
        });

        // Iterate through question answers and filter based on 'socials' tag
        this.socialsQuestionAnswers = questionAnswersData.filter((questionAnswer) => {
            return questionAnswer.question.tag === 'socials';
        });
    }

    /**
     * @function goToDm
     * @param
     * @description This function is used to redirect to the Direct Message
     */
    goToDm() {
        this.router.navigateByUrl(`/${this.communityId}?direct_message=true`);
    }
    /**
     * @function editProile
     * @param params
     * @description This function is used to editProile
     */
    editProile() {
        this.dialog
            .open(ChEditProfileComponent, {
                data: {
                    aboutObj: this.basicQuestionAnswers,
                },
                disableClose: true,
            })
            .afterClosed()
            .subscribe((res) => {
                if (res === 'updated') {
                    this.profileDrawer();
                }
            });
    }
    /**
     * @function editAboutUs
     * @param params
     * @description This function is used to editAboutUs
     */
    editAboutUs() {
        this.dialog
            .open(ChEditAboutComponent, {
                data: {
                    aboutObj: this.aboutQuestionAnswers,
                },
                disableClose: true,
            })
            .afterClosed()
            .subscribe((res) => {
                if (res === 'updated') {
                    this.profileDrawer();
                }
            });
    }

    /**
     * @function editToolStack
     * @param params
     * @description This function is used to editToolStack
     */
    editToolStack() {
        this.dialog
            .open(ChToolStackComponent, {
                data: {
                    aboutObj: this.toolQuestionAnswers,
                },
                disableClose: true,
            })
            .afterClosed()
            .subscribe((res) => {
                if (res === 'updated') {
                    this.profileDrawer();
                }
            });
    }
    /**
     * @function editAddToolStack
     * @param params
     * @description This function is used to editAddToolStack
     */
    editAddToolStack() {
        this.dialog.open(ChAddToolStackComponent, {
            // backdropClass: 'notVisibleBackdrop',
            // disableClose: false
        });
    }

    /**
     * @function editInterest
     * @param params
     * @description This function is used to editInterest
     */
    editInterest() {
        this.dialog
            .open(ChEditInterestComponent, {
                data: {
                    aboutObj: this.interestQuestionAnswers,
                },
                disableClose: true,
            })
            .afterClosed()
            .subscribe((res) => {
                if (res === 'updated') {
                    this.profileDrawer();
                }
            });
    }

    /**
     * @function editReach
     * @param params
     * @description This function is used to editReach
     */
    editReach() {
        this.dialog
            .open(ChEditReachOutComponent, {
                data: {
                    aboutObj: this.reachQuestionAnswers,
                },
                disableClose: true,
            })
            .afterClosed()
            .subscribe((res) => {
                if (res === 'updated') {
                    this.profileDrawer();
                }
            });
    }

    /**
     * @function editSocial
     * @param params
     * @description This function is used to editSocial
     */
    editSocial() {
        this.dialog
            .open(ChEditSocialComponent, {
                data: {
                    aboutObj: this.socialsQuestionAnswers,
                },
                disableClose: true,
            })
            .afterClosed()
            .subscribe((res) => {
                if (res === 'updated') {
                    this.profileDrawer();
                }
            });
    }

    /**
     * @function onLogout
     * @param params
     * @description This function is used to logout user
     */
    onLogout() {
        this.homeFeedService.communityGroup$$.next([]);
        this.subscriptionService.showMySubscriptions$$.next(false);
        const loginType = JSON.parse(localStorage.getItem('login_type'));
        if (loginType?.google) this.authService.signOut();
        this.authService
            .logout()
            .pipe(takeUntil(this.destroy$$))
            .subscribe(
                (res) => {
                    this.clearLocalData();
                },
                (err) => {
                    this.clearLocalData();
                }
            );
    }

    /**
     * @function clearLocalData
     * @param
     * @description This function is used to clear all local, session, cookies storage
     */
    clearLocalData() {
        localStorage.clear();
        sessionStorage.clear();
        this.cookieService.deleteAll();
        this.router.navigate(['auth']);
        this.hideMediaPopup();
    }

    hideMediaPopup(): void {
        this.chatroomService.closeMediaPopup$$.next(false);
    }

    checkIfErrorInFiles(files): boolean {
        const fileArray: any[] = Array.from(files);
        if (fileArray.find((file) => file.size > MAX_FILE_SIZE_BYTES)) {
            this.snackbar.open(`Maximum allowed size is ${MAX_FILE_SIZE_IN_MBS}Mbs.`, undefined, {
                panelClass: ['snackbar'],
                duration: 3000,
            });
            return true;
        }
        return false;
    }

    closeProfileDrawer() {
        this.chatroomService.openHomePageProfileDrawer$$.next({ status: false, userId: '' });
        // this.chatroomService.openHomePageProfileDrawer$$.next(false);
    }

    onImgError(event, name): void {
        this.imgInit1 = this.userInit(name);
        this.imgInitShow = false;
    }

    userInit(name) {
        this.circleColor = this.colors[Math.floor(Math.random() * Math.floor(this.colors.length))];
        let initials = '';
        let namesList = name?.split(' ');
        for (let name of namesList) {
            if (name[0] !== ' ' && name[0]) {
                initials += name[0]?.toUpperCase();
                if (initials.length === 2) break;
            }
        }
        return initials;
    }

    uploadImage(event) {
        if (this.checkIfErrorInFiles(event.target.files)) {
            return;
        }

        this.dialog
            .open(ImageCropperComponent, {
                data: {
                    member_id: this.user?.id,
                    event: event,
                },
            })
            .afterClosed()
            .subscribe((response) => {
                if (response) {
                    this.image_source = 'gallery';
                    this.updateProfile(response);
                }
            });
    }

    updateProfile(event) {
        const payload = new HttpParams().set('type', 'image').set('value', event);

        this.store.dispatch(StartLoading());
        this.profileService
            .updateProfileImage(payload)
            .pipe(takeUntil(this.destroy$$))
            .subscribe(
                (response) => {
                    if (response?.success) {
                        let updatedUser = this.user;
                        updatedUser.image_url = event;
                        this.localStorageService.setSavedState(updatedUser, STORAGE_KEY.LIKEMINDS_USER);
                        this.user = updatedUser;
                        this.homeFeedService?.homePageProfileUpdated$$?.next(true);
                    } else
                        this.snackbar.open(`Error updating profile.`, null, {
                            duration: 3000,
                            panelClass: ['black-bottom-left-snackbar'],
                        });
                    this.store.dispatch(StopLoading());
                },
                (error) => {
                    this.store.dispatch(StopLoading());
                    this.snackbar.open(`Error updating profile.`, null, {
                        duration: 3000,
                        panelClass: ['black-bottom-left-snackbar'],
                    });
                }
            );
    }

    ngOnDestroy() {
        this.destroy$$.next(null);
        this.destroy$$.complete();
    }
}
