import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-hint-modal',
    templateUrl: './hint-modal.component.html',
    styleUrls: ['./hint-modal.component.scss'],
})
export class HintModalComponent implements OnInit {
    introObj: any;

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            introduction: any;
        }
    ) {}

    ngOnInit() {
        this.introObj = this.data.introduction;
    }

    close() {
        this.dialogRef.close();
    }
}
