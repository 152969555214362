<div class="seperator text-center mt-2 mb-3"> 
    <div class="lg-seperator mx-auto"></div>
</div>
<div class="eventContent">
    <img src="../../../../../../assets/images/svg/cross.svg" alt="Cancel" width="20" height="20" class="cancelBtn" (click)=close()>
    <div class="eventContent__header">
        <h2>Guidelines for image files</h2>
        <p class="eventContent--subHeading">Use the following guidelines to get the highest quality event image:</p>
    </div>
    <div class="eventContent__header">
        <h2>Dimensions</h2>
        <p class="eventContent--subHeading">Find at least a 2160 x 1080px (2:1 ratio) image.</p>
    </div>
    <div class="eventContent__header">
        <h2>File Type</h2>
        <p class="eventContent--subHeading">Pictures with file types JPEG, BMP, PNG, or GIF work best.</p>
    </div>
    <div class="eventContent__header">
        <h2>File Size</h2>
        <p class="eventContent--subHeading">Use a photo that's not larger than 10MB.</p>
    </div>
    <div class="eventContent__header m-0">
        <h2>General</h2>
        <p class="eventContent--subHeading">Avoid images that have a lot of text, logos, and fliers.</p>
    </div>
</div>