<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g>
                    <g>
                        <g>
                            <path fill="#FFF" d="M0 0H24V24H0z"
                                transform="translate(-136.000000, -554.000000) translate(112.000000, 125.000000) translate(24.000000, 356.000000) translate(0.000000, 32.000000) translate(0.000000, 41.000000)" />
                            <g class="fill-brand" [ngClass]="{'inactive-tab': inactive}" fill-rule="nonzero">
                                <path
                                    d="M0 14.907c0 .572.647.904 1.111.573l3.866-2.758c.325-.231.706-.354 1.105-.354h8.502c1.497 0 2.714-1.217 2.714-2.714V.732c0-.388-.315-.703-.703-.703H2.715C1.217.03 0 1.247 0 2.744v12.163zM1.406 2.743c0-.72.587-1.307 1.308-1.307h13.178v8.218c0 .721-.587 1.308-1.308 1.308H6.082c-.693 0-1.357.213-1.921.615l-2.755 1.965V2.743z"
                                    transform="translate(-136.000000, -554.000000) translate(112.000000, 125.000000) translate(24.000000, 356.000000) translate(0.000000, 32.000000) translate(0.000000, 41.000000) translate(0.000000, 1.000000)" />
                                <path
                                    d="M24 8.197c0-1.496-1.218-2.714-2.714-2.714h-1.878c-.389 0-.703.315-.703.703 0 .388.314.703.703.703h1.878c.721 0 1.308.587 1.308 1.308v10.799l-2.755-1.965c-.564-.402-1.228-.615-1.92-.615H9.415c-.721 0-1.308-.587-1.308-1.308v-.63c0-.389-.315-.703-.703-.703-.388 0-.703.314-.703.703v.63c0 1.496 1.217 2.714 2.714 2.714h8.502c.399 0 .78.122 1.105.354l3.866 2.757c.461.33 1.111.001 1.111-.572V8.197zM5.056 5.266h8.124c.388 0 .703-.315.703-.703 0-.389-.315-.703-.703-.703H5.056c-.388 0-.703.314-.703.703 0 .388.315.703.703.703zM5.056 8.547h8.124c.388 0 .703-.315.703-.703 0-.388-.315-.703-.703-.703H5.056c-.388 0-.703.315-.703.703 0 .388.315.703.703.703z"
                                    transform="translate(-136.000000, -554.000000) translate(112.000000, 125.000000) translate(24.000000, 356.000000) translate(0.000000, 32.000000) translate(0.000000, 41.000000) translate(0.000000, 1.000000)" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>