/**
 * @class AuthService
 * @description This class contains all the service related to auth
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { BaseService } from './base.service';
import {
    GENERATE_OTP_API,
    VERIFY_OTP_API,
    LOGIN_API,
    MERGE_ACCOUNT_API,
    LOGOUT_API,
    FETCH_APP_ACCESS,
    FETCH_ALL,
    SDK_ONBOARDING,
    REFRESH_TOKEN_API,
    USER_TOKEN,
    SDK_INITIATE,
    USER_META,
    COMMUNITY_QUESTIONS,
    USER_CONFIG,
    USER_SOCIAL_LOGIN,
    COMMUNITY_INTRO_EXAMPLE,
} from '../../shared/constants/api.constant';
import { GenerateOtpModel, GenerateOtpForMergeAccountModel } from '../../shared/models/auth.model';
import { VerifyOtpModel } from '../../shared/models/auth.model';
import { AnalyticsService } from './analytics.service';
import { LocalStorageService } from './localstorage.service';
import { STORAGE_KEY } from '../../shared/enums/storage-keys.enum';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';

@Injectable({
    providedIn: 'root',
})
export class AuthService extends BaseService {
    openRenewMembership$$ = new BehaviorSubject<any>(false);
    public socialUser: SocialUser | null = null;
    public idTokenSubject = new BehaviorSubject<string | null>(null);

    constructor(
        private httpClient: HttpClient,
        private analyticsService: AnalyticsService,
        private localStorageService: LocalStorageService,
        private authSocialService: SocialAuthService
    ) {
        super(httpClient);
    }

    /**
     * @function initGoogleSignIn
     * @param params
     * @description This function initGoogleSignIn
     */
    initGoogleSignIn(): void {
        this.authSocialService.initState.subscribe(() => {
            this.authSocialService.authState.subscribe((user) => {
                this.socialUser = user;
                this.idTokenSubject.next(user?.idToken || null);
            });
        });
    }

    signInWithGoogle(): void {
        this.authSocialService
            .signIn(GoogleLoginProvider.PROVIDER_ID)
            .then((user: SocialUser) => {
                console.log(user);
                // Access user information, including idToken
                console.log('id_token:', user.idToken);

                // Save the user information and idToken
                this.socialUser = user;
                this.idTokenSubject.next(user.idToken || null);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    get authenticated(): boolean {
        return this.socialUser !== null;
    }

    get currentUser(): SocialUser | null {
        return this.socialUser;
    }

    get idToken(): string | null {
        return this.idTokenSubject.value;
    }

    signOut(): void {
        this.authSocialService.signOut();
        this.socialUser = null;
        this.idTokenSubject.next(null);
    }

    /**
     * @function generateOtp
     * @param params
     * @description This function generates OTP
     */
    generateOtp(params: GenerateOtpModel | GenerateOtpForMergeAccountModel): Observable<any> {
        return this.post(params, null, GENERATE_OTP_API);
    }

    /**
     * @function verifyOtp
     * @param params
     * @description This function verifies OTP
     */
    verifyOtp(params: VerifyOtpModel): Observable<any> {
        return this.get(params, VERIFY_OTP_API);
    }

    /**
     * @function login
     * @param data
     * @description This function is for login
     */
    login(data): Observable<any> {
        return this.post(data, null, LOGIN_API);
    }

    /**
     * @function InitiateUser
     * @param data
     * @description This function is for Initiate
     */
    // initiateUser(initiateUserRequest: InitiateUserRequest): Observable<any> {
    initiateUser(initiateUserRequest?: any): Observable<any> {
        const data = {
            name: initiateUserRequest.name,
            user_unique_id: initiateUserRequest.userUniqueId,
            is_guest: initiateUserRequest.isGuest,
        };
        // return this.post(data, null, SDK_INITIATE);
        return this.post(initiateUserRequest || null, null, SDK_INITIATE);
    }

    /**
     * @function uuid
     * @param data
     * @description This function is for uuid
     */
    uuid(): Observable<any> {
        return this.get(null, USER_META);
    }

    /**
     * @function oneTimeTokent
     * @param data
     * @description This function is for oneTimeTokent
     */
    oneTimeTokent(tokenType: any): Observable<any> {
        return this.get(tokenType, USER_TOKEN);
    }

    /**
     * @function refreshToken
     * @param data
     * @description This function is for refreshing token
     */
    refreshToken(): Observable<any> {
        return this.post(null, null, REFRESH_TOKEN_API);
    }

    /**
     * @function socialLogin
     * @param data
     * @description This function is for socialLogin
     */
    socialLogin(data: any): Observable<any> {
        return this.get(data, USER_SOCIAL_LOGIN);
    }

    /**
     * @function logout
     * @param data
     * @description This function is for logout
     */
    logout(): Observable<any> {
        const data = {
            refresh_token: this.localStorageService.getSavedState(STORAGE_KEY.REFRESH_TOKEN_RTM),
        };
        return this.post(data, null, LOGOUT_API);
    }

    /**
     * @function mergeAccount
     * @param data
     * @description This function is to merge accounts
     */
    mergeAccount(data): Observable<any> {
        // const { country_code, mobile_no, user_id } = data;
        return this.post(data, null, `${MERGE_ACCOUNT_API}`);
    }

    /**
     * @function getOnboardingScreen
     * @param data
     * @description This function is to merge accounts
     */
    getOnboardingScreen(): Observable<any> {
        return this.get({}, SDK_ONBOARDING);
    }

    /**
     * @function getProfileQuestions
     * @param params
     * @description This function verifies OTP
     */
    getProfileQuestions(): Observable<any> {
        return this.get(null, COMMUNITY_QUESTIONS);
    }

    /**
     * @function introductionSamples
     * @param params
     * @description This function verifies OTP
     */
    introductionSamples(): Observable<any> {
        return this.get(null, COMMUNITY_INTRO_EXAMPLE);
    }

    /**
     * @function setConfig
     * @description This function is get config data for the user and send it to mixpanel
     */
    setConfig(): void {
        // this.get(null, CONFIG_API).subscribe((userData) => {
        this.get(null, USER_CONFIG).subscribe((userData) => {
            const {
                user_detail,
                user_detail: { user: userObj, user_metrics },
            } = userData?.data;
            if (user_detail && userObj && user_metrics) {
                this.analyticsService.setUserConfig({
                    $name: userObj.name,
                    has_profile_photo: userObj.image_url && !!userObj.image_url,
                    ...user_metrics,
                });
            }
            this.analyticsService.segMentConfig$$.next(userData.use_segment);
        });
    }

    getLimitAccess(userId): Observable<any> {
        return this.get(null, FETCH_APP_ACCESS);
    }

    fetchAppAccess(): Observable<any> {
        return this.get(null, FETCH_APP_ACCESS);
    }

    fetchAll(params?: any): Observable<any> {
        return this.get(params, FETCH_ALL);
    }
}
