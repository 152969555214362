export enum STORAGE_KEY {
    OTP_INFO = '__otp_info__',
    GEN_OTP = '__generate_otp_obj__',
    VER_OTP = '__verify_otp_obj__',
    LIKEMINDS_USER = '__likeminds_user__',
    COMMUNITY = '__community__',
    CHATROOM = '__chatroom__',
    LINK_TYPE = '__link_type__',
    AUTH = '__auth__',
    LIKEMINDS_REDIRECT_URL = '__likeminds_redirect_url__',
    NEW_ACCOUNT_CREATION = 'nac',
    JOIN_COMMUNITY = 'join_community',
    LINKEDIN_USER = 'lnuser',
    LOGIN_LINK = '__login_link__',
    PENDING_USER_DATA = '__pending_user_data__',
    DEVICE_ID = 'device_id',
    CURRENTLY_OPENED_CHATROOM_ID = '__currently_opened_chatroom_id__',
    CHATROOM_OPENED = '__chatroom_opened__',
    URL_PATTERN = '__url_pattern__',
    PAYMENT_ID_SUCCESS = '__payment_id__success__',
    PAYMENT_ID_FAIL = '__payment_id_failure__',
    OTP_SEND = '__otp_send__',
    REG_INIT = '__reg_init__',
    NOTIFICATION = '__notification__',
    ACCESS = '__access__',
    // USER_EXIST = '__user_exists__',
    USER_EXIST = '__access__',
    SHOW_DETAIL_PAGE = '__show_detail_page__',
    MY_SUBSCRIPTION_IS_OPENED = '__my_susbcription_is__opened__',
    HAS_LEFT_COMMUNITY = '__has_left_community__',
    PROFILE_RIGHTS_URL = '__profile_rights_url__',
    LOCABASE_INDEX = '__localbase_index__',
    TEMP_CHATROOM_ID = '__temp_chatroom_id__',
    EDIT_COHORTS = '__edit_cohorts__',
    RELOAD = '__reload__',
    BRANDING = '__branding__',
    CHATROOM_LOCAL = '__chatroom_local__',
    EMAIL_UNSUB_USER_ID = '__email_unsub_user_id__',

    ACCESS_TOKEN_LTM = '__access_token_LTM__',
    ACCESS_TOKEN_VTM = '__access_token_VTM__',
    REFRESH_TOKEN_RTM = '__refresh_token_RTM__',
    REFRESH_TOKEN_OTM = '__refresh_token_OTM__',
    LOGGED_IN_MEMBER_UUID = '__logged_in_member_UUID__',
    LOGGED_IN_MEMBER_ID = '__logged_in_member_ID__',
    IS_ON_BOARDING_DONE = '__is_On_boarding_done__',
    FORM_DATA_START = '__reg_form_data_start__',
    FORM_DATA = '__reg_form_data__',
    GMAIL_USER_DATA = '__gmail_user_data__',
}
