<ng-container>
    <label class="fs14 c6 float-start ms-2"
        >{{ question?.question_title }} <span *ngIf="!question?.optional" class="text-danger">*</span></label
    >
    <mat-form-field
        class="simple-input multi-select-with-search w-100 mb-4"
        [ngClass]="{ invalid: !question?.optional && !selectedOptions.length && formSubmitted }"
        floatLabel="never"
    >
        <mat-chip-list #chipList aria-label="Fruit selection">
            <mat-chip *ngFor="let item of selectedOptions" [selectable]="selectable" [removable]="true" (removed)="remove(item)">
                {{ item | ellipsisWord: 35 }}
                <span matChipRemove>
                    <img src="assets/images/svg/cross-theme.svg" />
                </span>
            </mat-chip>
            <input
                #choiceInput
                [formControl]="control"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [placeholder]="question?.help_text || 'Select an option'"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)"
                [required]="!question?.optional"
            />
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption (optionSelected)="selected($event)">
            <mat-option class="text-capitalize" *ngFor="let option of filteredOptions | async" [value]="option?.value">
                {{ option?.value }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <div
        class="invalid-feedback question-form-error ms-2 d-block text-start"
        *ngIf="!question?.optional && !selectedOptions.length && formSubmitted"
    >
        This is a mandatory field
    </div>
</ng-container>
