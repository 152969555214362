import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IUser } from '../../models/user.model';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { STORAGE_KEY } from '../../enums/storage-keys.enum';
import { AuthService } from 'src/app/core/services/auth.service';
import { Question } from '../../models/question.model';
import { ProfileService } from 'src/app/core/services/profile.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-ch-edit-social',
    templateUrl: './ch-edit-social.component.html',
    styleUrls: ['./ch-edit-social.component.scss'],
})
export class ChEditSocialComponent implements OnInit {
    private destroy$$ = new Subject();
    @Input() user: IUser;

    formData = [];
    form: FormGroup;

    // form: any;
    formSubmitted = false;
    aboutDataObj: any;

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            aboutObj: any;
        },
        private localStorageService: LocalStorageService,
        private authService: AuthService,
        private profileService: ProfileService,
        private fb: FormBuilder
    ) {}

    ngOnInit(): void {
        this.user = this.localStorageService?.getSavedState(STORAGE_KEY.LIKEMINDS_USER);
        this.profileQuestions();
    }

    initializeForm() {
        this.form = this.fb.group({});

        this.formData.forEach((question: any, i) => {
            const platform = question.options[0].profile_platform;
            const validators =
                platform === 'Others'
                    ? [
                          Validators.pattern(
                              '(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})(.[a-zA-Z0-9]{2,})'
                          ),
                      ]
                    : null;

            this.form.addControl(`question_${i}`, this.fb.control('', validators));
        });
    }

    /**
     * @function profileQuestions
     * @description This function is used to get profileQuestions
     */
    profileQuestions(): void {
        this.authService
            .getProfileQuestions()
            .pipe(takeUntil(this.destroy$$))
            .subscribe((resData: any) => {
                this.aboutDataObj = this.filterQuestionsStepOne(resData?.data?.questions, 'socials');

                if (this.data?.aboutObj.length > 0) {
                    const questionObj = this.aboutDataObj.map((item1: any) => {
                        const matchingItem2 = this.data?.aboutObj.find((item2) => {
                            return item2.question_answer.question_id === item1.id;
                        });
                        if (matchingItem2) {
                            const fieldType = JSON.parse(matchingItem2.question.value);
                            if (fieldType[0].profile_platform === 'Instagram' || fieldType[0].profile_platform === 'Twitter') {
                                // Regular expression to match either Instagram or Twitter URL
                                const regex = /(https:\/\/(www\.)?(instagram|twitter)\.com\/)/;
                                // Remove the base domain from the URL
                                const username = matchingItem2.question_answer.answer.replace(regex, '');
                                item1.answer = username;
                            } else {
                                item1.answer = matchingItem2.question_answer.answer;
                            }
                        }
                        return item1;
                    });

                    this.formData = questionObj.map((item: any) => ({
                        question_title: item?.question_title,
                        question_id: item?.id?.toString(),
                        answer: item?.answer,
                        options: JSON.parse(item?.value),
                    }));
                } else {
                    this.formData = this.aboutDataObj.map((item: any) => ({
                        question_title: item?.question_title,
                        question_id: item?.id,
                        answer: item?.answer,
                        options: JSON.parse(item?.value),
                    }));
                }

                // Initialize the form controls dynamically based on the retrieved data
                this.initializeForm();
            });
    }

    /**
     * @function filterQuestionsStepOne
     * @description This function is used to filter the questions
     */
    filterQuestionsStepOne(questions: Question[], type?: string): Question[] {
        if (type === 'socials') {
            return questions.filter((question) => {
                return question.optional && question.tag === 'socials';
            });
        }
    }

    close() {
        this.dialogRef.close();
    }

    confirm() {
        this.dialogRef.close('updated');
    }

    onSubmit() {
        if (!this.form.valid) {
            console.log('Form is not valid.');
            return;
        }

        const formValues = this.form.value;
        const valuesArray = Object.values(formValues);
        const newData = this.formData.map((item: any, index: number) => ({
            answer: valuesArray[index],
            question_id: item?.question_id?.toString(),
            question_title: item?.question_title,
            options: item.options,
        }));
        const params = {
            question_answers: newData,
        };
        this.profileService
            .editMemberProfileCh(params)
            .pipe(takeUntil(this.destroy$$))
            .subscribe((resData) => {
                if (resData.success) this.confirm();
            });
    }

    ngOnDestroy(): void {
        this.destroy$$.next(null);
        this.destroy$$.complete();
    }
}
