<div>
    <form  [formGroup]="invitationForm" (ngSubmit)="checkDataAndSendInvite()">
        <div class="invite-member" [ngClass] = "{'mat-field-error':errorInEmailList}">
            <mat-form-field class="example-chip-list" appearance="fill">
                <mat-chip-list #chipList aria-label="Fruit selection" (click)="setFoucusOnInput()">
                    <mat-chip
                      *ngFor="let email of emailList"
                      (removed)="remove(email)"
                      (click) = "onChipClick($event)"
                      [ngClass] = "{'mat-chip-error':!checkIfEmailIsCorrectorNot(email)}"
                      >
                      {{email}}
                      <mat-icon matChipRemove [ngClass] = "{'icon-error-style':!checkIfEmailIsCorrectorNot(email)}">cancel</mat-icon>
                    </mat-chip>
                    <input
                        matInput class="email-input " 
                        [placeholder]="placeHolder"
                        formControlName="emailString"
                      #emailInput
                      [formControl]="emailCtrl"
                      [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      (matChipInputTokenEnd)="add($event)"
                      autocomplete="off"
                      >
                  </mat-chip-list>
            </mat-form-field>
            
            <!-- <input matInput class="email-input mt-1 pt-2" placeholder="Add upto 100 emails here."
                       formControlName="emailString" type="text" autocomplete="off" /> -->
            <div class='upload-download-wrapper'>
                <span class="download-csv" (click)="downloadSampleEmailCsvFile()">
                    Download Sample CSV
                </span>
                <span class="upload-csv" (click)="uploadEmails.click()">
                    
                    <input hidden  type="file" accept=".csv" (change)="convertFile($event)" (click)="$event.target.value=null" #uploadEmails>
                    Upload CSV
                </span>
            </div>      
        </div>
        <div>
            <textarea matInput  rows="7" class="text-area-input form-control" id="aboutInput" formControlName="invitationText"
            placeholder="Write invitation message here."></textarea>
            
        </div>
        <div class="send-btn-wrapper">
            <button class='send-btn-cta' type="submit">Send</button>
        </div>
    </form>
    
</div>