<div class="renewal-page">
    <main *ngIf="!showSuccessPage" class="container-fluid p-0">
        <div class="d-flex flex-column">
            <div class="sub__header" [ngClass]="{ 'd-flex': screenType !== 'mobile', 'justify-content-center': screenType !== 'mobile' }">
                <div
                    (click)="closeRenewal()"
                    [ngStyle]="{ display: screenType === 'mobile' ? 'none' : 'block' }"
                    class="cursor-pointer"
                    style="position: absolute; left: 16px; top: 19px; margin: auto 2px"
                >
                    <img src="assets/images/svg/back-navigation-arrow.svg" alt="back" />
                </div>
                <div class="sub__header-content">
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="image-div cursor-pointer" (click)="closeRenewal()">
                            <img src="assets/images/svg/white-back-arrow.svg" alt="back" />
                        </div>

                        <div [ngStyle]="{ display: screenType !== 'mobile' ? 'none' : 'block' }">
                            <img class="profile-img" src="{{ communityImageUrl }}" alt="" />
                        </div>
                        <div class="community-name-div d-flex">
                            <div class="membership-div"><b>Renew Membership</b></div>
                            <div class="community-name">{{ communityName }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="body__container">
                <!-- p-3  -->
                <div class="top__section">
                    <h2 class="text-center top__section--heading">Choose a Membership plan</h2>
                    <p class="text-center top__section-subheading">That best suits you.</p>
                </div>

                <div class="px-4 main__content">
                    <div class="row h-100 flex-container stretch">
                        <div class="h-100 col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngFor="let plan of plans; let i = index">
                            <div class="h-100 p-4 text-color-white card">
                                <div *ngIf="i === 1" class="recommended">
                                    Recommended
                                    <span class="arrow-right"> </span>
                                </div>
                                <div class="d-flex justify-content-between plan-header">
                                    <div class="plan-subheader">
                                        <h2 class="plan__name" >{{plan.duration_name}}</h2>
                                        <h3 class="prev__price" *ngIf="plan?.strike_cost || plan?.strike_cost == 0" >&#8377; {{plan?.strike_cost}}</h3>
                                        <p class="cost"><b>Rs {{plan.cost}} for {{plan.duration_in_months}} month</b></p>
                                        <!-- <p class="gst">(GST extra)</p> -->
                                    </div>
                                    <div>
                                        <img class="plan-image" [src]="plan.image" alt="Plan Image" />
                                    </div>
                                </div>

                                <div
                                    *ngIf="!isObjectOrNot(plan.description); else isObject"
                                    class="list-container"
                                    style="margin-bottom: 20px"
                                >
                                    {{ plan.description }}
                                </div>

                                <ng-template>
                                    <div #isObject class="list-container">
                                        <ul class="p-2">
                                            <li *ngFor="let item of plan.description">{{ item }}</li>
                                        </ul>
                                    </div>
                                </ng-template>

                                <div class="razorpay-button-holder">
                                    <div>
                                        <button class="razorpay-button" (click)="createOrder(i, plan.plan_id , plan.cost, plan.duration_name , plan.duration_in_months)">BUY NOW</button>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>

<!-- <app-payment-failure></app-payment-failure> -->

<!-- <div class="renewal-page" style="height: 100vh; overflow: scroll;">

    <main *ngIf="!showSuccessPage" class="container-fluid p-0">
        <div class="d-flex flex-column">    
                
            <div class="sub__header" [ngClass]="{'d-flex': screenType !== 'mobile', 'justify-content-center': screenType !== 'mobile'}">
                <div (click)="closeRenewal()" [ngStyle]= "{'display': screenType === 'mobile' ? 'none' : 'block' }" class="cursor-pointer" 
                    style="position: absolute; left: 16px; top:19px; margin: auto 2px;">
                    <img src="assets/images/svg/back-navigation-arrow.svg" alt="back">
                </div>
                <div class="sub__header-content">
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="image-div cursor-pointer" (click)="closeRenewal()" >
                            <img src="assets/images/svg/white-back-arrow.svg" alt="back">
                        </div>

                        <div [ngStyle]= "{'display': screenType !== 'mobile' ? 'none' : 'block' }" >
                            <img class="profile-img" src={{communityImageUrl}} alt="">
                        </div>
                        <div class="community-name-div d-flex">
                            <div class="membership-div"><b>Renew Membership</b></div>                   
                            <div class="community-name">{{communityName}}</div>                 
                        </div>
                    </div>
                </div>
            </div>

        
            <div class="body__container">
        
                <div class="top__section">
                    <h2 class='text-center top__section--heading'>Choose a Membership plan</h2>
                    <p class="text-center top__section-subheading">That best suits you.</p>
                </div>
                
                <div class="px-4 main__content">
                    <div class="row h-100 flex-container stretch">           
                        <div class="h-100 col-xs-12 col-sm-12 col-md-12 col-lg-12 " *ngFor="let plan of plans , let i = index">                      
                            <div class="h-100 p-4 text-color-white card d-flex flex-row">

                                    <div *ngIf="i===1" class="recommended">
                                        Recommended
                                        <span class="arrow-right">
        
                                        </span>
                                    </div>
                                    <div class="d-flex justify-content-between plan-header">
                                        <div class="plan-subheader">
                                            <h2 class="plan__name" >{{plan.duration_name}}</h2>
                                            <h3 class="prev__price">&#8377; {{(plan.cost + plan.cost*1/2)/100 | number:'1.0-0'}}</h3>
                                            <p class="cost"><b>Rs {{plan.cost/100}} for {{plan.duration_in_months}} month</b></p>
                                            <p class="gst">(GST extra)</p>
                                        </div>
                                        <div>
                                            <img class="plan-image" [src]="plan.image" alt="Plan Image">
                                        </div>
                                    </div>
        
                                    <div *ngIf="!isObjectOrNot(plan.description) ; else isObject" class="list-container" style="margin-bottom : 20px">{{plan.description}}</div>
    
                                    <ng-template>
                                        <div #isObject class="list-container">
                                            <ul class="p-2">
                                                <li  
                                                    *ngFor="let item of plan.description">{{item}}
                                                </li>
                                            </ul>
                                        </div>
                                    </ng-template>                                     

                                    <div class="razorpay-button-holder">
                                        <div >
                                            <button class="razorpay-button"(click)="createOrder(i, plan.plan_id)">BUY NOW</button>                                        
                                        </div>                        
                                    </div>
                                 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>






 -->
