<div class="wrapper">
    <div class="wrapper-header">
        Add Tool Stack

        <div class="close cursor-pointer" (click)="close()">
            <img src="../../../../assets/images/svg/ch-img/close.svg" alt="close" />
        </div>
    </div>

    <form action="">
        <div class="wrapper-body">
            <div class="image-container">
                <!-- [src]="user?.image_url" -->
                <img
                    class="profile-image"
                    src="../../../../assets/images/png/avatar.png"
                    onerror="this.src='../../../../assets/images/png/avatar.png'"
                />
                <label>
                    <app-camera class="camera"></app-camera>

                    <input #imageFileUpload type="file" accept="image/*" />
                </label>
            </div>
            <!-- (change)="uploadImage($event)" (click)="imageFileUpload.value = ''" -->

            <div class="form-group">
                <label for="org-name">Orgnisation name<span>*</span></label>
                <input type="text" class="form-control" name="org-name" />
            </div>
            <div class="form-group">
                <label for="designation">Orgnisation name<span>*</span></label>
                <select class="form-select" aria-label="Default select example">
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
            <div class="form-group">
                <label for="org-name">Orgnisation name<span>*</span></label>
                <input type="text" class="form-control" name="org-name" />
            </div>
            <div class="form-group">
                <label for="designation">Orgnisation name<span>*</span></label>
                <select class="form-select" aria-label="Default select example">
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            </div>
        </div>

        <div class="wrapper-footer">
            <button type="submit">Save</button>
        </div>
    </form>
</div>
