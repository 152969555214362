<div class="event-remove-attachment-dialog">
    <div class="heading">Remove attachment?</div>
    <div class="sub-heading">Are you sure you want to remove this attachment?</div>

    <div class="actions">
        <mat-dialog-actions mat-button [mat-dialog-close]="false">
            <div class="event-attachment-btn-close">
                <img src="../../../../assets/images/svg/close-event-attachment-dialog.svg" alt="close" />
            </div>
        </mat-dialog-actions>
        <mat-dialog-actions mat-button [mat-dialog-close]="false">
            <div class="event-attachment-btn-cancel">CANCEL</div>
        </mat-dialog-actions>
        <mat-dialog-actions mat-button [mat-dialog-close]="true">
            <div class="event-attachment-btn-confirm">CONFIRM</div>
        </mat-dialog-actions>
    </div>
</div>
