<div class="dialog-wrapper">
    <div class="wrapper-header">
        Add Your Desiganation/Role
        <div class="close cursor-pointer" (click)="close()">
            <img src="../../../../assets/images/svg/ch-img/close.svg" alt="close" />
        </div>
    </div>

    <div class="wrapper-body">
        <div class="form-group">
            <input type="text" class="form-control" [(ngModel)]="role" />
        </div>
    </div>

    <div class="wrapper-footer">
        <button type="submit" (click)="onSubmit()">Submit</button>
    </div>
</div>
