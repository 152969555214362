<div class="row auth-login-wrap d-flex" *ngIf="displayContent">
    <div class="col-md-6 col-lg-6 col-sm-6 g-0">
        <app-tutorial></app-tutorial>
    </div>

    <div class="col-md-6 col-lg-6 col-sm-6 g-0">
        <div
            class="login-form d-flex flex-column"
            *ngIf="!openRenewalMembershipComponent"
            [ngClass]="
                !(blockerData?.pending_communities?.length || blockerData?.membership_expired_communities?.length)
                    ? 'blocker'
                    : 'pendingBlocker'
            "
        >
            <!-- <div class="cancel-btn" (click)="backToLogin()">&times;</div> -->
            <div class="form-content">
                <app-pending-request
                    (communitySelected)="setCommunity($event)"
                    [blockerData]="blockerData"
                    (openRenewalPlans)="openRenewal($event)"
                    [removedMember]="removedMember"
                    [isRemovedMember]="isRemovedMember"
                    *ngIf="
                        ((blockerData?.pending_communities?.length || blockerData?.membership_expired_communities?.length) &&
                            screenType !== 'mobile') ||
                        isRemovedMember
                    "
                >
                </app-pending-request>

                <!-- <app-pending-request-mobile
                    (communitySelected)="setCommunity($event)"
                    [blockerData]="blockerData"
                    [isRemovedMemberMobile]="isRemovedMemberMobile"
                    (openRenewalPlans)="openRenewal($event)"
                    [removedMember]="removedMember"
                    *ngIf="
                        ((blockerData?.pending_communities?.length || blockerData?.membership_expired_communities?.length) &&
                            screenType === 'mobile') ||
                        isRemovedMemberMobile
                    "
                >
                </app-pending-request-mobile> -->

                <app-invite-only
                    *ngIf="
                        !(blockerData?.pending_communities?.length || blockerData?.membership_expired_communities?.length) &&
                        !isRemovedMember &&
                        !isRemovedMemberMobile
                    "
                    [blockerData]="blockerData"
                >
                </app-invite-only>
            </div>
        </div>

        <app-renewal-membership
            (closeRenewalContent)="closeRenewal($event)"
            [community]="community"
            *ngIf="openRenewalMembershipComponent"
        ></app-renewal-membership>
    </div>
</div>
