<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2_2973)">
        <circle cx="22" cy="20" r="18" class="fill-brand" />
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd"
        d="M31.6553 24.415C31.6553 26.0264 30.7838 26.8979 29.1578 26.8979H15.9522C14.3262 26.8979 13.4547 26.0264 13.4547 24.415V16.7173C13.4547 15.106 14.3262 14.2344 15.9522 14.2344H17.754C18.3253 14.2344 18.523 14.1465 18.8965 13.7729L19.4678 13.1797C19.8633 12.7769 20.2589 12.5864 20.9986 12.5864H24.0821C24.8218 12.5864 25.2173 12.7769 25.6129 13.1797L26.1841 13.7729C26.5577 14.1465 26.7554 14.2344 27.3267 14.2344H29.1578C30.7838 14.2344 31.6553 15.106 31.6553 16.7173V24.415ZM18.6402 20.5625C18.6402 22.7451 20.3834 24.4883 22.5587 24.4883C24.7266 24.4883 26.4698 22.7451 26.4698 20.5625C26.4698 18.3872 24.7339 16.644 22.5587 16.644C20.3834 16.644 18.6402 18.3872 18.6402 20.5625ZM27.9346 18.8779C27.3853 18.8779 26.9312 18.4385 26.9312 17.8745C26.9312 17.3398 27.3853 16.8784 27.9346 16.8784C28.4766 16.8784 28.938 17.3398 28.938 17.8745C28.938 18.4312 28.4766 18.8706 27.9346 18.8779ZM24.983 20.5625C24.983 21.9175 23.899 23.0015 22.5587 23.0015C21.2183 23.0015 20.127 21.9175 20.127 20.5625C20.127 19.2148 21.211 18.1309 22.5587 18.1309C23.899 18.1309 24.983 19.2148 24.983 20.5625Z"
        fill="white" />
    <defs>
        <filter id="filter0_d_2_2973" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_2973" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_2973" result="shape" />
        </filter>
    </defs>
</svg>