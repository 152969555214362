<!-- 1st screen -->
<div class="main" >
  <div class="mainFrame" *ngIf="screen == 1" >
    <div class="logo"><img src="../../../assets/images/svg/frame.svg" alt="frame" /></div>
    <h1>Community Hood</h1>
    <div class="line">&nbsp;</div>
    <p>Uncheck the types of emails you do not want to receive:</p>
    <div class="subFrame1" id="frame1" [ngClass]="{'not-allowed-cursor': allNotification}" >
        <div class="check">
          <input type="checkbox" id="event_noti" [(ngModel)]="eventNotification" [disabled]="allNotification">
          <label for="checkbox">Event Notifications</label>
        </div>
        <p>Contains email related to new events happening in the community</p>
    </div>
    <div class="subFrame2" id="frame2" [ngClass]="{'not-allowed-cursor': allNotification}" >
      <div class="check">
        <input type="checkbox" id="chatroom_noti" [(ngModel)]="chatroomNotification" [disabled]="allNotification">
        <label for="checkbox">Chatroom/DM Notifications</label>
        <p>Contains email related to poll results, DMs, etc.</p>
      </div>
    </div>
    <div class="para1">
    <p>Or check here to never receive any emails</p>
  </div>
    <div class="subFrame3">
      <div class="check">
        <input type="checkbox" id="all_noti" [(ngModel)]="allNotification" (click)="checkboxUncheck($event)" >
        <label for="checkbox">No more emails</label>
        <p>No more emails - we’re breaking up.</p>
      </div>
    </div>
    <div class="wrapper">
    <button type="button" class="button" id="unsub_btn" (click)="callingUnsubscribeEmail($event)">SAVE PREFERENCES</button>
    </div>
  </div>

  <!-- second screen -->

  <div class="secondscreen" *ngIf="screen == 2">
    <div class="logo"><img src="../../../assets/images/svg/frame.svg" alt="frame" /></div>
    <h1>Community Hood</h1>
    <div class="line">&nbsp;</div>
    <div class="subFrame_s1">
      <div class="subFrame_s2">
      <div class="unsub_message">
        <img src="../../../assets/images/svg/Icon - Tick - Circle.svg" alt="frame" />
      </div>
      <h3>Your preferences have been saved successfully.</h3>
    </div>
    </div>
    <div class="wrapper">
      <button type="button" class="button2" id="sub_btn" (click)="changeScreenToSubscribePage($event)">Edit Preferences</button>
      </div>
  </div>