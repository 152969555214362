<!-- Login.html -->
<div class="login-container">
    <form [formGroup]="form">
        <ng-container formGroupName="user">
            <!-- Step One -->
            <div class="card border-0" *ngIf="stepOne">
                <!-- Header -->

                <div class="card-header">
                    <div class="topHeader">
                        <div><h2>Create Account</h2></div>
                        <div class="steps">Step 1 of 2</div>
                    </div>

                    <div class="headerDesc">
                        <h2>Hurray! You are in.</h2>
                        <p class="mx-auto c6 fs14">
                            CommunityHood is a real identity network. <br />
                            Please share your details with the community.
                        </p>
                    </div>
                </div>
                <!-- Header -->

                <div class="d-flex justify-content-center">
                    <div class="login-form">
                        <div
                            class="upload-container position-relative mb-5 form-group"
                            [ngClass]="{
                                'invalid-border-img': formSubmitted && form.get('user.image_url').hasError('required')
                            }"
                        >
                            <img
                                src="{{ imageUrl | defaultImage }}"
                                class="user-img rounded-circle"
                                width="90"
                                height="90"
                                alt="user image"
                            />
                            <img (click)="uploadImage()" src="assets/images/svg/edit-pic.svg" class="edit-icon position-absolute" />

                            <div class="lable">Add profile pic <span>*</span></div>
                        </div>
                        <br />
                        <br />

                        <div *ngFor="let question of filteredQuestions">
                            <!-- For 'user.name' -->
                            <div>
                                <div class="form-group" *ngIf="question.question_title === 'Your Name'">
                                    <div class="lable">Your name <span>*</span></div>

                                    <input
                                        class="form-control"
                                        formControlName="name"
                                        maxlength="30"
                                        type="text"
                                        autocomplete="off"
                                        placeholder="Enter your full name"
                                        [ngClass]="{
                                            'invalid-border':
                                                formSubmitted &&
                                                (form.get('user.name').hasError('required') || form.get('user.name').hasError('whitespace'))
                                        }"
                                    />
                                </div>
                            </div>

                            <!-- For 'user.organization' -->

                            <div>
                                <div
                                    class="form-group"
                                    *ngIf="
                                        question.question_title === 'Organization/ Community Name' ||
                                        question.question_title === 'Organisation/Community Name'
                                    "
                                >
                                    <div class="lable">Organisation/Community name <span>*</span></div>

                                    <input
                                        class="form-control"
                                        formControlName="org"
                                        type="text"
                                        autocomplete="off"
                                        placeholder="Company or community you are associated with"
                                        [ngClass]="{
                                            'invalid-border':
                                                formSubmitted &&
                                                (form.get('user.org').hasError('required') || form.get('user.org').hasError('whitespace'))
                                        }"
                                    />
                                </div>
                            </div>

                            <!-- For 'user.designation' -->
                            <div *ngIf="filteredQuestions && filteredQuestions.length && showDesignationField">
                                <div class="form-group" *ngIf="question.question_title === 'Designation/Role'">
                                    <div class="lable">Your designation/role <span>*</span></div>

                                    <select
                                        formControlName="designation"
                                        (change)="onDesignationChange($event)"
                                        class="form-control"
                                        [ngClass]="{
                                            'invalid-border':
                                                formSubmitted &&
                                                (form.get('user.designation').hasError('required') ||
                                                    form.get('user.designation').hasError('whitespace'))
                                        }"
                                    >
                                        <option *ngFor="let option of designationOptions" [value]="option.value">
                                            {{ option.value }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <button type="button" class="btn-create text-uppercase br24 fs13 fw500 border-0 cF" (click)="goTo('stepTwo')">
                            Next <img src="../../../../../assets/images/svg/ch-img/next.svg" class="ms-1" alt="Next" />
                        </button>
                    </div>
                </div>
            </div>

            <!-- Step One ends -->

            <!-- Step two -->
            <div class="card border-0" *ngIf="stepTwo">
                <div class="card-header">
                    <div class="topHeader">
                        <div><h2>Create Account</h2></div>
                        <div class="steps">Step 2 of 2</div>
                    </div>

                    <div class="headerDesc">
                        <h2>Last step and we are good to go</h2>
                        <p class="mx-auto c6 fs14">
                            Introduce yourself by sharing a brief bio with <br />
                            CommunityHood members.
                        </p>
                    </div>
                </div>

                <div class="d-flex justify-content-center">
                    <div class="login-form mt-3">
                        <div *ngFor="let question of filteredQuestionsAbout">
                            <!-- For 'intro.about' -->
                            <div class="about-circle">
                                <!-- <span *ngIf="form.value.user.about?.length < 50; else counts"
                                    >Min {{ 50 - form.value.user.about?.length }} characters</span
                                >
                                <ng-template #counts>
                                    <span>{{ form.value.user.about?.length }} characters</span>
                                </ng-template> -->
                                <label for="about"> About you </label>
                                <div
                                    class="circle-box"
                                    matTooltip="Minimum characters: 50"
                                    matTooltipClass="tooltip"
                                    matTooltipPosition="above"
                                >
                                    <circle-progress
                                        class="circle"
                                        [percent]="calculatePercentage(form.value.user.about)"
                                        [radius]="4"
                                        [outerStrokeWidth]="2"
                                        [innerStrokeWidth]="2"
                                        [space]="-2"
                                        [innerStrokeColor]="getStrokeColour()"
                                        [outerStrokeColor]="getStrokeColour()"
                                        [titleFontSize]="2"
                                        [unitsFontSize]="2"
                                        [showTitle]="false"
                                        [showUnits]="false"
                                        [showSubtitle]="false"
                                        [animation]="true"
                                        [animationDuration]="300"
                                        [startFromZero]="false"
                                        [responsive]="true"
                                    >
                                    </circle-progress>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="question.question_title === 'About You'">
                                <textarea
                                    placeholder="Tell us about yourself..."
                                    class="form-control text-area"
                                    matInput
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="2"
                                    cdkAutosizeMaxRows="3"
                                    formControlName="about"
                                    [ngClass]="{
                                        'invalid-border': formSubmitted && form.get('user.about').hasError('minlength')
                                    }"
                                ></textarea>
                                <mat-error *ngIf="form.get('user.about').hasError('minlength')">
                                    <span class="fs13"> Minimum {{ 50 - form.value.user.about?.length }} characters are required. </span>
                                </mat-error>
                            </div>
                            <span class="introduce" (click)="openHint()">
                                See how others are introducing themselves
                                <img src="../../../../../assets/images/svg/ch-img/caret-down-green.svg" alt="caret-down-green" />
                            </span>
                        </div>

                        <div class="d-flex justify-content-between w-100">
                            <button
                                (click)="submitLoginForm($event)"
                                type="button"
                                class="btn-create text-uppercase br24 fs13 fw500 border-0 cF"
                            >
                                Next <img src="../../../../../assets/images/svg/ch-img/next.svg" class="ms-1" alt="Next" />
                            </button>

                            <button
                                type="button"
                                (click)="submitLoginForm($event)"
                                id="skip"
                                class="btn-skip float-start text-uppercase br24 fs13 fw500 border-0"
                            >
                                Skip
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Step two end -->
        </ng-container>
    </form>
</div>
