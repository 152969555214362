<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M9.25999 4.236V4.63127L9.64459 4.7225C12.2306 5.33592 13.7825 7.6031 13.8701 10.4613L13.8711 10.5133C11.8511 11.74 10.5 13.9616 10.5 16.5C10.5 17.1669 10.5935 17.8118 10.7679 18.423H0.515991V18.1042L2.42046 16.2006L2.56699 16.0542V15.847V10.717C2.56699 7.82382 4.04782 5.49315 6.56412 4.7818L6.80746 4.71926L7.18299 4.62274V4.235V3.537C7.18299 2.96393 7.64635 2.5 8.21999 2.5H8.22199C8.76054 2.5 9.20203 2.90831 9.25417 3.434L9.25999 3.55049V4.236ZM9.68971 20.449C9.48177 21.0605 8.90299 21.5 8.22199 21.5C7.53919 21.5 6.96027 21.0603 6.7523 20.449H9.68971Z"
        fill="#00897B"
        stroke="#00897B"
        class="fill-brand stroke-brand"
    />
    <path
        d="M15.851 18.9L20.418 14.333L20.889 14.805L15.939 19.754L15.681 19.496L15.593 19.584L13 16.992L13.471 16.52L15.851 18.9Z"
        fill="#00897B"
        stroke="#00897B"
        class="fill-brand stroke-brand"
    />
</svg>
