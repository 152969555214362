import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaymentModalDialogComponent } from 'src/app/shared/entryComponents/payment-modal-dialog/payment-modal-dialog.component';
import { RENEWAL_FLOW } from 'src/app/shared/enums/mixpanel.enum';

@Component({
    selector: 'app-pending-request-mobile',
    templateUrl: './pending-request-mobile.component.html',
    styleUrls: ['./pending-request-mobile.component.scss'],
})
export class PendingRequestMobileComponent implements OnInit {
    @Input() blockerData;
    @Input() isRemovedMemberMobile
    @Input() removedMember;
    @Output() communitySelected = new EventEmitter();
    @Output() openRenewalPlans = new EventEmitter();
    analyticsService: any;

    constructor(private dialog: MatDialog) {}

    ngOnInit(): void {
    }

    openPaymentModal() {
        this.dialog.open(PaymentModalDialogComponent, {
            data: {
                showExpiredCommunityPopup: false,
            },
        });
    }

    openRenewal() {
        this.openRenewalPlans.emit(this.blockerData?.membership_expired_communities[0]);
    }

    openExpiredCommunityPopup(community) {
        this.analyticsService.sendEvent(RENEWAL_FLOW.RENEWAL_BUTTON_CLICKED, {
            community_id: community.id,
            community_name: community.name,
            source: 'pending_blocker',
            membership_state: 'expired',
        });

        this.communitySelected.emit(community);
        this.dialog.open(PaymentModalDialogComponent, {
            data: {
                showExpiredCommunityPopup: true,
                community: community,
            },
        });
    }
}
