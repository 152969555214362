<div class="generate-otp-container col-md-12 text-center">
    <div class="row">
        <div class="card mx-auto rounded">

            <section class="text-center">

                <community-card [readMoreCount]="35" [headerType]="headerType"></community-card>
                <form [formGroup]="form" (ngSubmit)="submitGenerateOtpForm()" class="mx-auto">
                    <div class="form-group position-relative">
                        <span class="hide-sm">
                            <div class="generate-otp-mobile-input d-flex">
                                <div class="display-name">
                                    <div class="d-inline-block">
                                        <span class="fs20">{{ selectedCountry?.flag }}</span>
                                        <span class="ms-2">{{ selectedCountry?.dial_code }}</span>
                                        <img src="assets/images/svg/arrow-drop-up.svg" alt="">
                                    </div>
                                </div>
                                <mat-form-field class="country-list" appearance="fill">
                                    <mat-select panelClass="country-list-panel">
                                        <mat-form-field class="simple-input w-100 mb-3" floatLabel="never">
                                            <input matInput class="mt-1 pt-2" placeholder="Search country name or code"
                                                formControlName="search" type="text" autocomplete="off" />
                                        </mat-form-field>
                                        <div class="country-code-wrap">
                                            <mat-option
                                                *ngFor="let country of countries | searchCountry:form?.value?.search"
                                                [value]="country?.dial_code">
                                                <div id="{{country?.dial_code}}"
                                                    class="item d-flex justify-content-between cursor-pointer"
                                                    (click)="selectCountryCode(country)">
                                                    <div>
                                                        <span class="flag">{{ country?.flag }}</span>
                                                        <span class="ms-2">{{ country?.name }}</span>
                                                    </div>
                                                    <div class="">{{ country?.dial_code }}</div>
                                                </div>
                                            </mat-option>
                                        </div>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="simple-input w-100 br8" [ngClass]="{'invalid-border': form?.invalid && formSubmitted}" floatLabel="never">
                                    <input matInput numberOnly placeholder="Mobile no." pattern="[0-9]*" formControlName="mobile_no"
                                        type="number" [maxlength]="10" autocomplete="off"/>
                                </mat-form-field>
                            </div>
                            <div class="invalid-feedback d-block text-start"
                                *ngIf="form.get('mobile_no').hasError('required') && formSubmitted"> Mobile
                                number
                                is
                                required.</div>
                            <div class="invalid-feedback d-block text-start"
                                *ngIf="form.get('mobile_no').hasError('minlength') && formSubmitted"> Mobile
                                number
                                should be 10 digits number.</div>
                        </span>

                        <span class="show-sm">
                            <div class="input-group mb-2" [ngClass]="{'invalid-border': form?.invalid && formSubmitted}">
                                <div class="input-group-prepend">
                                    <span class="input-group-text brL8 cursor-pointer" (click)="openCountryList()">
                                        <div class="d-inline-block">
                                            <span class="fs20">{{ selectedCountry?.flag }}</span>
                                            <span class="ms-2">{{ selectedCountry?.dial_code }}</span>
                                            <img src="assets/images/svg/arrow-drop-up.svg" alt="">
                                        </div>
                                    </span>
                                </div>
                                <input numberOnly type="number" pattern="[0-9]*" class="form-control brR8"
                                    placeholder="Mobile No." formControlName="mobile_no" maxlength="10">
                                <div class="invalid-feedback d-block text-start"
                                    *ngIf="form.get('mobile_no').hasError('required') && formSubmitted"> Mobile number
                                    is
                                    required.</div>
                                <div class="invalid-feedback d-block text-start"
                                    *ngIf="form.get('mobile_no').hasError('minlength') && formSubmitted"> Mobile number
                                    should be 10 digits number.</div>
                            </div>
                        </span>
                    </div>
                    <button class="btn cF w-100 br24 fs13 mt-4 fw500 mx-auto text-uppercase"
                        type="submit">{{ 'generateOtp.generateOtp' | translate }}</button>
                </form>
            </section>

            <footer class="text-center pt-4 pb-5">
                <div class="container">
                    <p class="m-0 w80 mx-auto">
                        {{ 'generateOtp.footerText' | translate }} <a target="_blank" routerLink="/terms"
                            title="{{ 'generateOtp.terms' | translate }}">{{ 'generateOtp.terms' | translate }}</a>
                        & <a routerLink="/privacy" target="_blank"
                            title="{{ 'generateOtp.privacy' | translate }}">{{ 'generateOtp.privacy' | translate }}</a>.
                    </p>
                </div>
            </footer>
        </div>
    </div>
</div>
