import { Component, OnInit, ChangeDetectorRef, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store, select } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { GetHeaderDataAction, ClearMessage } from './shared/store/actions/app.action';
import { BaseHeaderData } from './shared/models/header.model';
import { getSelectedHeader, isLoading } from './shared/store/selectors/app.selector';
import { MESSAGE_TYPE } from './shared/enums/message-type.enum';
import { setTheme } from 'ngx-bootstrap/utils';
import { State } from './shared/store/reducers';
import { ScriptService } from './core/services/script.service';
import { isPlatformBrowser } from '@angular/common';
import { FacebookPixelService } from './core/services/facebook-pixel.service';
import { COMMUNITY_DETAIL_PATH, ROUTE_PIXEL_MAP } from './shared/constants/routes.constant';
import { CommunityService } from './core/services/community.service';
import { takeUntil } from 'rxjs/operators';
import { LocalStorageService } from './core/services/localstorage.service';
import { STORAGE_KEY } from './shared/enums/storage-keys.enum';
import _ from 'lodash';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'LikeMinds';
    isLoading = false;
    subscriptions: Subscription[] = [];
    headerData: BaseHeaderData;
    publicLink = MESSAGE_TYPE.PUBLIC_LINK;
    privateLink = MESSAGE_TYPE.PRIVATE_LINK;
    snackbar = MESSAGE_TYPE.SNACKBAR;
    private destroy$$ = new Subject();

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        public translate: TranslateService,
        private store: Store<State>,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private scriptService: ScriptService,
        private facebookPixelService: FacebookPixelService,
        private communityService: CommunityService,
        private lsService: LocalStorageService
    ) {
        setTheme('bs4');
        translate.addLangs(['en', 'hi']);
        translate.setDefaultLang('en');
    }

    ngOnInit(): void {
        this.scriptService.load('google_places');
        this.getSelectedHeader();
        this.store.pipe(select(isLoading)).subscribe((isLoading) => {
            this.isLoading = isLoading;
            this.cdr.detectChanges();
        });

        // Get Headers Data
        this.store.dispatch(GetHeaderDataAction());

        //get branding
        this.communityService.currentCommunityData$$.pipe(takeUntil(this.destroy$$)).subscribe(res => {
            if (res?.id)
                this.communityService.getBranding(res?.id);
        })

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd && isPlatformBrowser(this.platformId)) {
                this.facebookPixelService.registerPixelEvent('init', '633859857057924');
                const urlArray = event.url.split('/');
                switch (urlArray[1]) {
                    case COMMUNITY_DETAIL_PATH:
                        this.facebookPixelService.registerPixelEvent('trackCustom', ROUTE_PIXEL_MAP[COMMUNITY_DETAIL_PATH], {
                            community_id: urlArray[2] || 0,
                        });
                        break;
                    default:
                        this.facebookPixelService.registerPixelEvent('track', 'PageView');
                        break;
                }
            }
        });
    }

    // Get selected header
    getSelectedHeader() {
        this.subscriptions.push(
            this.store.pipe(select(getSelectedHeader)).subscribe((headerData) => {
                this.headerData = headerData;
                this.cdr.detectChanges();
            })
        );
    }

    close() {
        this.store.dispatch(ClearMessage());
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.destroy$$.next(null);
        this.destroy$$.complete();
    }
}
