<ng-container>
    <div class="top-bar"></div>
    <div class="p-3">
        <div> 
            <h2 class="invite-heading">Share community</h2>            
            <div class="d-flex flex-column">
                <div class="d-flex flex-column copy-link">
                    <div class="d-flex align-items-center copy-link__box">
                        <img class="cursor-pointer" src="assets/images/svg/copy.svg" (click)="copyToClipBoard(0)" alt="">
                        <h3>Copy public invite Link</h3>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="user['task'] === 'openShareInfoPopup'" >
    <section class="send-response-section w-100 p-3">
        <div *ngIf="user['data']['index'] === 1">
            <h3><b>Free invitation</b></h3>
            <h4>Members joining via these links would not need to make a payment before joining your community. Use this link to invite volunteers, mentors or team members who would always be on Free membership plan.</h4>
        </div>  

        <div *ngIf="user['data']['index'] === 2">
            <h3><b>Payment required</b></h3>
            <h4>Members joining via these links would need to make a payment before joining your community. Use this link to invite new members who would need to renew their membership plan when it expires.</h4>
        </div>    

        <div class="d-flex justify-content-end close-share-info-btn">
            <mat-dialog-actions mat-button mat-dialog-close >
                <div (click)="closeShareInfoPopup()" class="cursor-pointer">
                    CONTINUE
                </div>
            </mat-dialog-actions>
        </div>
        
    </section>
</ng-container>
