export enum MESSAGE_TYPE {
    PRIVATE_LINK = 'PRIVATE LINK',
    PUBLIC_LINK = 'PUBLIC LINK',
    SNACKBAR = 'SNACKBAR',
    AJ_EXPIRED = 'AJ EXPIRED'
};

export enum PREVIEW_TYPE {
  CHATROOM = 'chatroom',
  COMMUNITY = 'community',
  DIRECTORY = 'directory'
};
