import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upgrade-your-plan-dialog',
  templateUrl: './upgrade-your-plan-dialog.component.html',
  styleUrls: ['./upgrade-your-plan-dialog.component.scss']
})
export class UpgradeYourPlanDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
