import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var document: any;

export interface Scripts {
    name: string;
    src: string;
}
export const ScriptStore: Scripts[] = [
    {
        name: 'google_places',
        src: `https://maps.googleapis.com/maps/api/js?key=${environment.firebasePlaceApiKey}&libraries=places&language=en`,
    },
    // { name: 'google_places', src: `https://maps.googleapis.com/maps/api/js?key=${environment.firebaseConfig.apiKey}&libraries=places&language=en` }
];

@Injectable({
    providedIn: 'root',
})
export class ScriptService {
    private scripts: any = {};

    constructor() {
        ScriptStore.forEach((script: any) => {
            this.scripts[script.name] = {
                loaded: false,
                src: script.src,
            };
        });
    }

    load(...scripts: string[]) {
        var promises: any[] = [];
        scripts.forEach((script) => promises.push(this.loadScript(script)));
        return Promise.all(promises);
    }

    loadScript(name: string) {
        return new Promise((resolve, reject) => {
            if (this.scripts[name].loaded) resolve({ script: name, loaded: true, status: 'Already Loaded' });
            else {
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.scripts[name].src;
                if (script.readyState) {
                    script.onreadystatechange = () => {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                } else {
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    }
}
