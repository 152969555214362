<section class="report-wrap" *ngIf="data">
  <div class="cross-div">
    <span class="close-icon" (click)="close()"><img src="../../../../../assets/images/svg/cross.svg" /></span>
  </div>

  <div class="primary-header">{{data.title}}</div>

  <div class="details">
    <div class="secondary-header">Please specify the problem to continue</div>
    <div class="description">{{data.desc}}</div>
  </div>

  <div class="buttons" *ngIf="reportTagsList?.length > 0">
    <div *ngFor="let tag of reportTagsList">
      <button [ngClass]="{'selected-problem': clickedTag?.id === tag?.id, 'not-selected-problem': clickedTag?.id != tag?.id}"
              (click)="clickButton(tag)">{{tag?.name}}</button>
    </div>
  </div>

  <div *ngIf="clickedTag.name === 'Others'" class="others-input">
    <label>{{data.label}}</label>
    <input type="text" [ngModel]="othersValue" (ngModelChange)="onTextChange($event)">
  </div>

  <div class="report-button">
    <button [ngClass]="{'selected-button': submitActive, 'not-selected-button': !submitActive}"
            (click)="report()">REPORT</button>
  </div>
</section>
