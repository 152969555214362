<div class="home-page-profile-drawer">
    <div class="home-page-profile-drawer-top">
        <img
            (click)="closeProfileDrawer()"
            class="profile-drawer-cancel-btn"
            src="../../../../../assets/images/svg/home_page_profile_drawer_cancel.svg"
            alt="cancel-btn"
        />

        <div class="user-info">
            <div class="image-container">
                <img
                    class="profile-image"
                    [src]="profileData?.member?.image_url"
                    onerror="this.src='../../../../../assets/images/png/avatar.png'"
                />
            </div>

            <div class="profile-info">
                <div class="name">{{ profileData?.member?.name }}</div>
                <div class="otherInfo">
                    <img src="https://prod-likeminds-media.s3.ap-south-1.amazonaws.com/files/utilities/company%403x.png" />

                    <span> {{ basicQuestionAnswers[0]?.question_answer?.answer || notMentioned }} &nbsp; </span>
                    <span> @ {{ basicQuestionAnswers[1]?.question_answer?.answer || notMentioned }} </span>
                </div>
                <div class="otherInfo">
                    <img src="../../../../assets/images/svg/ch-img/location.svg" />
                    {{ basicQuestionAnswers[2]?.question_answer?.answer || notMentioned }}
                </div>
            </div>
        </div>

        <div class="profile-expert">
            <ng-container *ngFor="let questionAnswer of basicQuestionAnswers">
                <ng-container *ngIf="questionAnswer.question.question_title === 'Area of Specialization'">
                    <div>
                        <img src="../../../../assets/images/svg/ch-img/expert.svg" class="mr-2" width="24" height="24" />
                        Expert in
                    </div>
                    <div [innerHTML]="questionAnswer.question_answer.answer | keys: 'custom-tag-class'"></div>
                </ng-container>
            </ng-container>
            <button class="dm-btn" *ngIf="!selfProfile" (click)="goToDm()">
                <img src="../../../../assets/images/svg/ch-img/send.svg" alt="DM" /> Message
            </button>
            <button class="edit-btn" *ngIf="selfProfile" (click)="editProile()">
                <img src="../../../../assets/images/svg/ch-img/edit-light.svg" alt="Edit" /> Edit Profile
            </button>
        </div>

        <!-- About -->
        <div class="profile-details border-0">
            <div class="title d-flex">
                <div>About</div>
                <div (click)="editAboutUs()" *ngIf="selfProfile">
                    <img src="../../../../assets/images/svg/ch-img/edit.svg" alt="Edit" />
                </div>
            </div>
            <ng-container *ngIf="aboutQuestionAnswers">
                <div class="profile-detail m-0">
                    <div class="desc pb-3">
                        <div class="d-flex align-items-center">
                            <div>
                                <div
                                    class="desc-text-about"
                                    [innerHTML]="aboutQuestionAnswers[0]?.question_answer?.answer || notMentioned | newlineToBr"
                                ></div>
                            </div>
                        </div>
                    </div>

                    <!-- Block -->
                    <div class="desc pb-3">
                        <div class="d-flex align-items-center">
                            <div class="img-box">
                                <img src="../../../../assets/images/svg/ch-img/community-icon.svg" alt="icon" />
                            </div>
                            <div>
                                <div class="desc-text-about">Scale of Community</div>
                                <div class="desc-text">
                                    {{ aboutQuestionAnswersObject['Scale of Community'] || notMentioned }}

                                    <!-- <ng-container *ngIf="question?.question?.question_title === 'Scale of Community'; else noData">
                                        {{ question?.question_answer?.answer }}
                                    </ng-container>
                                    <ng-template #noData>
                                        {{ notMentioned }}
                                    </ng-template> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Block -->

                    <!-- Block -->
                    <div class="desc pb-3">
                        <div class="d-flex align-items-center">
                            <div class="img-box">
                                <img src="../../../../assets/images/svg/ch-img/industry.svg" alt="icon" />
                            </div>
                            <div>
                                <div class="desc-text-about">Industry</div>

                                <div class="desc-text">
                                    {{ aboutQuestionAnswersObject['Industry'] || notMentioned }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Block -->

                    <!-- Block -->

                    <div class="desc pb-3">
                        <div class="d-flex align-items-center">
                            <div class="img-box">
                                <img src="../../../../assets/images/svg/ch-img/community-experience.svg" alt="icon" />
                            </div>
                            <div>
                                <div class="desc-text-about">Community Experience</div>

                                <div class="desc-text">
                                    {{ aboutQuestionAnswersObject['Community Experience'] || notMentioned }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Block -->
                </div>
            </ng-container>
        </div>

        <!-- Tool Stack -->
        <div class="profile-details seprator pt-4">
            <div class="title">
                <div>Tool Stack</div>
                <div (click)="editToolStack()" *ngIf="selfProfile">
                    <img src="../../../../assets/images/svg/ch-img/edit.svg" alt="Edit" />
                </div>
            </div>
            <ng-container *ngIf="toolQuestionAnswers.length > 0; else noAboutData">
                <div class="profile-detail" *ngFor="let questionAnswer of toolQuestionAnswers">
                    <div class="desc">
                        <img [src]="questionAnswer?.question?.image_url" alt="" *ngIf="questionAnswer?.question?.image_url" />
                        <div [innerHTML]="questionAnswer.question_answer.answer | keys: 'custom-tag-class'"></div>
                    </div>
                </div>
            </ng-container>
        </div>

        <!-- Interest Areas -->
        <div class="profile-details">
            <div class="title">
                <div>Interest Areas</div>
                <div (click)="editInterest()" *ngIf="selfProfile">
                    <img src="../../../../assets/images/svg/ch-img/edit.svg" alt="Edit" />
                </div>
            </div>
            <ng-container *ngIf="interestQuestionAnswers.length > 0; else noAboutData">
                <div class="profile-detail" *ngFor="let questionAnswer of interestQuestionAnswers">
                    <div class="desc">
                        <img [src]="questionAnswer?.question?.image_url" alt="" *ngIf="questionAnswer?.question?.image_url" />
                        <div [innerHTML]="questionAnswer.question_answer.answer | keys: 'custom-tag-class'"></div>
                    </div>
                </div>
            </ng-container>
            <ng-template #noAboutData>
                <p class="default">{{ notMentioned }}</p>
            </ng-template>
        </div>

        <!-- reach out to me for -->
        <div class="profile-details">
            <div class="title">
                <div>Reach out to me for</div>
                <div (click)="editReach()" *ngIf="selfProfile">
                    <img src="../../../../assets/images/svg/ch-img/edit.svg" alt="Edit" />
                </div>
            </div>
            <ng-container *ngIf="reachQuestionAnswers.length > 0; else noAboutData">
                <div class="profile-detail" *ngFor="let questionAnswer of reachQuestionAnswers">
                    <div class="desc" [innerHTML]="questionAnswer.question_answer.answer | keys: 'custom-tag-class'"></div>
                </div>
            </ng-container>
            <ng-template #noAboutData>
                <p class="default">{{ notMentioned }}</p>
            </ng-template>
        </div>

        <!-- Social -->
        <div class="profile-details border-0">
            <div class="title">
                <div>Socials</div>
                <div (click)="editSocial()" *ngIf="selfProfile">
                    <img src="../../../../assets/images/svg/ch-img/edit.svg" alt="Edit" />
                </div>
            </div>
            <ng-container *ngIf="socialsQuestionAnswers.length > 0; else noAboutData">
                <div class="profile-detail">
                    <div class="desc">
                        <a
                            [href]="
                                questionAnswer?.question_answer?.answer.startsWith('http')
                                    ? questionAnswer?.question_answer?.answer
                                    : 'http://'.concat(questionAnswer?.question_answer?.answer)
                            "
                            class="me-2 mb-1 float-start social-icon"
                            target="_blank"
                            *ngFor="let questionAnswer of socialsQuestionAnswers"
                        >
                            <img [src]="questionAnswer.question.image_url" alt="icons" *ngIf="questionAnswer.question.image_url" />
                        </a>
                    </div>
                </div>
            </ng-container>
            <ng-template #noAboutData>
                <p class="default">{{ notMentioned }}</p>
            </ng-template>
        </div>

        <!-- Verification Pending -->
        <ng-container *ngIf="memberState?.member?.state == 1 && profileData?.member?.state === 3">
            <div class="profile-details border-0 text-center">
                <div class="title">
                    <div>Verification is Pending for this User</div>
                </div>

                <div class="profile-detail">
                    <button class="btn-join btn-req" (click)="acceptRejectMember(true, profileData?.member?.uuid)">
                        <img src="../../../../../assets/images/svg/done-green.svg" alt="join" width="16px" /> Approve
                    </button>
                    <button class="btn-reject btn-req" (click)="acceptRejectMember(false, profileData?.member?.uuid)">
                        <span>&times;</span> Reject
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</div>
