<div class="lm-wrapper">
    <div class="wrapper-header">
        Interest
        <div class="close cursor-pointer" (click)="close()">
            <img src="../../../../assets/images/svg/ch-img/close.svg" alt="close" />
        </div>
    </div>

    <div class="wrapper-body">
        <div class="searchbox">
            <img src="../../../../assets/images/svg/search-icon.svg" alt="search" />
            <input type="text" class="form-control" [(ngModel)]="searchTerm" (input)="search()" placeholder="Search" />
        </div>

        <div class="wrapper-items">
            <ng-container *ngFor="let option of filteredToolStackOptions">
                <div class="form-group" *ngIf="option?.value">
                    <label class="form-container">
                        {{ option.value }}
                        <div>
                            <ng-container *ngIf="selectedOptionObj.length > 0">
                                <input
                                    type="checkbox"
                                    [(ngModel)]="option.selected"
                                    (change)="updateSelectedOptions($event, option)"
                                    [checked]="shouldCheckit(option)"
                                />
                            </ng-container>
                            <ng-container *ngIf="selectedOptionObj.length === 0">
                                <input type="checkbox" [(ngModel)]="option.selected" (change)="updateSelectedOptions($event, option)" />
                            </ng-container>

                            <span class="checkmark"></span>
                        </div>
                    </label>
                </div>
            </ng-container>

            <div class="form-group">
                <div class="d-flex align-items-center cursor-pointer" (click)="newOptionDialog()">
                    <img src="../../../../assets/images/svg/ch-img/create-new.svg" alt="Create new" />
                    <div class="ms-2 text-primary">Add a new option</div>
                </div>
            </div>
        </div>
        <div class="wrapper-footer">
            <button type="submit" (click)="onSubmit()">Save</button>
        </div>
        <!-- </form> -->
    </div>
</div>
