<div class="choice-dialog-wrap" *ngIf="data">
  <div class="handle-wrap justify-content-center">
    <img src="assets/images/svg/rectangle.svg" alt="handle">
  </div>
  <div class="d-flex justify-content-center my-3">
    <img src="assets/images/svg/warning.svg" alt="warning">
  </div>
  <h2 *ngIf="data.title">{{data.title}}</h2>
  <h3 *ngIf="data.subTitle as subTitle">{{subTitle}}</h3>
  <div class="button-wrap" *ngIf="data.choices && data.choices.length">
      <button class="btn btn-submit px-5 my-2" *ngFor="let btn of data.choices" (click)="output.emit(btn)">
          {{btn}}
      </button>
  </div>
</div>
