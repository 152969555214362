<svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <filter x="-46.3%" y="-42.6%" width="192.6%" height="192.6%" filterUnits="objectBoundingBox" id="a">
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" in="shadowBlurOuter1" />
        </filter>
        <rect id="b" x="0" y="0" width="54" height="54" rx="27" />
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g transform="translate(16 14)">
            <use fill="#000" filter="url(#a)" xlink:href="#b" />
            <use class="fill-brand-btn" xlink:href="#b" />
        </g>
        <path d="M54.115 34.168 40.25 48.033l.24.241-1.28 1.28-7.92-7.92 1.28-1.28 6.4 6.4 13.866-13.866 1.28 1.28z"
            fill="#FFF" stroke="#FFF" />
    </g>
</svg>