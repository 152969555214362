<svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <path fill="#D8D8D8" fill-opacity="0" d="M0 0H52V52H0z"
                    transform="translate(-611 -689) translate(611 689)" />
                <g class="fill-brand" fill-rule="nonzero">
                    <path
                        d="M25.375 8H2.625C1.175 8 0 9.343 0 11v18c0 1.657 1.175 3 2.625 3h22.75C26.825 32 28 30.657 28 29V11c0-1.657-1.175-3-2.625-3zm-.328 21H2.953c-.181 0-.328-.168-.328-.375v-17.25c0-.207.147-.375.328-.375h22.094c.181 0 .328.168.328.375v17.25c0 .207-.147.375-.328.375zM7 13.5c-1.208 0-2.188 1.12-2.188 2.5s.98 2.5 2.188 2.5c1.208 0 2.188-1.12 2.188-2.5S8.207 13.5 7 13.5zM5.25 26h17.5v-5l-4.786-5.47c-.256-.293-.672-.293-.928 0L10.5 23l-2.161-2.47c-.256-.293-.672-.293-.928 0L5.25 23v3zM31.543 10.574L31.543 6.082 35.508 6.082 35.508 4.373 31.543 4.373 31.543.223 29.727.223 29.727 4.373 25.762 4.373 25.762 6.082 29.727 6.082 29.727 10.574z"
                        transform="translate(-611 -689) translate(611 689) translate(12 6)" />
                </g>
            </g>
        </g>
    </g>
</svg>