<section [ngClass]="{ 'p-3': isPollChatRoom }">
    <main>
        <div class="d-flex justify-content-between align-items-center">
            <h3 class="mb-0"><b>New Poll</b></h3>
            <span (click)="close()" class="h1 display-4 cursor-pointer">&#735;</span>
        </div>
        <form [formGroup]="pollRoomForm" (ngSubmit)="createPoll()">
            <div class="mb-4">
                <div class="text-area d-flex flex-column">
                    <textarea
                        [ngClass]="{ 'poll-textarea': isPollChatRoom }"
                        class="form-control p-3"
                        formControlName="pollQuestion"
                        placeholder="Ask a question*"
                        rows="2"
                    ></textarea>
                    <!-- <div class="d-flex justify-content-between text-muted">
                        <div>&nbsp;</div>
                          <div>
                            <span *ngIf="pollRoomForm.value.pollQuestion?.length < 10; else counts"
                                >Min {{ 10 - pollRoomForm.value.pollQuestion?.length }} characters</span
                            >
                            <ng-template #counts>
                                <span>{{ pollRoomForm.value.pollQuestion?.length }} characters</span>
                            </ng-template>
                            <circle-progress
                                class="circle"
                                [percent]="(pollRoomForm.value.pollQuestion?.length || 0) * 10"
                                [radius]="4"
                                [outerStrokeWidth]="2"
                                [innerStrokeWidth]="2"
                                [space]="-2"
                                [innerStrokeColor]="'#CFF7F3'"
                                [outerStrokeColor]="'#00CAB3'"
                                [titleFontSize]="2"
                                [unitsFontSize]="2"
                                [showTitle]="false"
                                [showUnits]="false"
                                [showSubtitle]="false"
                                [animation]="true"
                                [animationDuration]="300"
                                [startFromZero]="false"
                                [responsive]="true"
                            >
                            </circle-progress>
                        </div>
                    </div> -->
                </div>
            </div>

            <div formArrayName="options">
                <label class="label mb-2">Poll Options</label>
                <div class="mb-4" *ngFor="let item of pollOptions.controls; let optionIndex = index" [formGroupName]="optionIndex">
                    <div class="position-relative text-area d-flex justify-content-between">
                        <input
                            type="text"
                            class="form-control pe-5 text-capitalize"
                            [ngClass]="{ 'poll-option': isPollChatRoom }"
                            formControlName="text"
                            placeholder="Option"
                        />
                        <div class="d-flex align-items-center position-absolute mx-2 cross-image-holder">
                            <img
                                src="assets/images/svg/cancel_gray.svg"
                                class="cursor-pointer"
                                alt="about event"
                                (click)="deletePollOption(optionIndex)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-2">
                <div class="text-area d-flex justify-content-between" [ngClass]="{ add_option: isPollChatRoom }">
                    <div class="cursor-pointer" (click)="addPollOption()">
                        <app-plus-round-icon class="me-1 cursor-pointer"></app-plus-round-icon>
                        <span class="color-primary">Add an option</span>
                    </div>
                </div>
            </div>

            <div class="mb-3 mt-2">
                <label class="label mb-2">Poll Expires on</label>
                <div class="bg-white text-area d-flex position-relative justify-content-between align-items-center date-picker-container">
                    <div class="me-1"><app-calendar-square-icon></app-calendar-square-icon></div>

                    <mat-form-field floatLabel="never">
                        <input
                            matInput
                            [ngxMatDatetimePicker]="pickerFrom"
                            style="color: white !important"
                            [readonly]="true"
                            formControlName="expiryDate"
                            [min]="minDate"
                        />
                        <div *ngIf="disableSubmitButtonDateFlag" style="position: absolute; top: 0px; color: #acb7c0">DD-MM-YYYY hh:mm</div>
                        <div *ngIf="!disableSubmitButtonDateFlag" style="position: absolute; top: 0px">
                            {{ selectedDate }}
                        </div>
                        <mat-datepicker-toggle
                            style="position: absolute; right: -10px; top: -22px"
                            *ngIf="disableSubmitButtonDateFlag"
                            class="me-2"
                            matSuffix
                            [for]="pickerFrom"
                        >
                        </mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #pickerFrom [enableMeridian]="true"></ngx-mat-datetime-picker>
                    </mat-form-field>
                    <img
                        src="assets/images/svg/cancel_gray.svg"
                        class="cursor-pointer cancel-date me-2 position-absolute"
                        alt="about event"
                        (click)="removeDate()"
                        *ngIf="!disableSubmitButtonDateFlag"
                    />
                </div>
            </div>

            <ng-container *ngIf="advanceOptions">
                <div class="mb-3 advanced-options-container bg-white">
                    <div class="text-area d-flex justify-content-between advanced-options-containe__div">
                        <div>Allow voters to add options</div>
                        <mat-slide-toggle formControlName="allowAddOption"></mat-slide-toggle>
                    </div>
                    <div class="text-area custom-text-area d-flex justify-content-between advanced-options-containe__div">
                        <div>Anonymous Poll</div>
                        <mat-slide-toggle formControlName="anonymousPoll"></mat-slide-toggle>
                    </div>
                    <div class="text-area custom-text-area d-flex justify-content-between advanced-options-containe__div">
                        <div>Don't show live results</div>
                        <mat-slide-toggle formControlName="liveResult"></mat-slide-toggle>
                    </div>
                    <div class="text-area custom-text-area d-flex justify-content-between advanced-options-containe__lastdiv">
                        <div class="w-100">
                            <label class="custom-label mb-1">User can vote for</label>
                            <div class="row">
                                <div class="col-5 userVote">
                                    <select
                                        class="form-control custom-select"
                                        style="border: 0px solid grey"
                                        formControlName="multipleSelectState"
                                    >
                                        <Option value="Exactly">Exactly</Option>
                                        <Option value="At Most">At Most</Option>
                                        <Option value="At Least">At Least</Option>
                                    </select>
                                    <span class="arrowDown">
                                        <img src="assets/images/svg/poll-vote.svg" alt="Poll Vote For" />
                                    </span>
                                </div>
                                <div class="col-2 text-center pt-2">=</div>
                                <div class="col-5 userVote">
                                    <select
                                        class="form-control custom-select"
                                        style="border: 0px solid grey"
                                        formControlName="multipleSelectNo"
                                    >
                                        <option [value]="number" *ngFor="let number of multiSelectOption.slice(0, pollOptions.length)">
                                            <span *ngIf="number > 1">{{ number }} Options</span>
                                            <span *ngIf="number == 1">{{ number }} Option</span>
                                        </option>
                                    </select>
                                    <span class="arrowDown">
                                        <img src="assets/images/svg/poll-vote.svg" alt="Poll Vote For" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="mb-3 text-center">
                <label (click)="showAdvanceOption()" class="label m-0"
                    >Advanced<img
                        class="cursor-pointer advanced-option-arrow-icon"
                        [ngClass]="{ 'inverted-arrow': advanceOptions }"
                        src="../../../../assets/images/svg/down-arrow-light.svg"
                /></label>
            </div>

            <div class="mb-3 d-flex justify-content-center">
                <button
                    type="submit"
                    class="btn btn-lm-round round-button"
                    [ngClass]="{
                        'bg-green': !disableSubmitButtonQuesFlag && !disableSubmitButtonOptionFlag && !disableSubmitButtonDateFlag
                    }"
                    [disabled]="!(!disableSubmitButtonQuesFlag && !disableSubmitButtonOptionFlag && !disableSubmitButtonDateFlag)"
                >
                    <img src="assets/images/svg/completed.svg" alt="back arrow" />
                </button>
            </div>
        </form>
    </main>
</section>
