/**
 * @class ApiInterceptor
 * @description It intercepts all the HTTP requests
 */
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
    FETCH_ALL,
    FETCH_CONVERSATION_API,
    GET_COMMUNITY_FEED_CHATROOMS,
    SEARCH_MEMBER_DIRECTORY,
} from '../../shared/constants/api.constant';
import { LocalStorageService } from '../services/localstorage.service';
import { FETCH_FILTERS } from '../../shared/constants/api.constant';
import { STORAGE_KEY } from '../../shared/enums/storage-keys.enum';
import { environment } from '../../../environments/environment';
import { DeviceService } from '../services/device.service';
import { IUser } from '../../shared/models/user.model';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
        private localStorageService: LocalStorageService,
        private deviceStorage: DeviceService,
        private cookieService: CookieService
    ) {}
    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let BASE_URL = '';
        BASE_URL = environment.baseUrl;

        const isAssetRequest = httpRequest.url.includes('assets');
        const isLinkedinRequest = httpRequest.url.includes('linkedin');
        const isInviteRequest = httpRequest.url.includes('/community/invite');
        const isFetchUrlRequest = httpRequest.url.includes('/chatroom/share');
        const isMergeAccountRequest = httpRequest.url.includes('merge_account');
        const isJoinCommunityRequest = httpRequest.url.includes('skip_community');
        const isLeaveCommunityRequest = httpRequest.url.includes('remove_from_member');
        const isCreateEventRequest = httpRequest.url.includes('event/create');
        const isEditConversationRequest = httpRequest.url.includes('edit_conversation');
        const isMarkRead = httpRequest.url.includes('mark_read');
        const isRenewMember = httpRequest.url.includes('renew_member');
        const isChatroomMuteRequest = httpRequest.url.includes('chatroom_mute');
        const isUploadUriReq = httpRequest.url.includes('/cms/banner/upload_uri');
        const isUserConfigRequest = httpRequest.url.includes('/user/config');
        const isConversationFetchAPI = httpRequest.url.includes(FETCH_CONVERSATION_API);
        const isFetchAllUsers = httpRequest.url.includes(FETCH_ALL);
        const isLogoutRequest = httpRequest.url.includes('/logout');
        const removeAsCommunityManager = httpRequest.url.includes('remove_community_manager');
        const transferOwnerShip = httpRequest.url.includes('transfer_ownership');
        const isCommunityFetchCommunityMeta = httpRequest.url.includes('community/fetch_community_meta');
        const isCommunityMemberFetchFeed = httpRequest.url.includes(GET_COMMUNITY_FEED_CHATROOMS);
        const isOTPRequest = httpRequest.url.includes('otp');
        const isVerifyRequest = httpRequest.url.includes('verify');
        const isLoginRequest = httpRequest.url.includes('login');
        const isRefreshRequest = httpRequest.url.includes('refresh');
        const isTokenRequest = httpRequest.url.includes('user/token');
        const isInitiateRequest = httpRequest.url.includes('initiate');
        const isMetaRequest = httpRequest.url.includes('user/meta');
        const isOnboardingRequest = httpRequest.url.includes('sdk/onboarding');
        const isQuestionsRequest = httpRequest.url.includes('community/questions');
        const isMemberStateRequest = httpRequest.url.includes('community/member/state');
        const isProfileState = httpRequest.url.includes('community/member/profile');
        const isSocialLoginState = httpRequest.url.includes('social/login');
        const isIntroEampleRequest = httpRequest.url.includes('community/intro_examples');
        const isMemberJoinRequest = httpRequest.url.includes('member/join');
        const isFetchFiltersRequest = httpRequest.url.includes(FETCH_FILTERS);
        const isUploadUriAws = httpRequest.url.includes(`${environment.awsS3MediaUrl}`);
        // adding community/member/search to kettle
        const isMemberSearchDirectory = httpRequest.url.includes(SEARCH_MEMBER_DIRECTORY);

        // subscription apis
        const isEventCohortRequest = httpRequest.url.includes('fetch_event_plan_with_cohort');
        const isSubscriptionCreateEventPlanRequest = httpRequest.url.includes('subscription/create_event_plan');
        const isSubscriptionUpdateEventPlanRequest = httpRequest.url.includes('subscription/update_event_plan');
        const isFetchEventPlanRequest = httpRequest.url.includes('subscription/fetch_event_plan');

        // Kettle Apis
        if (
            isTokenRequest ||
            isInitiateRequest ||
            isOTPRequest ||
            isVerifyRequest ||
            isQuestionsRequest ||
            isMemberStateRequest ||
            isRefreshRequest ||
            isUserConfigRequest ||
            isProfileState ||
            isSocialLoginState ||
            isIntroEampleRequest ||
            isOnboardingRequest ||
            isLogoutRequest ||
            isMemberJoinRequest ||
            isInviteRequest ||
            isFetchUrlRequest ||
            isFetchFiltersRequest ||
            isMemberSearchDirectory ||
            isMarkRead
        ) {
            BASE_URL = environment.kettleBaseUrl;
        }
        // Subscription Apis
        if (
            isSubscriptionCreateEventPlanRequest ||
            isEventCohortRequest ||
            isSubscriptionUpdateEventPlanRequest ||
            isFetchEventPlanRequest
        ) {
            BASE_URL = environment.subscriptionBaseUrl;
        }

        const url = `${isAssetRequest || isLinkedinRequest || isUploadUriReq || isUploadUriAws ? '' : BASE_URL}` + httpRequest.url;
        httpRequest = httpRequest.clone({ url });
        const likemindsUser: IUser = this.localStorageService.getSavedState(STORAGE_KEY.LIKEMINDS_USER);

        let headers: HttpHeaders = new HttpHeaders();
        if (!isAssetRequest && !isLinkedinRequest) {
            headers = new HttpHeaders({
                'x-platform-code': environment.platformCode,
                'x-version-code': environment.versionCode,
                // Accept: 'application/json',
                // 'Content-Type': 'application/json',
            });
        }

        if (
            !isLinkedinRequest ||
            !isMergeAccountRequest ||
            !isJoinCommunityRequest ||
            !isLeaveCommunityRequest ||
            !removeAsCommunityManager ||
            !transferOwnerShip ||
            !isCommunityFetchCommunityMeta
        ) {
            headers.append('Content-Type', 'application/json');
        }

        if (!isOTPRequest && !isLoginRequest && !isRefreshRequest && !isTokenRequest && !isVerifyRequest && !isInitiateRequest) {
            headers = headers.set('Authorization', `Bearer ${this.localStorageService.getSavedState(STORAGE_KEY.ACCESS_TOKEN_LTM)}`);
        }

        if (isOTPRequest || isVerifyRequest || isOnboardingRequest) {
            headers = headers.set('Authorization', `Bearer ${this.localStorageService.getSavedState(STORAGE_KEY.REFRESH_TOKEN_OTM)}`);
        }

        if (isMemberStateRequest || isUserConfigRequest || isProfileState) {
            headers = headers.set('Authorization', `Bearer ${this.localStorageService.getSavedState(STORAGE_KEY.ACCESS_TOKEN_LTM)}`);
        }

        if (isLoginRequest) {
            headers = headers.set(
                'Authorization',
                `Bearer ${this.localStorageService.getSavedState(STORAGE_KEY.ACCESS_TOKEN_VTM)?.access_token}`
            );
        }

        if (isIntroEampleRequest) {
            if (this.localStorageService.getSavedState(STORAGE_KEY.ACCESS_TOKEN_LTM)) {
                headers = headers.set('Authorization', `Bearer ${this.localStorageService.getSavedState(STORAGE_KEY.ACCESS_TOKEN_LTM)}`);
            } else if (this.localStorageService.getSavedState(STORAGE_KEY.ACCESS_TOKEN_VTM)) {
                headers = headers.set('Authorization', `Bearer ${this.localStorageService.getSavedState(STORAGE_KEY.ACCESS_TOKEN_VTM)}`);
            } else {
                headers = headers.set(
                    'Authorization',
                    `Bearer ${this.localStorageService.getSavedState(STORAGE_KEY.ACCESS_TOKEN_VTM)?.access_token}`
                );
            }
        }

        if (isRefreshRequest) {
            headers = headers.set('Authorization', `Bearer ${this.localStorageService.getSavedState(STORAGE_KEY.REFRESH_TOKEN_RTM)}`);
        }

        if (isInitiateRequest || isQuestionsRequest) {
            if (this.localStorageService.getSavedState(STORAGE_KEY.ACCESS_TOKEN_VTM)) {
                headers = headers.set('Authorization', `Bearer ${this.localStorageService.getSavedState(STORAGE_KEY.ACCESS_TOKEN_VTM)}`);
            }
        }

        if (isSocialLoginState) {
            if (this.localStorageService.getSavedState(STORAGE_KEY.REFRESH_TOKEN_OTM)) {
                headers = headers.set('Authorization', `Bearer ${this.localStorageService.getSavedState(STORAGE_KEY.REFRESH_TOKEN_OTM)}`);
            }
        }

        // Add x-api-key in headers
        if (
            isInitiateRequest ||
            isOTPRequest ||
            isVerifyRequest ||
            isOnboardingRequest ||
            isQuestionsRequest ||
            isTokenRequest ||
            isMetaRequest
        ) {
            headers = headers.set('x-api-key', `${environment.xApiKey}`);
        }

        // Unsubscribe email start from here
        const onUnsubscribed = httpRequest.url.includes('/unsubscribe_email_notifications');
        if (onUnsubscribed) headers = headers.append('x-member-id', this.cookieService.get(STORAGE_KEY.EMAIL_UNSUB_USER_ID));

        // Unsubscribe email ends here
        const createConvoReq = httpRequest.url.includes('/conversation/create');
        const convoMetaReq = httpRequest.url.includes('/conversation_meta');
        const deviceId = this.deviceStorage.deviceID;

        if ((createConvoReq || convoMetaReq || isConversationFetchAPI) && deviceId) {
            // headers = headers.append('x-device-id', deviceId);
            headers = headers.append('x-device-id', 'web_device_' + likemindsUser?.id);
        }

        if (
            isLinkedinRequest ||
            isMergeAccountRequest ||
            isJoinCommunityRequest ||
            isLeaveCommunityRequest ||
            isEditConversationRequest ||
            isChatroomMuteRequest ||
            isRenewMember ||
            isMarkRead ||
            removeAsCommunityManager ||
            transferOwnerShip ||
            isCommunityFetchCommunityMeta
        ) {
            headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
        }

        if (isCommunityMemberFetchFeed) headers = headers.append('x-accept-version', 'v2');

        if (isCreateEventRequest) {
            headers = headers.append('Content-Type', 'text/plain');
        }

        if (isUploadUriReq || isFetchAllUsers) {
            headers = headers.append('x-username', 'teamGrowth');
            headers = headers.append('x-password', 'TheLMGrowth@1001');
        }
        if (likemindsUser && !isAssetRequest && !isLinkedinRequest && !onUnsubscribed) {
            headers = headers.append('x-member-id', likemindsUser.id.toString());
        }

        httpRequest = httpRequest.clone({ headers });

        return next.handle(httpRequest).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    // Handle success response here
                },
                (error: any) => {
                    if (error instanceof HttpErrorResponse) {
                        console.log('Something went wrong: ' + JSON.stringify(error));
                    }
                }
            )
        );
    }
}
