<div class='add-plan-wrapper'>
    <div class="add-plan-header"> 
        Add Membership Plans
    </div>
    <div class="add-plan-content">
        You have not created any membership plans yet. To invite members to join your community via paid invitation, Connect with the LikeMinds team(<a class="mail contact" href="mailto:hi@likeminds.community">hi@likeminds.community</a>).
    </div>
    <div class="">
        <div class="add-plan-btn space-bottom"><a class="mail" href="mailto:hi@likeminds.community">Contact Us</a></div>
        <div class="add-plan-btn cancel_color" mat-dialog-close>Cancel</div>
    </div>
</div>