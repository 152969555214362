<section>
    <main>
        <form [formGroup]="chatRoomForm" (ngSubmit)="onCreateChatRoom()">
            <div class="mb-4">
                <div class="warning-container" *ngIf="showWarning">
                    <p>Files above 100 MB are not allowed</p>
                    <p class="cursor-pointer" (click)="showWarning = false">&#x2715;</p>
                </div>
                <div class="text-area d-flex flex-column" [ngClass]="{ 'is-invalid': submitted && formDataValue.conversationArea.errors }">
                    <textarea
                        class="form-control"
                        formControlName="conversationArea"
                        placeholder="Describe what is the chatroom about"
                        rows="3"
                    ></textarea>

                    <div class="d-flex justify-content-between text-muted">
                        <div>
                            <!-- Secretc: Chatroom Type -->
                            <ng-container *ngIf="is_cm">
                                <div class="crType me-1 suffix align-self-end" (click)="secretChatroomDialog()">
                                    <span class="me-1">
                                        <img
                                            src="../../../../../assets/images/svg/open.svg"
                                            alt="Icon"
                                            *ngIf="crType === 'open'; else lockIcon"
                                            width="18px"
                                            height="16px"
                                        />
                                        <ng-template #lockIcon>
                                            <img src="../../../../../assets/images/svg/secret_lock.svg" alt="Icon" />
                                        </ng-template>
                                    </span>
                                    <span class="me-1">
                                        {{ crType }}
                                    </span>
                                    <img src="assets/images/svg/secret_down_arrow.svg" alt="down arrow" />
                                </div>
                            </ng-container>
                            <!-- Secretc: Chatroom Type -->

                            <!-- Attachment Btn -->
                            <span class="suffix align-self-end crType">
                                <img
                                    matSuffix
                                    class="op5 cursor-pointer"
                                    [matMenuTriggerFor]="uploadMenu"
                                    src="assets/images/svg/secret_attached.svg"
                                    alt="Attach"
                                    [ngClass]="{ disabled: sendingMessage }"
                                    width="18px"
                                    height="18px"
                                />
                                <mat-menu #uploadMenu xPosition="before" yPosition="above" class="upload-menu">
                                    <label class="upload-menu-tile">
                                        <img src="assets/images/svg/gallery.svg" alt="Gallery" />
                                        <h4>Photos</h4>
                                        <input
                                            multiple
                                            #imageFileUpload
                                            type="file"
                                            accept="image/*"
                                            (change)="uploadFiles($event, 'image')"
                                            (click)="imageFileUpload.value = ''"
                                        />
                                    </label>
                                    <label class="upload-menu-tile">
                                        <img src="assets/images/svg/gallery.svg" alt="Gallery" />
                                        <h4>Videos</h4>
                                        <input
                                            multiple
                                            #videoUpload
                                            type="file"
                                            accept="video/mp4,video/x-m4v,video/*"
                                            (change)="uploadFiles($event, 'video')"
                                            (click)="videoUpload.value = ''"
                                        />
                                    </label>
                                    <label class="upload-menu-tile mx-0">
                                        <img src="assets/images/svg/document-chatroom.svg" alt="Document" />
                                        <h4>Documents</h4>
                                        <input
                                            multiple
                                            #pdfUpload
                                            type="file"
                                            accept="application/pdf, application/vnd.ms-excel"
                                            (change)="uploadFiles($event, 'pdf')"
                                            (click)="pdfUpload.value = ''"
                                        />
                                    </label>
                                    <label class="upload-menu-tile">
                                        <div
                                            style="background: #ff9500; border-radius: 50%; height: 48px; width: 48px"
                                            class="audio-headphone-background d-flex align-items-center justify-content-center"
                                        >
                                            <img
                                                src="../../../../../assets/images/svg/headphone.svg"
                                                style="height: 30px; width: 30px"
                                                alt="Audio"
                                            />
                                        </div>
                                        <h4>Audio</h4>
                                        <input
                                            multiple
                                            #audioUpload
                                            type="file"
                                            accept="audio/mpeg,audio/wav,audio/flac"
                                            (click)="audioUpload.value = ''"
                                            (change)="uploadFiles($event, 'audio')"
                                        />
                                    </label>
                                </mat-menu>
                            </span>
                            <!-- / Attachment Btn -->
                        </div>
                        <div class="mt-2">
                            <span *ngIf="chatRoomForm.value.conversationArea?.length < 10; else counts"
                                >Min {{ 10 - chatRoomForm.value.conversationArea?.length }} characters</span
                            >
                            <ng-template #counts>
                                <span>{{ chatRoomForm.value.conversationArea?.length }} characters</span>
                            </ng-template>
                            <circle-progress
                                class="circle"
                                [percent]="(chatRoomForm.value.conversationArea?.length || 0) * 10"
                                [radius]="4"
                                [outerStrokeWidth]="2"
                                [innerStrokeWidth]="2"
                                [space]="-2"
                                [innerStrokeColor]="getStrokeColour()"
                                [outerStrokeColor]="getStrokeColour()"
                                [titleFontSize]="2"
                                [unitsFontSize]="2"
                                [showTitle]="false"
                                [showUnits]="false"
                                [showSubtitle]="false"
                                [animation]="true"
                                [animationDuration]="300"
                                [startFromZero]="false"
                                [responsive]="true"
                            >
                            </circle-progress>
                        </div>
                    </div>
                </div>
                <div
                    class="chatroomform d-flex flex-wrap selectedMedia"
                    *ngIf="fileToBeUpload.length > 0 && !((chatroomService.showOverlayAudioUpload$$ | async) && audioCount)"
                >
                    <ng-container *ngFor="let item of fileToBeUpload; let i = index">
                        <div class="imgBox chatroomform__attach">
                            <ng-container *ngIf="item.type == 'image'">
                                <img [src]="item.blob" alt="image" width="60" height="60" />
                                <img
                                    src="../../../../../assets/images/svg/remove.svg"
                                    alt="remove image"
                                    class="removeIcon cursor-pointer"
                                    width="24"
                                    height="24"
                                    (click)="removeImg(i)"
                                />
                            </ng-container>
                            <ng-container *ngIf="item.type == 'video'">
                                <video [src]="item.blob" height="60" width="60"></video>
                                <img
                                    src="../../../../../assets/images/svg/remove.svg"
                                    alt="remove image"
                                    class="removeIcon cursor-pointer"
                                    width="24"
                                    height="24"
                                    (click)="removeImg(i)"
                                />
                            </ng-container>
                            <ng-container *ngIf="item.type == 'pdf'">
                                <img src="../../../../../assets/images/svg/pdf-document.svg" alt="pdf" width="35" />
                                <img
                                    src="../../../../../assets/images/svg/remove.svg"
                                    alt="remove image"
                                    class="removeIcon cursor-pointer"
                                    width="24"
                                    height="24"
                                    (click)="removeImg(i)"
                                />
                                <span>{{ item.fileName }}</span>
                            </ng-container>
                            <ng-container *ngIf="item.type == 'audio'">
                                <div class="chatroomform__audio d-flex flex-column justify-content-center align-items-center text-white">
                                    <img
                                        class="chatroomform__audio-headphone"
                                        src="../../../../../assets/images/svg/headphone.svg"
                                        alt="audio"
                                    />
                                    <img
                                        src="../../../../../assets/images/svg/remove.svg"
                                        alt="remove image"
                                        class="removeIcon cursor-pointer"
                                        style="top: -6px; right: -6px; height: 1.5rem; width: 1.5rem"
                                        (click)="removeImg(i)"
                                    />
                                    <span style="color: #fff" class="text-center">{{ timeFormatter(item?.meta?.duration) }}</span>
                                </div>
                            </ng-container>
                        </div>
                        <label>
                            <div
                                style="background: #d0d8e2"
                                class="imgBox chatroomform__add-audio cursor-pointer"
                                *ngIf="item.type == 'audio' && i === fileToBeUpload?.length - 1 && i < 9"
                            >
                                <div
                                    style="background: #d0d8e2; height: 60px; width: 60px"
                                    class="imgBox d-flex flex-column justify-content-center align-items-center text-white"
                                >
                                    <img src="../../../../../assets/images/svg/add-audio-gray.svg" alt="" />
                                </div>
                                <input
                                    multiple
                                    #audioUpload
                                    type="file"
                                    accept="audio/mpeg,audio/wav,audio/flac"
                                    class="d-none"
                                    (change)="uploadFiles($event, 'audio')"
                                    (click)="audioUpload.value = ''"
                                />
                            </div>
                        </label>
                    </ng-container>
                </div>
                <div *ngIf="submitted && formDataValue.conversationArea.errors" class="invalid-feedback text-start">
                    <div *ngIf="formDataValue.conversationArea.errors.required">Please enter some conversation required</div>
                </div>
            </div>
            <div class="mb-5">
                <div
                    class="text-area d-flex justify-content-between"
                    [ngClass]="{ 'is-invalid': submitted && formDataValue.chatroomName.errors }"
                >
                    <input
                        type="text"
                        class="form-control"
                        formControlName="chatroomName"
                        placeholder="Name of the chat room"
                        maxlength="30"
                    />
                    <span class="text-muted" *ngIf="chatRoomForm.value.chatroomName?.length < 10; else chatRoomNameCounts">{{
                        30 - chatRoomForm.value.chatroomName?.length
                    }}</span>
                    <ng-template #chatRoomNameCounts>
                        <span class="text-muted">{{ chatRoomForm.value.chatroomName?.length }}</span>
                    </ng-template>
                </div>
                <div *ngIf="submitted && formDataValue.chatroomName.errors" class="invalid-feedback text-start">
                    <div *ngIf="formDataValue.chatroomName.errors.required">First Name is required</div>
                </div>
            </div>
            <div class="mb-3 d-flex justify-content-center">
                <button type="submit" class="btn btn-lm-round" [disabled]="!chatRoomForm.valid">
                    <img src="assets/images/svg/completed.svg" alt="back arrow" *ngIf="!isSecretCR" />
                    <img src="assets/images/svg/right_arror_btn.svg" alt="back arrow" *ngIf="isSecretCR" />
                </button>
            </div>
        </form>
    </main>
</section>

<!-- Secret chatroom participants list -->
<ng-container *ngIf="isSecretCR">
    <div class="memberList">
        <!-- participant header -->
        <div class="memberList__header" *ngIf="!searchHeader; else showSearchHeader">
            <div class="d-flex justify-content-between">
                <div class="d-flex">
                    <div class="arrowBox mt-2" (click)="back()">
                        <img
                            [src]="
                                !branding?.advanced?.header_colour
                                    ? '../../../../../assets/images/svg/secret_search_back_icon.svg'
                                    : '../../../../../assets/images/svg/secret_right_arrow.svg'
                            "
                            alt="icon"
                            class="arrow"
                        />
                    </div>
                    <div>
                        <div class="title">Add Participants</div>
                        <div class="subTitle">{{ selectedMemberList.length || 0 }} Selected</div>
                    </div>
                </div>
                <!-- <div (click)="searchBtn()">
                    <img src="../../../../../assets/images/svg/secret_search_icon.svg" alt="icon" class="searchArea" />
                </div> -->
            </div>
        </div>
        <!-- participant header -->

        <ng-template #showSearchHeader>
            <div class="memberList__header search">
                <div class="d-flex justify-content-between">
                    <div class="d-flex">
                        <div class="arrowBox mt-2" (click)="searchBtn()">
                            <img
                                [src]="
                                    !branding?.advanced?.header_colour
                                        ? '../../../../../assets/images/svg/secret_search_back_icon.svg'
                                        : '../../../../../assets/images/svg/secret_right_arrow.svg'
                                "
                                alt="icon"
                                class="arrow"
                            />
                        </div>
                        <div>
                            <input type="text" placeholder="search..." />
                        </div>
                    </div>
                    <div>
                        <img
                            src="../../../../../assets/images/svg/secret_search_icon.svg"
                            alt="icon"
                            height="18px"
                            width="18px"
                            class="mt-2"
                        />
                    </div>
                </div>
            </div>
        </ng-template>
        <!-- participant search header -->

        <!-- Participant list -->
        <div
            class="memberList__body"
            infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            (scrolled)="onScroll()"
            [scrollWindow]="false"
            *ngIf="memberList"
        >
            <!-- <div class="memberList__body--totleMembers">{{ memberList?.total_members }} members</div> -->
            <div class="memberList__body--totleMembers">{{ memberListObj.length - 1 }} members</div>

            <ng-container *ngFor="let member of memberListObj">
                <div class="memberList__body__box" (click)="selectMember(member.id)" *ngIf="user.id != member.id">
                    <div class="memberList__body__box--userImg">
                        <img [src]="member.image_url" alt="user" onerror="this.src='../../../../../assets/images/png/avatar.png'" />
                        <div [ngClass]="selectedClass(member.id)"></div>
                    </div>
                    <div class="memberList__body__box__content">
                        <div class="memberList__body__box__content--userName">{{ member.name }}</div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="memberList__footer" *ngIf="selectedMemberList.length > 0">
            <button type="button" (click)="onCreateSecretChatroom()">
                <img src="assets/images/svg/right_arror_btn.svg" alt="back arrow" />
            </button>
        </div>
    </div>
</ng-container>
