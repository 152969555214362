<div class="seperator text-center mt-2 mb-5"> 
    <div class="lg-seperator mx-auto"></div>
</div>
<div class="eventContent">
    <img src="../../../../../../assets/images/svg/cross.svg" alt="Cancel" width="20" height="20" class="cancelBtn" (click)=close()>
    <div class="eventContent__header">
        <h2>Event Privacy</h2>
        <p class="eventContent--subHeading">An event can either be a private or a public event.</p>
    </div>
    <div class="d-flex mb-35">
        <img src="../../../../../../assets/images/svg/padlock.svg" alt="Private Event">
        <div>
            <h2>Private Event</h2>
            <p class="fs-15">Only verified community mambers can see all the details. A non-member trying to access the event information would have to join the community first.</p>
        </div>
    </div>
    <div class="d-flex">
        <img src="../../../../../../assets/images/svg/globe.svg" alt="Public Event">
        <div>
            <h2>Public Event</h2>
            <p class="fs-15">Anyone with the link can see this event. Attending Members’ details would be available only to the users who join the community.</p>
        </div>
    </div>
     
</div>