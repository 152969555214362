import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { IUser } from '../../models/user.model';
import { ProfileService } from 'src/app/core/services/profile.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Question } from '../../models/question.model';
import { HintModalComponent } from '../hint-modal/hint-modal.component';
import { CommunityService } from 'src/app/core/services/community.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-ch-edit-about',
    templateUrl: './ch-edit-about.component.html',
    styleUrls: ['./ch-edit-about.component.scss'],
})
export class ChEditAboutComponent implements OnInit {
    private destroy$$ = new Subject();
    @Input() user: IUser;
    formData: any[] = [];
    aboutDataObj: any;
    introduction: any;

    branding: any;

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            aboutObj: any;
        },
        private profileService: ProfileService,
        private authService: AuthService,
        private dialog: MatDialog,
        private communityService: CommunityService,
        private snackbar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.getIntroductionSamples();
        this.profileQuestions();
        this.communityService.currentCommunityBranding$$.pipe(takeUntil(this.destroy$$)).subscribe((branding) => {
            this.branding = branding;
        });
    }

    calculatePercentage(aboutText: string | null): number {
        if (!aboutText) {
            return 0;
        }
        return Math.min((aboutText.length / 50) * 100, 100);
    }

    getStrokeColour() {
        return this.branding?.advanced?.buttons_icons_colour || this.branding?.basic?.primary_colour || '#00CAB3';
    }

    openHint() {
        this.dialogRef = this.dialog.open(HintModalComponent, {
            data: {
                introduction: this.introduction,
            },
            panelClass: 'hint-modal',
            disableClose: true,
        });
        this.dialogRef.afterClosed().subscribe();
    }

    onSubmit() {
        const item = this.formData.find((item) => item.question.question_title === 'About You');
        const answer = item?.question_answer?.answer;

        if (answer === undefined || (answer.length < 50 && answer.length !== 0)) {
            this.snackbar.open(`'About You' has less than 50 characters!`, 'Close', {
                duration: 5000,
                panelClass: ['error-alert-snackbar'],
                horizontalPosition: 'right',
            });
            return;
        }

        const questionAnswers = this.formData.map((item) => {
            return {
                question_id: item.question.id.toString(),
                answer: item.question_answer.answer,
            };
        });

        const params = {
            question_answers: questionAnswers,
        };

        this.profileService.editMemberProfileCh(params).subscribe((resData) => {
            if (resData.success) this.confirm();
        });
    }

    close() {
        this.dialogRef.close();
    }

    confirm() {
        this.dialogRef.close('updated');
    }

    /**
     * @function getIntroductionSamples
     * @description This function is used to get getIntroductionSamples
     */
    getIntroductionSamples(): void {
        this.authService.introductionSamples().subscribe((resData) => {
            this.introduction = resData.data;
        });
    }

    /**
     * @function profileQuestions
     * @description This function is used to get profileQuestions
     */
    profileQuestions(): void {
        this.authService.getProfileQuestions().subscribe((resData) => {
            let basicObj;
            basicObj = this.filterQuestionsStepOne(resData?.data?.questions, 'about');
            this.aboutDataObj = basicObj.sort((a, b) => a.rank - b.rank);

            const QuestionObj = this.aboutDataObj.map((item: any) => ({ question: item, question_answer: {} }));

            if (this.data.aboutObj.length > 0) {
                const updatedDataObj = QuestionObj.map((item) => {
                    const matchingSecondObject = this.data.aboutObj.find((secondItem) => secondItem.question.id === item.question.id);

                    if (matchingSecondObject) {
                        return {
                            question: item.question,
                            question_answer: matchingSecondObject.question_answer,
                        };
                    } else {
                        return item;
                    }
                });

                this.formData = Object.values(updatedDataObj);
            } else {
                this.formData = Object.values(QuestionObj);
            }

            this.formData.forEach((item) => {
                if (item.question.value) {
                    item.question.value = JSON.parse(item.question.value);
                }
            });
        });
    }

    /**
     * @function filterQuestionsStepOne
     * @description This function is used to filter the questions
     */
    filterQuestionsStepOne(questions: Question[], type?: string): Question[] {
        if (type === 'about') {
            return questions.filter((question) => {
                return question.optional && question.tag === 'about';
            });
        } else {
            return questions.filter((question) => {
                return !question.optional && question.tag === 'basic' && question.state !== 7;
            });
        }
    }
}
