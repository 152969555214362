<section class="image-cropper-wrapper">
    <div class="cross-div" (click)="close()">
        <span class="close-icon"><img src="../../../../assets/images/svg/cross.svg" /></span>
    </div>

    <div class="header">Drag the image to adjust</div>

    <image-cropper
        class="image-cropper"
        [imageChangedEvent]="data?.event"
        [maintainAspectRatio]="true"
        (imageCropped)="imageCropped($event)"
        format="png"
        roundCropper="true"
    ></image-cropper>

    <div class="save-button" (click)="upload()">
        <button>SAVE</button>
    </div>
</section>
