<div class="login-tutorial d-flex">
    <div id="carouselExampleIndicators" class="carousel slide" *ngIf="screens">
        <div class="carousel-indicators">
            <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
            ></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active" *ngFor="let screen of screens; let i = index" [ngClass]="{ active: i === 0 }">
                <div class="slide">
                    <div class="imageBox">
                        <img [src]="'../../../../assets/images/svg/ch-img/onbarding_' + i + '.png'" alt="LikeMinds Intro Banner" />
                        <!-- <img [src]="screen.image" alt="LikeMinds Intro Banner" /> -->
                    </div>
                    <h1 class="title">{{ screen.heading }}</h1>
                    <h3>{{ screen.text }}</h3>
                </div>
            </div>
        </div>
    </div>
</div>
