import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommunityCardComponent } from 'src/app/shared/components/community-card/community-card.component';
import { IUser } from 'src/app/shared/models/user.model';

@Component({
    selector: 'your-response-pop-up',
    templateUrl: './your-response.component.html',
})
export class YourResponseComponent {
    constructor(
        public dialogRef: MatDialogRef<CommunityCardComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    close(event) {
        this.dialogRef.close(event);
    }
}

// plan_H4kFGOsZoMsmWt
