// Kettle APIs

export const GENERATE_OTP_API = '/user/otp';
export const VERIFY_OTP_API = '/user/otp/verify';
export const LOGOUT_API = '/user/logout';
export const MEMBER_STATE_API = '/community/member/state';
export const FETCH_FILTERS = '/community/question/filters';

// Carvan APIs

// export const GENERATE_OTP_API = '/generate_otp';
// export const VERIFY_OTP_API = '/verify_otp';
// export const LOGIN_API = '/user/login';
// export const MEMBER_STATE_API = '/members_state';
export const CONFIG_API = '/config';
export const LIMIT_ACCESS = '/limit_access';
export const FETCH_APP_ACCESS = '/user/fetch_app_access';
export const FETCH_ALL = '/user/fetch_all';
export const MERGE_ACCOUNT_API = '/merge_account';
export const QUESTIONS_API = '/questions';
export const INVITE_MULTIPLE_MEMBER = '/community/invite';
export const JOIN_COMMUNITY_API = '/v1/join_community';
export const SKIP_COMMUNITY_API = '/skip_community';
export const ADMIN_MEMBER_LIST_API = '/admins';
export const ALL_MEMBER_LIST_API = '/v1/all_members';
export const COMMUNITY_API = '/community';
export const GET_COMMUNITY_ID = '/community/get_community_id';
export const INTRO_EXAMPLES_API = '/fetch_intro_examples';
export const COMMUNITY_CHAT_ROOM_FEED_API = '/fetch_community_chatroom_feed';
export const FETCH_CHATROOM_API = '/chatroom/fetch';
export const FETCH_CONVERSATION_API = '/conversation/fetch';
export const FETCH_UNREAD_PREVIEWS_API = '/conversation/fetch_unread_previews'; // to fetch intro-room thread conversations
export const FETCH_UNREAD_PREVIEWS_COUNT_API = '/conversation/fetch_preview_unread_messages_count'; // to fetch intro-room thread conversations count
export const FETCH_POLL_USER = '/conversation/poll_users'; //
export const CREATE_CONVERSATION_API = '/conversation/create';
export const FETCH_MICROPOLL_USERS = '/conversation/poll_users'; //
export const ADD_REACTION = '/conversation/add_reaction';
export const REMOVE_REACTION = '/conversation/remove_reaction';
export const DELETE_CONVERSATION_API = '/delete_conversation';
export const EDIT_CONVERSATION_API = '/edit_conversation';
export const FETCH_SHARE_URL = '/chatroom/share';
export const FETCH_COMMUNITY_URL = FETCH_SHARE_URL;
export const FETCH_SUBSCRIPTION_API = '/subscription/fetch';
export const FETCH_SUBSCRIPTION_META = '/subscription/fetch_subscription_meta';
export const CONVERSATION_META = '/conversation_meta';
export const FETCH_COMMUNITY_PLANS = '/subscription/fetch_plan';
export const CREATE_PLAN_ORDER = '/subscription/create_order';
export const CREATE_COMMUNITY_EVENT_ORDER = '/subscription/create_community_event_order';
export const VERIFY_ORDER = '/subscription/verify_order';
export const FETCH_COMMUNITY = '/community/fetch';
export const FETCH_COMMUNITY_META = '/subscription/fetch_community_meta';
export const FETCH_COMMUNITY_RENEWAL = '/subscription/fetch_community_renewals';
export const CREATE_SUBSCRIPTION = '/subscription/create_subscription';
export const CREATE_EVENT_SUBSCRIPTION = '/subscription/create_event_order';
export const CANCEL_MEMBERSHIP = '/subscription/cancel';
export const SUBSCRIPTION_FETCH = '/subscription/fetch';
export const RENEW_COMMUNITY_MEMBER = '/community_membership/renew_member';
export const FETCH_SUBSCRIPTION_HISTORY = '/subscription/fetch_history';
export const FETCH_FEED_URL = '/community/fetch_feed_url';
export const COMMUNITY_FETCH_COMMUNITY_META = '/community/fetch_community_meta';
export const UPDATE_ACCESS = '/chatroom/update_access';
export const FETCH_CHATROOM_ACCESS = '/chatroom/fetch_access';
export const FETCH_OTL_URL = '/community/fetch_otl_url';
export const CREATE_FREE_TRANSACTION = '/subscription/create_free_transaction';
//export const CHATROOM_UPLOAD_FILES = '/upload_files';

export const CHATROOM_UPLOAD_FILES = '/v1/upload_files';
export const LEAVE_COMMUNITY_API = '/remove_from_member';
export const MARK_READ = '/chatroom/mark_read';
export const TAGGING_LIST_API = '/chatroom/get_tagging_list';
export const FOLLOW_CHATROOM_API = '/collabcard_follow';
export const ATTEND_EVENT_API = '/collabcard_attend';
export const DEFAULT_PROFILE_PHOTO_LINK =
    'https://firebasestorage.googleapis.com/v0/b/collabmates-beta.appspot.com/o/files%2Fuser%2F222%2Fimg_user_222?alt=media';
export const LINKEDIN_LOG_TEST = '/v1/login';
export const SUBMIT_POLL = '/submit_poll';
export const SUBMIT_MICRO_POLL = '/conversation/submit_poll';
export const ADD_POLL = '/add_poll';
export const ADD_MICRO_POLL = '/conversation/add_poll';
export const FETCH_COMMUNITY_BENEFITS = '/community_membership/fetch_community_benefits';
export const FETCH_POLL_USERS = '/fetch_poll_users';
export const FETCH_COMMUNITY_PROFILE = '/fetch_community_profile';
export const FETCH_COMMON_COMMUNITIES = '/fetch_common_communities';
export const FETCH_USER_CHATROOMS = '/fetch_user_chatrooms';
export const FETCH_REPORT_TAGS = '/fetch_report_tags';
export const MEMBERS_FETCH_API = '/community_member/fetch_members_detail';
export const EDIT_MEMBER_PROFILE = '/edit_member_profile';
export const CHATROOM_CREATE = '/chatroom/create';
export const GET_HOME_FEED_CHATROOMS = '/v1/my_chatrooms';
export const GET_DM_FEED_CHATROOMS = '/community_member/fetch_dm_chatrooms';
export const CAN_DM = '/community_member/can_dm';
export const GET_ALL_MEMBERS_DM = '/v1/all_members';
export const REQUEST_DM_LIMIT = '/community_member/request_dm_limit';
export const CREATE_DM = '/chatroom/create_dm';
export const REQUEST_DM = '/chatroom/request_dm';
export const PUSH_REPORT = '/push_report';
export const BLOCK = '/chatroom/block';
export const GET_HOME_FEED_COMMUNITIES = '/community_member/home_communities';
export const GET_COMMUNITY_FEED_CHATROOMS = '/community_member/fetch_feed';
export const CREATE_POLL = '/create_poll';
export const UPLOAD_FILES = '/v1/upload_files';
export const CHATROOM_RENAME = '/chatroom_rename';
export const SET_CHATROOM_ACTIVE = '/set_chatroom_active';
export const COLLABCARD_SEEN = '/collabcard_seen';
export const COMMUNITY_META = '/community_member/fetch_feed_meta';
export const CHATROOM_MUTE = '/chatroom_mute';
export const HOME_FEED_META = '/community_member/fetch_chatroom_home';
export const CHATROOM_PIN = '/chatroom/pin';
export const PUSH = '/push';
export const FETCH_TRANSACTIONS_API = '/subscription/fetch_transactions';
export const REFUND_TRANSACTIONS_API = '/subscription/refund_transaction';
export const DOWNLOAD_MEMBERS_API = '/subscription/members_report';
export const FETCH_PLAN_API = '/subscription/fetch_plan';
export const EVENT_PARENT = '/chatroom/event';
export const EVENT_FETCH_ALL = '/fetch_all';
export const CREATE_EVENT_PLAN = '/subscription/create_event_plan';
export const UPDATE_EVENT_PLAN = '/subscription/update_event_plan';
export const FETCH_EVENT_PLAN = '/subscription/fetch_event_plan';
export const FETCH_EVENT_PLAN_WITH_COHORT = '/subscription/fetch_event_plan_with_cohort';
export const EVENT_ALL_MEMBERS = '/all_members';
export const EVENT_FETCH_ONLINE_LINK = '/chatroom/event/fetch_event_link_for_dashboard';
export const UPDATE_INSTRUCTORS = '/chatroom/event/add_or_update_instructor';
export const UPDATE_HIGHLIGHTS = '/chatroom/event/add_or_update_highlights';
export const UPDATE_TESTIMONIALS = '/chatroom/event/add_or_update_member_testimonials';
export const UPDATE_FAQ = '/chatroom/event/add_or_update_event_faq';
export const FETCH_COMMUNITY_MANGER_RIGHTS = '/fetch_community_manager_rights';
export const UPDATE_COMMUNITY_MANAGER_RIGHTS = '/update_community_manager_rights';
export const FETCH_MEMBER_RIGHTS = '/fetch_member_rights';
export const UPDATE_MEMBER_RIGHTS = '/update_member_rights';
export const JOIN_REQUEST = '/join';
export const REMOVE_COMMUNITY_MANAGER = '/remove_community_manager';
export const TRANSFER_OWNERSHIP = '/transfer_ownership';
export const SUBSCRIPTION_UPDATE_PAYMENT_ID = '/subscription/update_payment_id';
export const CHATROOM_EVENT_ATTEND = '/chatroom/event/attend';
export const CHATROOM_EVENT_ATTENDED = '/chatroom/event/attended';
export const CHATROOM_EVENT_FETCH_LINK = '/chatroom/event/fetch_link';
export const SUBSCRIPTION_CREATE_EVENT_ORDER = '/subscription/create_event_order';
export const SUBSCRIPTION_EVENT_PAYMENT_ID = '/subscription/valid_event_payment_id';
export const V2_FETCH_CHATROOM_DETAIL = '/v2/fetch_chatroom';
export const FETCH_CHATROOM_DETAIL = '/chatroom/fetch';
export const CHATROOM_EVENT_FETCH_ALL = '/chatroom/event/fetch_all';
export const FETCH_UNSEEN_COUNT = '/chatroom/event/fetch_unseen_count';
export const UPDATE_LAST_SEEN = '/chatroom/event/update_last_seen';
export const UPDATE_EVENT = '/chatroom/event/update';
export const CREATE_EVENT_API = '/chatroom/event/create';
export const UPLOAD_RECORDINGS = '/chatroom/event/upload_recordings';
export const DELETE_RECORDING = '/chatroom/event/delete_recordings';
export const UPLOAD_RECORDINGS_META = '/chatroom/event/upload_recordings_meta';
export const DELETE_RECORDINGS_META = '/chatroom/event/delete_recordings_meta';
export const JOIN_EMAIL_ADD = '/community/join_email/add';
export const JOIN_EMAIL_FETCH = '/community/join_email/fetch';
export const FETCH_MEMBERS_META = '/community/fetch_members_meta';

export const COHORT_FETCH_COMMUNITY_COHORTS = '/cohort/fetch_community_cohorts';
export const COHORT_FETCH = '/cohort/fetch';
export const COHORT_UPDATE = '/cohort/update';
export const COHORT_CREATE = '/cohort/create';
export const COHORT_REMOVE_MEMBER = '/cohort/remove_member';
export const CHATROOM_FETCH_SETTINGS = '/chatroom/fetch_settings';
export const CHATROOM_DELETE = '/chatroom_delete';
export const CHATROOM_ENABLE_MEMBER_MESSAGE = '/chatroom/enable_member_message';
export const MEMBERS_STATE = '/members_state';
export const CHATROOM_SECRET_ADD = '/chatroom/secret/add';
export const CHATROOM_SECRET_LEAVE = '/chatroom/secret/leave';

//Link fetching
export const FETCH_PREVIEW = '/fetch_preview';
export const DECODE_URL = '/decode_url';
export const HOME_DRAWER_BOTTOM_MENU = '/home/bottom_menu';
export const EDIT_USER = '/edit_user';

// export const SEARCH_MEMBER_DIRECTORY = '/search/member_directory';
export const SEARCH_MEMBER_DIRECTORY = '/community/member/search';
export const SUBSCRIPTION_SEARCH = '/subscription/search';
export const SUBSCRIPTION_SEARCH_HISTORY = '/subscription/search_history';
export const USER = '/user';

// Event api
export const EVENT_FETCH_ALL_META = '/chatroom/event/fetch_all_meta';

// DM API

export const SHOW_DM_BUTTON = '/community_member/show_dm';
export const FETCH_DM_HOME = '/user/fetch_dm_home';
export const UPDATE_DM_TUTORIAL = '/user/update_dm_tutorial';
export const FETCH_DM_FEED = '/user/fetch_dm_feed';
//Branding API
export const BRANDING = '/branding';

// Search APIs
export const SEARCH_CHATROOM = '/search/chatroom';
export const SEARCH_CONVERSATION = '/search/conversation';
export const UNSUBSCRIBE_EMAIL_NOTIFICATIONS = '/community_member/unsubscribe_email_notifications';

// Onboarding APIs
export const SDK_ONBOARDING = '/sdk/onboarding';
export const SDK_INITIATE = '/sdk/initiate';
export const LOGIN_API = '/sdk/initiate';
export const USER_SOCIAL_LOGIN = '/user/social/login';
export const REFRESH_TOKEN_API = '/user/refresh';
export const USER_TOKEN = '/user/token';
export const USER_META = '/user/meta';
export const COMMUNITY_QUESTIONS = '/community/questions';
export const COMMUNITY_MEMBER_PROFILE = '/community/member/profile';
export const COMMUNITY_INTRO_EXAMPLE = '/community/intro_examples';
export const USER_CONFIG = '/user/config';
export const COMMUNITY_MEMBER_JOIN = '/community/member/join';
