<ng-container>
    <label class="fs14 c6 float-start ms-2"
        >{{ question?.question_title }}<span *ngIf="!question?.optional" class="text-danger">*</span></label
    >
    <mat-form-field class="simple-input email w-100 mb-4" [ngClass]="{ invalid: control.errors && formSubmitted }" floatLabel="never">
        <input
            matInput
            [placeholder]="question?.help_text"
            [formControl]="control"
            name="email-id"
            type="email"
            maxlength="100"
            autocomplete="off"
            [value]="question?.value"
            [required]="!question?.optional"
        />
    </mat-form-field>
    <div
        class="invalid-feedback question-form-error ms-2 d-block text-start"
        *ngIf="control.errors && control.errors.required && formSubmitted"
    >
        This is a mandatory field
    </div>
    <div
        class="invalid-feedback question-form-error ms-2 d-block text-start"
        *ngIf="control.errors && control.errors.pattern && formSubmitted"
    >
        Invalid email address
    </div>
</ng-container>
