<div class="chatroom-image-group">
    <div class="left-hemisphere" [ngClass]="{'half-image': data.conversations.length}">
      <img
        [src]="data.creator.image_url"
        class="image-single"
        [alt]="data.creator.name"
        (error)="setDefaultPic($event)"
      >
    </div>
    <ng-container *ngIf="data.conversations.length">
      <div class="right-hemisphere" [ngClass]="data.conversations.length < 2 ? 'single-half' : 'double-half'">
        <img
          [src]="data.conversations[0].image_url"
          class="image-single"
          (error)="setDefaultPic($event)"
          [alt]="data.conversations[0].name" />
        <img
          *ngIf="data.conversations.length > 1"
          [src]="data.conversations[1].image_url"
          class="image-single"
          (error)="setDefaultPic($event)"
          [alt]="data.conversations[1].name" />
      </div>
    </ng-container>
</div>
