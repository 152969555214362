export const SAVE_COMMUNITY_ID = '[Auth] Save Community ID';
export const SAVE_URL_PARAMS = '[Auth] Save Url Params';
export const SET_LANDING_TYPE = '[Auth] Set Landing Type';
export const SET_LINK_TYPE = '[Auth] Set Link Type';
export const SET_USER = '[Auth] Set User';

export const GENERATE_OTP = '[Auth] Generate Otp';
export const GENERATE_OTP_SUCCESS = '[Auth] Generate Otp Success';
export const GENERATE_OTP_FAILURE = '[Auth] Generate Otp Failure';

export const VERIFY_OTP = '[Auth] Verify Otp';
export const VERIFY_OTP_SUCCESS = '[Auth] Verify Otp Success';
export const VERIFY_OTP_FAILURE = '[Auth] Verify Otp Failure';

export const RESEND_OTP = '[Auth] Resend Otp';
export const RESEND_OTP_SUCCESS = '[Auth] Resend Otp Success';
export const RESEND_OTP_FAILURE = '[Auth] Resend Otp Failure';

export const LOGIN = '[Auth] Login';
export const LOGIN_SUCCESS = '[Auth] Login Success';
export const LOGIN_FAILURE = '[Auth] Login Failure';

export const MERGE_ACCOUNT = '[Auth] Merge Account';
export const MERGE_ACCOUNT_SUCCESS = '[Auth] Merge Account Success';
export const MERGE_ACCOUNT_FAILURE = '[Auth] Merge Account Failure';

export const GENERATE_OTP_FOR_MERGE_ACCOUNT = '[Auth] Generate Otp for Merge Account';
export const GENERATE_OTP_FOR_MERGE_ACCOUNT_SUCCESS = '[Auth] Generate Otp for Merge Account Success';
export const GENERATE_OTP_FOR_MERGE_ACCOUNT_FAILURE = '[Auth] Generate Otp for Merge Account Failure';