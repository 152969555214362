import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CustomPaymentComponent } from './pages/custom-payment/custom-payment.component';
import { EmailUnsubscribedComponent } from './pages/email-unsubscribed/email-unsubscribed.component';

import {
    AUTH_PATH,
    PAGE_NOT_FOUND_PATH,
    ROOT_PATH,
    CM_DASHBOARD_PATH,
    PAYMENT_PAGE,
    Email_Unsubscribe_Path,
} from './shared/constants/routes.constant';
import { AuthLoginGuard } from './shared/guards/auth-login.guard';

const appRoutes: Routes = [
    {
        path: AUTH_PATH,
        loadChildren: () => import('./layouts/auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: Email_Unsubscribe_Path,
        component: EmailUnsubscribedComponent,
    },
    {
        path: ROOT_PATH,
        loadChildren: () => import('./layouts/base-layout/base-layout.module').then((m) => m.BaseLayoutModule),
        // canActivate: [AuthLoginGuard],
    },
    {
        path: CM_DASHBOARD_PATH,
        loadChildren: () => import('./pages/cm-dashboard/cm-dashboard.module').then((m) => m.CmDashboardModule),
    },
    {
        path: PAYMENT_PAGE,
        component: CustomPaymentComponent,
    },
    {
        path: '**',
        redirectTo: PAGE_NOT_FOUND_PATH,
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            initialNavigation: 'enabled',
            scrollPositionRestoration: 'enabled',
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
