import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { IUser } from '../../models/user.model';
import { ProfileService } from 'src/app/core/services/profile.service';
import { Question } from '../../models/question.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { NewOptionDialogComponent } from '../new-option-dialog/new-option-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-ch-tool-stack',
    templateUrl: './ch-tool-stack.component.html',
    styleUrls: ['./ch-tool-stack.component.scss'],
})
export class ChToolStackComponent implements OnInit {
    @Input() user: IUser;
    aboutDataObj: any;
    filteredOptions: { value: string; selected: boolean }[] = [];
    searchTerm = ''; // Initialize searchTerm as an empty string
    toolStackOptions: any[] = [];
    filteredToolStackOptions: any[] = []; // Initialize as an empty array
    selectedOptionObj: any = [];
    selectedOptions: any[] = [];
    dropdownLimit: number = 0;

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            aboutObj: any;
        },
        private profileService: ProfileService,
        private authService: AuthService,
        private dialog: MatDialog,
        private snackbar: MatSnackBar
    ) {}

    ngOnInit(): void {
        console.log(this.data?.aboutObj);

        this.profileQuestions();
    }

    close() {
        this.dialogRef.close();
    }

    confirm() {
        this.dialogRef.close('updated');
    }

    updateSelectedOptions(event, option: any): void {
        if (this.selectedOptions.length === this.dropdownLimit && event.target.checked === true) {
            this.snackbar.open(`Selection exceeds the maximum limits: ${this.dropdownLimit}`, null, {
                duration: 5000,
                panelClass: ['snackbar'],
            });
            event.target.checked = false;
            return;
        }
        if (option.selected) {
            this.selectedOptions.push(option);
        } else {
            const index = this.selectedOptions.findIndex((optionInTheArray) => optionInTheArray.value === option.value);
            if (index !== -1) {
                this.selectedOptions.splice(index, 1);
            }
        }
    }

    onSubmit() {
        if (this.selectedOptions.length === 0 || this.selectedOptions.length > 4) {
            this.snackbar.open('Selection exceeds the maximum limits: 4', null, {
                duration: 5000,
                panelClass: ['snackbar'],
            });
            return;
        }
        const joinedValues = this.selectedOptions.map((option) => option.value).join('$#');
        const payload = {
            question_answers: [
                {
                    question_id: this.data?.aboutObj[0]?.question?.id.toString() || this.aboutDataObj[0]?.id?.toString(),
                    answer: joinedValues,
                },
            ],
        };
        this.profileService.editMemberProfileCh(payload).subscribe((resData) => {
            if (resData.success) this.confirm();
        });
    }

    shouldCheckit(option) {
        return this.selectedOptionObj.some((optionObject) => optionObject.value === option.value) > -1 ? true : false;
    }

    /**
     * @function profileQuestions
     * @description This function is used to get profileQuestions
     */
    profileQuestions(): void {
        this.authService.getProfileQuestions().subscribe((resData) => {
            this.aboutDataObj = this.filterQuestionsStepOne(resData?.data?.questions, 'tool_stack');

            this.dropdownLimit = this.aboutDataObj[0]?.state === 2 ? this.aboutDataObj[0]?.dropdown_selection_limit : 1;
            if (this.aboutDataObj) {
                this.toolStackOptions = JSON.parse(this.aboutDataObj[0]?.value);

                // Already Selected Options
                if (this.data.aboutObj.length > 0) {
                    this.selectedOptionObj = this.data.aboutObj[0]?.question_answer?.answer.split('$#');

                    this.toolStackOptions.forEach((item) => {
                        this.selectedOptionObj.includes(item.value);
                        if (this.selectedOptionObj.includes(item.value)) {
                            this.filteredToolStackOptions.push({ value: item.value, selected: true });
                            this.selectedOptions.push({ value: item.value, selected: true });
                        } else {
                            this.filteredToolStackOptions.push({ value: item.value, selected: false });
                        }
                    });
                } else {
                    this.filteredToolStackOptions = this.toolStackOptions;
                }
            }
        });
    }

    // Create a search function to filter options based on the searchTerm
    search(): void {
        const lowerCaseSearchTerm = this.searchTerm.toLowerCase().trim();
        this.filteredToolStackOptions = this.toolStackOptions.filter((option) => {
            return option.value.toLowerCase().includes(lowerCaseSearchTerm);
        });
    }

    /**
     * @function filterQuestionsStepOne
     * @description This function is used to filter the questions
     */
    filterQuestionsStepOne(questions: Question[], type?: string): Question[] {
        if (type === 'tool_stack') {
            return questions.filter((question) => {
                return question.tag === 'tool_stack';
            });
        }
    }

    /**
     * @function newOptionDialog
     * @param params
     * @description This function is used to newOptionDialog
     */
    newOptionDialog() {
        this.dialog
            .open(NewOptionDialogComponent, {
                disableClose: true,
            })
            .afterClosed()
            .subscribe((res) => {
                if (res) {
                    // Check for duplicates before pushing
                    if (!this.filteredToolStackOptions.some((obj) => obj.value.toLowerCase() === res.value.toLowerCase())) {
                        this.filteredToolStackOptions.push(res);
                    } else {
                        this.snackbar.open(`Option already exists.`, null, {
                            duration: 3000,
                            panelClass: ['snackbar'],
                        });
                        console.log('Duplicate value found. Not pushing into the array.');
                    }
                }
            });
    }
}
