<section class="download-app-section">
    <div class="w-100 close hide-sm">
        <img src="assets/images/svg/cross.svg" (click)="close()" class="float-end cursor-pointer" alt="Close">
    </div>
    <div class="content mx-4">
        <p class="heading fs16 fw500">{{ data?.heading }}</p>
        <p class="sub-heading fs14 c6" *ngIf="data?.subHeading1">{{ data?.subHeading1 }}</p>
        <p class="sub-heading fs14 c6" *ngIf="data?.subHeading2">{{ data?.subHeading2 }}</p>
        <p class="sub-heading fs14 c6" *ngIf="data?.subHeading3">{{ data?.subHeading3 }}</p>
    </div>
    <div class="mx-4 download-links hide-sm">
        <div class="appstore d-flex justify-content-end mt-5" (click)="downloadApp('appstore')">
            <img src="assets/images/svg/appstore-grey.svg" class="cursor-pointer" alt="AppStore">
            <p class="my-2 ms-2 fs13 fw500 cursor-pointer">DOWNLOAD iOS APP</p>
        </div>
        <div class="playstore d-flex justify-content-end mt-4" (click)="downloadApp('playstore')">
            <img src="assets/images/svg/playstore.svg" class="cursor-pointer" alt="PlayStore">
            <p class="my-2 ms-2 fs13 fw500 cursor-pointer">DOWNLOAD ANDROID APP</p>
        </div>
    </div>
    <span class="show-sm">
        <div class="action-buttons row mt-4 mb-2">
            <div class="col cursor-pointer c6 dismiss fs13" (click)="close()">DISMISS</div>
            <div class="col cursor-pointer download fs13" (click)="download()">DOWNLOAD APP</div>
        </div>
    </span>
</section>