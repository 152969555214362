<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="56" height="56"
    viewBox="0 0 56 56">
    <defs>
        <filter id="94vcxkraaa" width="129.2%" height="129.2%" x="-14.6%" y="-10.4%" filterUnits="objectBoundingBox">
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
        </filter>
        <circle id="qsd400n9rb" cx="24" cy="24" r="24" />
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g transform="translate(-1119 -565) translate(1123 567)">
                    <use fill="#000" filter="url(#94vcxkraaa)" xlink:href="#qsd400n9rb" />
                    <use class="fill-brand" xlink:href="#qsd400n9rb" />
                </g>
                <g fill-rule="nonzero">
                    <path fill="#FFF"
                        d="M3.108.173L21.873 7.96c.854.354 1.385 1.15 1.385 2.074 0 .925-.53 1.72-1.385 2.074L3.108 19.895c-.774.321-1.67.195-2.331-.377-.647-.56-.918-1.423-.706-2.252l1.183-4.642L10 10 1.221 7.314.071 2.802C-.141 1.973.13 1.11.777.55c.64-.554 1.532-.708 2.33-.377z"
                        transform="translate(-1119 -565) translate(1123 567) translate(15 14)" />
                    <path fill="#EFEFEF"
                        d="M10.177 9.3c.377 0 .682.305.682.681 0 .377-.305.682-.682.682l-2.388-.001L10 10l-2.286-.7h2.463z"
                        transform="translate(-1119 -565) translate(1123 567) translate(15 14)" />
                </g>
            </g>
        </g>
    </g>
</svg>