export const ROOT_PATH = '';
export const PAGE_NOT_FOUND_PATH = '404';
export const UNAUTHORISED_PATH = 'unauthorised';
export const GENERATE_OTP_PATH = 'generate_otp';
export const VERIFY_OTP_PATH = 'verify_otp';
export const LOGIN_PATH = 'login';
export const AUTH_PATH = 'auth';
export const LINKEDIN_LOGIN_PATH = 'linkedinurl';
export const TERMS_AND_CONDITIONS_PATH = 'terms';
export const PRIVACY_AND_POLICY_PATH = 'privacy';
export const COMMUNITY_PATH = 'community';
export const FEED = 'feed';
// export const
export const POST_PATH = 'post';
export const FEED_POST = 'post/:postId';
export const COMMUNITY_FEED_PATH = 'community_feed';
export const EVENT_FEED_PATH = 'event_feed';
export const DIRECT_MESSAGE_PATH = 'direct_messages';
export const DIRECT_MESSAGE_MEMBER_PATH = 'direct_messages_member';
export const COMMUNITY_DETAIL_PATH = 'community_detail';
export const MEMBER_DIRECTORY = 'member_directory';
export const JOIN_COMMUNITY_FEED_PATH = 'join_community';
export const COLLABCARD_PATH = 'collabcard';
export const COMMUNITY_QUESTION_PATH = 'community_questions';
export const CREATE_PROFILE_PATH = 'create_profile';
export const CHATROOM_PATH = 'collabcard';
export const CREATE_EVENT = 'create_event';
export const EVENT_DETAIL = 'event_detail';
export const EVENT_MARKETING_DETAIL = 'event_marketing_detail';
export const PINNED = 'pinned';
export const DM = 'dm';
export const HISTORY_PATH = 'history';
export const EVENT_PATH = 'event';
export const PROFILE = 'profile';
export const PROFILE_PATH = 'profile';
export const ACCOUNT = 'account';
export const SINGLE_EVENT = 'single_event';
export const RENEWAL_PATH = 'renewal';
export const BUY_MEMBERSHIP = 'buy_membership';
export const CM_DASHBOARD_PATH = 'dashboard';
export const PAYMENT_PAGE = 'payment_page';
export const ROUTE_PIXEL_MAP = {
    community_detail: 'CommunityDetailPageView',
};
export const BLOCKER = 'blocker';
export const COMMUNITY_PAY = 'community_pay';
export const SUCCESS = 'success';
export const FAILURE = 'failure';

export const CM_HOME_PATH = ':community_id';
export const CM_EVENTS_PATH = ':community_id/events';
export const CM_ADD_EVENT_PATH = ':community_id/events/add';
export const CM_SINGLE_EVENT_PATH = ':community_id/events/:chatroom_id';
export const CM_MEMBERS_PATH = ':community_id/members';
export const CM_MEMBER_GROUPS = ':community_id/member_groups';
export const CM_COHORT_MEMBER = ':community_id/member_groups/:member_id';
// export const CM_COHORT_MEMBER = ':community_id/member_groups/:member_id/:cohortName';
export const CM_NEW_GROUP = ':community_id/new_group';
export const CM_NEW_GROUP_MEMBER = ':community_id/new_group_member';
export const CM_EDIT_GROUP_MEMBER = ':community_id/edit_group_member';

export const DETAIL = 'detail';
export const MEMBER_DIRECTORY_PATH = 'member_directory';
export const GIVE_MANAGEMENT_RIGHTS = 'give_manager_rights';
export const EDIT_MANAGEMENT_RIGHTS = 'edit_manager_rights';
export const EDIT_MEMBER_RIGHTS = 'edit_member_rights';
export const EVENT_CHATROOM_PATH = 'event_pay';
export const EVENT_PAY_SUCCESS = 'event_pay_success';
export const EVENT_PAY_FAILURE = 'event_pay_failure';
export const CHATROOM_SETTINGS = 'chatroom_settings';
export const VIEW_PARTICIPANTS = 'view_participants';
export const ADD_PARTICIPANTS = 'add_participants';
//url routes constants
export const ROUTE_BROWSER_LINK = 'browser?link';
export const ROUTE_BROWSER_URL = 'browser?url';
export const ROUTE_HIGHLIGHT = 'highlight';
export const ROUTE_PAYMENT_DIALOG = 'payment_dialog';
export const ROUTE_MAIL_TO = 'mail?to';
export const Email_Unsubscribe_Path = 'email_unsubscribe';
