<div *ngIf="screenType !== 'mobile'" class="chatroom-overlay d-flex align-items-center">
    <div class="text-center">
        <div class="overlay-message d-flex">
            <img src="assets/images/svg/arrow-round.svg" alt="Arrow round">
            <div class="message ms-5 mt-4">
                <p class="cF mb-4">
                    Your subscription to "{{communityName}}" community has expired. Buy a membership plan of your choice to regain access community events, chat rooms and member profiles.
                </p>
            </div>
        </div>
    </div>
</div>
