import { Component, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { environment } from '../../../../../environments/environment';
import { APPSTORE, PLAYSTORE, IOS, ANDROID, COMMMUNITY_OPENED } from '../../../../shared/constants/app-constant';
import { JOIN_FLOW, MIXPANEL, RENEWAL_FLOW, INVITE_FLOW } from '../../../../shared/enums/mixpanel.enum';
import { AnalyticsService } from '../../../../core/services/analytics.service';
import { CommunityService } from '../../../../core/services/community.service';
import { ROOT_PATH } from '../../../../shared/constants/routes.constant';
import { SubscriptionService } from '../../../../core/services/subscription.service';
import { UtilsService } from '../../../../core/services/utils.service';
import { LocalStorageService } from '../../../../core/services/localstorage.service';
import { STORAGE_KEY } from '../../../../shared/enums/storage-keys.enum';
import { UpgradeYourPlanDialogComponent } from '../../../../shared/entryComponents/upgrade-you-plan-dialog/upgrade-your-plan-dialog/upgrade-your-plan-dialog.component';
import { AddMembershipPlanDialogComponent } from '../../../../shared/entryComponents/add-membership-plan-dialog/add-membership-plan-dialog.component';
import { InviteMembersViaEmailMobileComponent } from '../../../../shared/entryComponents/invite-members-via-email-mobile/invite-members-via-email-mobile.component';
import { InviteMembersViaWhatsappMobileComponent } from '../../../../shared/entryComponents/invite-members-via-whatsapp-mobile/invite-members-via-whatsapp-mobile.component';

@Component({
    selector: 'app-send-response',
    templateUrl: './send-response.component.html',
    styleUrls: ['./send-response.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SendResponseComponent {
    @Output() closeModal: EventEmitter<any> = new EventEmitter();
    @Output() closeSheet: EventEmitter<any> = new EventEmitter();
    @Input() user: any;
    @Input() isSheet = false;
    @Input() isModal = false;
    copiedLink: string = '';
    inivte_link_type_free = 'free';
    inivte_link_type_paid = 'paid';
    toggle1: boolean = false;
    toggle2: boolean = false;
    showInfo1: boolean = false;
    showInfo2: boolean = false;
    showFreeMemberToggleView: boolean = false;
    showPaidMemberToggleView: boolean = false;
    showUpgradeYourPlanModal: boolean = false;
    showAddMembershipPlanModal: boolean = false;
    showInviteViaMailView: boolean = false;
    showInviteViaWhatsAppView: boolean = false;
    choice = false;
    communityData: any;
    invitationType: any;
    invitationTypeSelected: boolean = false;
    communityName: string = '';
    private destroy$$ = new Subject();
    constructor(
        private analyticsService: AnalyticsService,
        private communityService: CommunityService,
        private router: Router,
        private subscriptionService: SubscriptionService,
        private snackBar: MatSnackBar,
        private utilsService: UtilsService,
        private localStorageService: LocalStorageService,
        private cookieService: CookieService,
        private dialog: MatDialog,
        private sheet: MatBottomSheet,
        private _bottomSheet: MatBottomSheet
    ) {}

    ngOnInit(): void {
        this.getMemberState();
        this.subscriptionService
            .fetchCommunity({ community_id: this.user?.communityId })
            .pipe(takeUntil(this.destroy$$))
            .subscribe((res) => {
                this.communityData = res?.community;
                this.communityName = this.communityData?.name;
            });
    }

    close() {
        if (this.isModal) this.closeModal.emit();
        if (this.isSheet) this.closeSheet.emit();
    }

    openCopiedToClipboard(message, action) {
        this.snackBar.open(message, action, {
            verticalPosition: 'bottom',
            horizontalPosition: 'left',
            panelClass: ['black-bottom-left-snackbar'],
            duration: 2000,
        });
    }

    getInviteLinkByMember(val) {
        var link;
        if (val === 0 && (this.memberData?.state == 4 || this.memberData?.state == 9)) {
            link = this.user?.url?.community_share?.private_link;
        } else {
            link = this.user?.url?.community_share?.public_link;
        }

        return encodeURIComponent(link);
    }

    socialLink: string;
    getInviteLinkTextByMember(val: any) {
        console.log(this.user);
        if (val === 0 && (this.memberData?.state === 4 || this.memberData?.state === 9)) {
            this.socialLink = this.user.url.community_share.private_link_text;
        } else {
            this.socialLink = this.user?.url?.community_share?.private_link || this.user?.url?.community_share?.public_link;
        }

        console.log(this.socialLink);
        return encodeURIComponent(this.socialLink);
    }

    getLinkedInLinkForMember() {
        return `https://www.linkedin.com/sharing/share-offsite/?url=${this.getInviteLinkTextByMember(this.communityData?.is_paid ? 1 : 0)}`;
    }

    getFacebookLinkForMember() {
        return `https://www.facebook.com/sharer/sharer.php?u=${this.getInviteLinkTextByMember(this.communityData?.is_paid ? 1 : 0)}`;
    }

    getTwitterLinkForMember() {
        return `https://twitter.com/intent/tweet?text=${this.getInviteLinkTextByMember(this.communityData?.is_paid ? 1 : 0)}`;
    }

    getWhatsAppLinkForMember() {
        return `https://api.whatsapp.com/send?text=${this.getInviteLinkTextByMember(this.communityData?.is_paid ? 1 : 0)}`;
    }

    copyInviteLinkByAMember() {
        const is_paid = this.communityData?.is_paid;
        if (is_paid) {
            this.copyLink(1);
        } else {
            this.copyLink(0);
        }
    }
    linkText: string;
    copyLink(val) {
        // Ensure that this.user and this.user.url exist
        if (this.user && this.user.url) {
            if (val === 0 && (this.memberData?.state === 4 || this.memberData?.state === 9)) {
                this.linkText = this.user.url?.community_share?.private_link;
            } else {
                this.linkText = this.user.url?.community_share?.private_link_text;
            }

            if (this.linkText) {
                // Create a temporary textarea element
                const selBox = document.createElement('textarea');
                selBox.value = this.linkText;
                document.body.appendChild(selBox);
                selBox.select();
                document.execCommand('copy');
                document.body.removeChild(selBox);

                // Show a success message
                this.openCopiedToClipboard('Invite link copied to clipboard', 'OK');
            } else {
                console.log('Link text is not available');
            }
        } else {
            console.log('User or user.url is not available');
        }
    }

    emailInviteLinkByAMember() {
        const is_paid = this.communityData?.is_paid;
        if (is_paid) this.emailLink(1);
        else this.emailLink(0);
    }

    emailText: string;

    getMailtoLink(): string {
        const isPaid = this.communityData?.is_paid;

        if (isPaid) {
            return this.emailLink(1);
        } else {
            return this.emailLink(0);
        }
    }

    emailLink(val: number): string {
        if (val === 0 && (this.memberData?.state === 4 || this.memberData?.state === 9)) {
            this.emailText = this.user?.url?.community_share?.private_link;
        } else {
            this.emailText = this.user?.url?.community_share?.private_link_text;
        }

        const subject = '';
        const body = this.emailText || 'This is only a test!';

        return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    }

    memberData: any;
    /**
     * @function getMemberState
     * @param params
     * @description This function is used to get the member state
     */
    getMemberState() {
        this.communityService.getMemberState().subscribe((resData) => {
            this.memberData = resData?.data;
        });
    }

    facebookClicked(inivte_link_type) {
        if (inivte_link_type == this.inivte_link_type_free) {
            this.analyticsService.sendEvent(INVITE_FLOW.FREE_MEMBER_INVITED, {
                type: 'via_social',
                social_type: 'facebook',
            });
        } else if (inivte_link_type == this.inivte_link_type_paid) {
            this.analyticsService.sendEvent(INVITE_FLOW.PAID_MEMBER_INVITED, {
                type: 'via_social',
                social_type: 'facebook',
            });
        }
    }

    twitterClicked(inivte_link_type) {
        if (inivte_link_type == this.inivte_link_type_free) {
            this.analyticsService.sendEvent(INVITE_FLOW.FREE_MEMBER_INVITED, {
                type: 'via_social',
                social_type: 'twitter',
            });
        } else if (inivte_link_type == this.inivte_link_type_paid) {
            this.analyticsService.sendEvent(INVITE_FLOW.PAID_MEMBER_INVITED, {
                type: 'via_social',
                social_type: 'twitter',
            });
        }
    }

    linkedInClicked(inivte_link_type) {
        if (inivte_link_type == this.inivte_link_type_free) {
            this.analyticsService.sendEvent(INVITE_FLOW.FREE_MEMBER_INVITED, {
                type: 'via_social',
                social_type: 'linkedin',
            });
        } else if (inivte_link_type == this.inivte_link_type_paid) {
            this.analyticsService.sendEvent(INVITE_FLOW.PAID_MEMBER_INVITED, {
                type: 'via_social',
                social_type: 'linkedin',
            });
        }
    }

    getLinkToShare(val) {
        let shareableLink = '';
        if (val === 0 && this.memberData?.state !== 1) {
            shareableLink = this.user.url.community_share.public_link;
        } else if (val === 0 && this.memberData?.state === 1) {
            shareableLink = this.user.url.community_share.private_link;
        } else if (val === 1 && this.memberData?.state === 1) {
            shareableLink = this.user.url.community_share.public_link;
        }

        return shareableLink;
    }

    getLinkTextToShare(val) {
        let shareableLink = '';
        if (val === 0 && this.memberData?.state !== 1) {
            shareableLink = this.user.url.community_share.public_link_text;
        } else if (val === 0 && this.memberData?.state === 1) {
            shareableLink = this.user.url.community_share.private_link_text;
        } else if (val === 1 && this.memberData?.state === 1) {
            shareableLink = this.user.url.community_share.public_link_text;
        }

        return shareableLink;
    }

    getLinkedInLink(val) {
        return `https://www.linkedin.com/sharing/share-offsite/?url=${this.getLinkToShare(val)}`;
    }

    getFacebookLink(val) {
        return `https://www.facebook.com/sharer/sharer.php?u=${this.getLinkToShare(val)}`;
    }

    getTwitterLink(val) {
        return `https://twitter.com/intent/tweet?text=${this.getLinkTextToShare(val)}`;
    }

    getWhatsAppLink(val) {
        return `https://api.whatsapp.com/send?text=${this.getLinkTextToShare(val)}-${this.getLinkToShare(val)}`;
    }

    copyToClipBoard(val) {
        let link;
        if (val === 0 && this.memberData?.state !== 1) {
            link = this.user.url.community_share.public_link_text;
            this.copiedLink = this.user.url.community_share.public_link;
        } else if (val === 0 && this.memberData?.state === 1) {
            link = this.user.url.community_share.private_link_text;
            this.copiedLink = this.user.url.community_share.private_link;
            this.analyticsService.sendEvent(INVITE_FLOW.FREE_MEMBER_INVITED, {
                type: 'copy_link',
            });
        } else if (val === 1 && this.memberData?.state === 1) {
            if (this.communityData?.is_freemium_community === false) {
                link = this.user.url.community_share.public_link_text;
                this.copiedLink = this.user.url.community_share.public_link;
                this.analyticsService.sendEvent(INVITE_FLOW.PAID_MEMBER_INVITED, {
                    type: 'copy_link',
                });
            } else {
                link = this.user.url.community_share.private_link_text;
                this.copiedLink = this.user.url.community_share.private_link;
                this.analyticsService.sendEvent(INVITE_FLOW.PAID_MEMBER_INVITED, {
                    type: 'copy_link',
                });
            }
        }

        this.openCopiedToClipboard('Invite link copied to clipboard', 'OK');

        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = link;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    downloadApp(store: string) {
        this.analyticsService.sendEvent(MIXPANEL.DOWNLOAD_APP, {
            source: 'pop_up',
            type: store === APPSTORE ? IOS : ANDROID,
            distinct_id: this.user?.id,
        });
        if (store === APPSTORE) window.open(environment.appstoreLink, '_blank');
        else if (store === PLAYSTORE) window.open(environment.playstoreLink, '_blank');
    }

    submit() {
        if (this.isModal) this.closeModal.emit(this.choice);
        if (this.isSheet) this.closeSheet.emit(this.choice);
    }

    showInviteFreeMemberView() {
        const is_paid = this.communityData?.is_paid;

        if (is_paid) {
            this.toggle1 = true;
            this.toggle2 = false;
        } else {
            this.toggle1 = false;
            this.toggle2 = true;
        }

        this.showFreeMemberToggleView = true;
        this.showPaidMemberToggleView = false;
    }

    showInviteFreeMemberViewMobile() {
        const is_paid = this.communityData?.is_paid;

        if (is_paid) {
            this.toggle1 = true;
            this.toggle2 = false;
        } else {
            this.toggle1 = false;
            this.toggle2 = true;
        }
        this.showFreeMemberToggleView = true;
        this.showPaidMemberToggleView = false;
    }
    showInvitePaidMemberViewMobile() {
        const is_paid = this.communityData?.is_paid;
        const likeminds_plan = this.communityData?.likeminds_plan;
        if (likeminds_plan == null && likeminds_plan != undefined) {
            this.dialog.open(UpgradeYourPlanDialogComponent);
        } else if (is_paid) {
            this.showUpgradeYourPlanModal = false;
            this.showAddMembershipPlanModal = false;
            this.showInviteViaMailView = false;
            this.showInviteViaWhatsAppView = false;
            this.toggle1 = false;
            this.toggle2 = true;
        } else {
            this.dialog.open(AddMembershipPlanDialogComponent, {
                data: {
                    createMembershipUrl: `${window.location.origin}/community_feed/${this.user['communityId']}?membership_plan=true`,
                },
            });
        }
        this.showFreeMemberToggleView = false;
        this.showPaidMemberToggleView = true;
    }
    showInvitePaidMemberView() {
        const is_paid = this.communityData?.is_paid;
        const likeminds_plan = this.communityData?.likeminds_plan;
        if (likeminds_plan == null && likeminds_plan != undefined) {
            this.showUpgradeYourPlanModal = true;
            this.showAddMembershipPlanModal = false;
            this.showInviteViaMailView = false;
            this.showInviteViaWhatsAppView = false;
            this.toggle1 = true;
            this.toggle2 = false;
        } else if (is_paid) {
            this.showUpgradeYourPlanModal = false;
            this.showAddMembershipPlanModal = false;
            this.showInviteViaMailView = false;
            this.showInviteViaWhatsAppView = false;
            this.toggle1 = false;
            this.toggle2 = true;
        } else {
            this.showUpgradeYourPlanModal = false;
            this.showAddMembershipPlanModal = true;
            this.showInviteViaMailView = false;
            this.showInviteViaWhatsAppView = false;
            this.toggle1 = true;
            this.toggle2 = false;
        }
        this.showFreeMemberToggleView = false;
        this.showPaidMemberToggleView = true;
    }

    showInviteMemberViaEmailMobile(link_type) {
        this.toggle2 = false;
        const sheet = this.sheet.open(InviteMembersViaEmailMobileComponent, {
            data: {
                sheetRef: this.sheet,
                communityId: this.user?.communityId,
                inivte_link_type: link_type,
                communityName: this.communityName,
            },
            panelClass: 'invite-via-email-mobile',
        });
        sheet.afterDismissed().subscribe((response) => {
            this.toggle2 = true;
        });
    }

    showInviteMemberViaWhatsAppMobile(link_type) {
        this.toggle2 = false;
        const sheet = this.sheet.open(InviteMembersViaWhatsappMobileComponent, {
            data: {
                sheetRef: this.sheet,
                communityId: this.user?.communityId,
                inivte_link_type: link_type,
                communityName: this.communityName,
            },
            panelClass: 'invite-via-email-mobile',
        });
        sheet.afterDismissed().subscribe((response) => {
            this.toggle2 = true;
        });
    }

    showInviteViaEmail() {
        this.showInviteViaMailView = true;
        this.showInviteViaWhatsAppView = false;
    }

    showInviteViaWhatsapp() {
        this.showInviteViaMailView = false;
        this.showInviteViaWhatsAppView = true;
    }

    startMembershipPlanCreationFlow() {
        let url = `${window.location.origin}/community_feed/${this.user['communityId']}?membership_plan=true`;
        window.open(url);
    }

    resetStateToDefaultView() {
        if (this.showInviteViaMailView || this.showInviteViaWhatsAppView) {
            this.showInviteViaMailView = false;
            this.showInviteViaWhatsAppView = false;
            return;
        }
        this.showFreeMemberToggleView = false;
        this.showPaidMemberToggleView = false;
        this.toggle1 = false;
        this.toggle2 = false;
        this.showUpgradeYourPlanModal = false;
        this.showAddMembershipPlanModal = false;
        this.showInviteViaMailView = false;
        this.showInviteViaWhatsAppView = false;
    }

    setInvitationTypeMobile(val) {
        this.invitationType = val;
        this.invitationTypeSelected = true;
        if (val == 0) {
            let radio = document.getElementById('radio2') as HTMLInputElement;
            radio.checked = false;
        } else if (val == 1) {
            let radio = document.getElementById('radio1') as HTMLInputElement;
            radio.checked = false;
        }
    }

    clickedShared(val) {
        if (val == 0) {
            this.showInviteFreeMemberViewMobile();
            let radio = document.getElementById('radio2') as HTMLInputElement;
            radio.checked = false;
        } else if (val == 1) {
            this.showInvitePaidMemberViewMobile();
            let radio = document.getElementById('radio1') as HTMLInputElement;
            radio.checked = false;
        }
    }
    clickedRadio(val) {
        if (val == 0) {
            this.showInviteFreeMemberViewMobile();
            let radio = document.getElementById('radio2') as HTMLInputElement;
            radio.checked = false;
        } else if (val == 1) {
            this.showInvitePaidMemberView();
            let radio = document.getElementById('radio1') as HTMLInputElement;
            radio.checked = false;
        }
    }

    leaveCommunity(communityId) {
        this.close();

        let subscriptionStatus = '';
        if (this.user?.membership_state == 1) {
            subscriptionStatus = 'expired';
        }

        if (this.user?.membership_state == 2) {
            subscriptionStatus = 'grace';
        }

        if (this.user?.membership_state == 3 || this.user?.membership_state == 0) {
            subscriptionStatus = 'active';
        }

        this.analyticsService.sendEvent(RENEWAL_FLOW.LEAVE_COMMUNITY, {
            community_id: communityId,
            community_name: this.user?.community_name,
            subscription_status: subscriptionStatus,
        });

        this.utilsService.closeMatDialogBox$$.next(true);

        this.communityService.leaveCommunity(`community_id=${communityId}`).subscribe((res) => {
            if (res.success) {
                this.localStorageService.setSavedState(true, STORAGE_KEY.HAS_LEFT_COMMUNITY);
                this.cookieService?.delete(COMMMUNITY_OPENED);
                this.router.navigate([`${ROOT_PATH}`]);
            }
        });
    }

    cancelSubscription(communityId) {
        this.close();
        // ANALYTICS :
        this.analyticsService.sendEvent(JOIN_FLOW.CANCEL_MEMBERSHIP_REQUEST, {
            community_id: this.user['communityId'],
            community_name: this.user['community_name'],
            source: 'community_detail',
        });
        this.submit();
        this.subscriptionService.cancelSubscription({ community_id: communityId }).subscribe((res) => {
            this.communityService.leaveCommunity(`community_id=${communityId}`).subscribe((res) => {
                if (res.success) {
                    this.localStorageService.setSavedState(true, STORAGE_KEY.HAS_LEFT_COMMUNITY);
                    this.cookieService?.delete(COMMMUNITY_OPENED);
                    this.router.navigate([`${ROOT_PATH}`]);
                }
            });
        });
    }

    openShareInfo(index) {
        if (index == '1') {
            this.utilsService.closeMatBottomSheet$$.next(1);
        } else if (index == '2') {
            this.utilsService.closeMatBottomSheet$$.next(2);
        }
    }

    closeShareInfoPopup() {
        this.utilsService.closeMatBottomSheet$$.next(0);
    }
}
