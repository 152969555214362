import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'keys',
})
export class KeysPipe implements PipeTransform {
    transform(input: string, cssClass: string = ''): string {
        if (!input) {
            return '';
        }

        const values = input.split('$#');

        // Map the split values to <span> elements with the specified class
        const spanElements = values.map((value) => `<span class="${cssClass}">${value}</span>`);

        // Join the <span> elements into a single string
        return spanElements.join('');
    }
}
