<div class="lm-wrapper">
    <div class="wrapper-header">
        Edit Profile sdsdsd

        <div class="close cursor-pointer" (click)="close()">
            <img src="../../../../assets/images/svg/ch-img/close.svg" alt="close" />
        </div>
    </div>

    <form #myForm="ngForm" (ngSubmit)="onSubmit()">
        <div class="wrapper-body">
            <div class="image-container">
                <img class="profile-image" [src]="user?.image_url" onerror="this.src='../../../../assets/images/png/avatar.png'" />
                <label>
                    <app-camera class="camera"></app-camera>
                    <input
                        #imageFileUpload
                        type="file"
                        accept="image/*"
                        (change)="uploadImage($event)"
                        (click)="imageFileUpload.value = ''"
                    />
                </label>
            </div>

            <ng-container *ngFor="let question of formData">
                <!-- If it's the "About You" question, use a text input -->
                <ng-container *ngIf="question.options.length === 0">
                    <div class="form-group" *ngIf="question.question_title === 'Your Name'">
                        <label for="{{ question.question_title }}"
                            >{{ question.question_title }}
                            <span *ngIf="question.rank === 0 || question.rank === 1">*</span>
                        </label>
                        <input type="text" [(ngModel)]="user.name" class="form-control" name="{{ question.question_title }}" />
                    </div>
                    <div class="form-group" *ngIf="question.question_title !== 'Your Name'">
                        <label for="{{ question.question_title }}"
                            >{{ question.question_title }}
                            <span *ngIf="question.rank === 0 || question.rank === 1">*</span>
                        </label>
                        <input type="text" [(ngModel)]="question.answer" class="form-control" name="{{ question.question_title }}" />
                    </div>
                </ng-container>

                <!-- Check if the question title is not "About You" -->
                <ng-container *ngIf="question.options.length > 0">
                    <div class="form-group">
                        <label for="{{ question.question_title }}"
                            >{{ question.question_title }}
                            <span *ngIf="question.rank === 0 || question.rank === 1">*</span>
                        </label>
                        <!-- Add options for the select box -->
                        <select class="form-select" [(ngModel)]="question.answer" name="{{ question.question_title }}">
                            <option *ngFor="let option of question.options" [value]="option.value">
                                {{ option.value }}
                            </option>
                        </select>
                    </div>
                </ng-container>
            </ng-container>
        </div>

        <div class="wrapper-footer">
            <button type="submit">Save</button>
        </div>
    </form>
</div>
