import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IUser } from '../../models/user.model';

@Component({
    selector: 'app-ch-add-tool-stack',
    templateUrl: './ch-add-tool-stack.component.html',
    styleUrls: ['./ch-add-tool-stack.component.scss'],
})
export class ChAddToolStackComponent implements OnInit {
    @Input() user: IUser;

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            user: any;
            title: any;
        }
    ) {}

    ngOnInit(): void {}

    close() {
        this.dialogRef.close();
    }

    confirm() {
        this.dialogRef.close('confirm');
    }
}
