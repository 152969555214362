<!-- Ch edit about component -->
<div class="lm-wrapper">
    <div class="wrapper-header">
        About You

        <div class="close cursor-pointer" (click)="close()">
            <img src="../../../../assets/images/svg/ch-img/close.svg" alt="close" />
        </div>
    </div>

    <form #myForm="ngForm" (ngSubmit)="onSubmit()">
        <div class="wrapper-body" *ngFor="let question of formData">
            <!-- If it's the "About You" question, use a text input -->
            <ng-container *ngIf="question.question.question_title === 'About You'">
                <div class="about-circle">
                    <label for="about"> About you <span>*</span> </label>
                    <div class="circle-box">
                        <circle-progress
                            class="circle"
                            matTooltip="Minimum characters: 50"
                            matTooltipClass="red-tooltip"
                            matTooltipPosition="above"
                            matTooltipShow="true"
                            [percent]="calculatePercentage(question.question_answer.answer)"
                            [radius]="4"
                            [outerStrokeWidth]="2"
                            [innerStrokeWidth]="2"
                            [space]="-2"
                            [innerStrokeColor]="getStrokeColour()"
                            [outerStrokeColor]="getStrokeColour()"
                            [titleFontSize]="2"
                            [unitsFontSize]="2"
                            [showTitle]="false"
                            [showUnits]="false"
                            [showSubtitle]="false"
                            [animation]="true"
                            [animationDuration]="300"
                            [startFromZero]="false"
                            [responsive]="true"
                        >
                        </circle-progress>
                    </div>
                </div>
                <div class="form-group">
                    <textarea
                        [(ngModel)]="question.question_answer.answer"
                        cols="30"
                        rows="10"
                        class="form-textarea"
                        name="about"
                    ></textarea>
                </div>
                <span class="cursor-pointer hint" (click)="openHint()">Here are few examples</span>
            </ng-container>

            <!-- Check if the question title is not "About You" -->
            <ng-container *ngIf="question.question.question_title !== 'About You' && !question.question.is_hidden">
                <div class="form-group">
                    <label for="{{ question.question.question_title }}">{{ question.question.question_title }}</label>

                    <select class="form-select" [(ngModel)]="question.question_answer.answer" name="{{ question.question.question_title }}">
                        <option *ngFor="let option of question?.question?.value" [value]="option.value">
                            {{ option.value }}
                        </option>
                    </select>
                </div>
            </ng-container>
        </div>

        <div class="wrapper-footer">
            <button type="submit">Save</button>
        </div>
    </form>
</div>
