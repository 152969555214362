import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { IScreens } from './tutorial.model';

@Component({
    selector: 'app-tutorial',
    templateUrl: './tutorial.component.html',
    styleUrls: ['./tutorial.component.scss'],
})
export class TutorialComponent implements OnInit {
    screens: IScreens;

    constructor(private authService: AuthService) {}

    ngOnInit(): void {
        this.onboardingScreen();
    }

    onboardingScreen() {
        this.authService.getOnboardingScreen().subscribe(
            (resData) => {
                this.screens = resData.data.screens;
            },
            (err) => {
                // location.reload();
                console.log(err);
            }
        );
    }
}
