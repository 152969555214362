<section class="member-card position-relative">
    <img
        [src]="member?.image_url"
        class="cursor-pointer"
        *ngIf="imgInitShow"
        (error)="onImgError($event, member?.name)"
        alt="{{ member?.name }}"
        (click)="viewMemberDirectory()"
    />
    <div
        *ngIf="!imgInitShow"
        (click)="viewMemberDirectory()"
        class="img defaultImg d-flex justify-content-center align-items-center text-white"
        [ngStyle]="{ 'background-color': circleColor }"
    >
        <span>{{ imgInit1 }}</span>
    </div>
    <div class="member-details position-absolute w-100 b0 text-center">
        <p class="cF fs15 fw500 mb-0 mt-1" [ngClass]="{ 'mt-2 pt-2': !member?.custom_title }">
            {{ member?.name | ellipsisWord: 17 }}
        </p>
        <div class="member-status d-inline-flex text-center mx-auto" *ngIf="member?.custom_title">
            <app-badge-icon></app-badge-icon>
            <p class="custom_title">{{ member?.custom_title | ellipsisWord: 15 }}</p>
        </div>
    </div>
    <div class="last-tile-overlay cursor-pointer" (click)="viewMemberDirectory()" *ngIf="member?.isLastTile">
        <div class="overlay">
            <p class="fs24 fw500 cF">+{{ remainingMembersCount }}</p>
        </div>
    </div>
</section>
