import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';

import { ConfigService } from './../../core/services/config.service';
import { FILL_REQUIRED_ERROR } from './../../shared/constants/app-constant';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { STORAGE_KEY } from 'src/app/shared/enums/storage-keys.enum';
import { PaymentsService } from './../../core/services/payments.service';

declare var Razorpay: any;

@Component({
    selector: 'app-custom-payment',
    templateUrl: './custom-payment.component.html',
    styleUrls: ['./custom-payment.component.scss'],
})
export class CustomPaymentComponent implements OnInit {
    constructor(
        private paymentsService: PaymentsService,
        private route: ActivatedRoute,
        private subscriptionService: SubscriptionService,
        private snackBar: MatSnackBar,
        private localStorageService: LocalStorageService,
        private configService: ConfigService
    ) {}

    status = 0;
    community_id = '';
    page: any = {};
    is_paying = false;
    loading = true;
    page_id = '';
    payment_id = '';
    redirect = 5;
    community: any = {};
    data = {
        name: '',
        email: '',
        phone: '',
        amount: 0,
    };
    contact: any = {};

    preview: Boolean;

    ngOnInit(): void {
        this.loading = true;

        var user = this.localStorageService.getSavedState(STORAGE_KEY.LIKEMINDS_USER);
        if (user) {
            this.data.name = user.name || '';
            this.data.email = user.emails[0].email || '';
            this.data.phone = user.mobiles[0].mobile_no || '';
        }
        this.route.queryParams.subscribe((params) => {
            if (params.payment_page_id) {
                this.page_id = params.payment_page_id;
                this.community_id = this.route.snapshot.paramMap.get('community_id');
                this.paymentsService.fetchOne(this.page_id).subscribe(
                    (res: any) => {
                        this.loading = false;
                        this.page = res.payment_page;
                        this.community = res.community.community;
                        this.data.amount = this.page.amount_type === 'fixed' ? this.page.amount / 100 : 0;
                        if (params.success === 'true') {
                            this.status = 1;
                            if (this.page.redirect_url) {
                                setInterval(() => {
                                    this.redirect -= 1;
                                    if (this.redirect === 0) {
                                        window.location.replace(this.page.redirect_url);
                                    }
                                }, 1000);
                            }
                            this.payment_id = params.payment_id;
                        }
                        if (this.page.is_active === false) {
                            this.status = 4;
                        }
                    },
                    (err) => {}
                );
            } else {
                this.preview = true;
                this.page.title = params.title;
                this.page.description = params.description;
                this.page.amount_type = params.amount_type;
                this.data.amount = params.amount_type === 'fixed' ? params.amount : 0;
                this.community_id = params.community_id;
                this.paymentsService.fetchContact().subscribe((res: any) => {
                    this.contact = res.contact_us;
                    this.page.contact_email = res.contact_us.email;
                    this.page.contact_mobile_no = `${res.contact_us.country_code}${res.contact_us.mobile_no}`;
                });

                this.configService.getCommunityDetails(this.community_id).subscribe((res: any) => {
                    this.community = res.community;
                });
            }
        });
    }

    change_data(field, value) {
        this.data[field] = value;
    }

    toggle_paying() {
        this.is_paying = !this.is_paying;
    }

    createOrder() {
        if (this.preview === true) {
            return;
        }
        const body = {
            payment_page_id: this.page_id,
            payment_page_url: window.location.href,
            amount: this.page.amount_type === 'fixed' ? this.page.amount : this.data.amount * 100,
            payment_name: this.data.name,
        };

        if (
            this.data.name === '' ||
            this.data.email === '' ||
            this.data.phone === '' ||
            this.data.amount <= 0 ||
            this.data.amount > 10000000
        ) {
            this.snackBar.open(FILL_REQUIRED_ERROR + ' with valid data.', '✕', {
                duration: 5000,
                panelClass: ['snackbar-toast', 'bg-primary-dashboard'],
            });
            return;
        }

        this.paymentsService.order(body).subscribe((res) => {
            let order_id = res['order']['order_id'];
            res['order']['handler'] = (response) => {
                let data = {
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_signature: response.razorpay_signature,
                    order_id: order_id,
                };

                this.subscriptionService.verifyOrder(data).subscribe(
                    (res) => {
                        if (res.success) {
                            window.location.replace(
                                '/payment_page?payment_page_id=' +
                                    this.page.payment_page_id +
                                    '&success=true&payment_id=' +
                                    data.razorpay_payment_id
                            );
                        } else {
                            this.status = 2;
                        }
                    },
                    (err) => {
                        this.status = 2;
                    }
                );
            };

            res['order']['prefill'] = {
                name: this.data.name,
                email: this.data.email,
                contact: this.data.phone,
            };

            var rzp1 = new Razorpay(res['order']);
            rzp1.on('payment.failed', function (response) {});

            rzp1.open();
        });
    }
}
