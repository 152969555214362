<section class="wrapper">
    <div class="heading">Profile does not exist</div>

    <div class="body">
        <p>{{ data?.user_obj?.custom_click_text }}</p>
    </div>

    <div class="button" (click)="close()">
        <button>OK</button>
    </div>
</section>
