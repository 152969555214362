import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Notification, NotificationType } from 'src/app/shared/components/notification/notification';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    private _subject = new Subject<Notification>();
    private _idx = 0;

    constructor() {}

    getObservable(): Observable<Notification> {
        return this._subject.asObservable();
    }

    info(logo: string, communityName: string, title: string, message: string, route: string, timeout = 2000) {
        this._subject.next(new Notification(this._idx++, NotificationType.info, logo, communityName, title, message, route, timeout));
    }

    // success(title: string, message: string, timeout = 3000) {
    //   this._subject.next(new Notification(this._idx++, NotificationType.success, title, message, timeout));
    // }

    // warning(title: string, message: string, timeout = 3000) {
    //   this._subject.next(new Notification(this._idx++, NotificationType.warning, title, message, timeout));
    // }

    // error(title: string, message: string, timeout = 0) {
    //   this._subject.next(new Notification(this._idx++, NotificationType.error, title, message, timeout));
    // }
}
