<div class="modDialog">
    <div class="cancelBtn" (click)="close()">
        <img src="../../../../assets/images/svg/secret_cancel_icon.svg" alt="cancel" />
    </div>
    <p class="modDialog__contentTitle">Select chat room privacy</p>
    <p class="modDialog__content">
        Your chatroom can have different formats based on your requirements. Select the chat room type that you want to create from the list
        below:
    </p>

    <form #myForm="ngForm" (submit)="templateForm(myForm.value)">
        <div class="modDialog__type">
            <div class="border-bottom">
                <input id="open" class="mt-2 float-start" type="radio" value="open" name="crType" [(ngModel)]="crType" checked />
                <div class="icon">
                    <img src="../../../../assets/images/svg/open.svg" alt="icon" />
                </div>
                <div class="desc">
                    <div class="title">Open</div>
                    <div class="subTitle">All community members can access it</div>
                </div>
            </div>
            <div>
                <input id="secret" class="mt-2 float-start" type="radio" value="secret" name="crType" [(ngModel)]="crType" />
                <div class="icon">
                    <img src="../../../../assets/images/svg/secret_lock.svg" alt="icon" />
                </div>
                <div class="desc">
                    <div class="title">Secret</div>
                    <div class="subTitle">Private chatroom for invited community members only</div>
                </div>
            </div>
        </div>
        <div class="modDialog__btnBox">
            <button type="submit" class="modDialog__btnBox--confirm text-uppercase btn-custom">Done</button>
        </div>
    </form>
</div>
