import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-new-option-dialog',
    templateUrl: './new-option-dialog.component.html',
    styleUrls: ['./new-option-dialog.component.scss'],
})
export class NewOptionDialogComponent implements OnInit {
    newOption = '';
    constructor(private dialogRef: MatDialogRef<any>) {}

    ngOnInit(): void {}

    close() {
        this.dialogRef.close();
    }

    saveChanges() {
        if (this.newOption) {
            const option = {
                value: this.newOption,
            };
            this.dialogRef.close(option);
        }
    }
}
