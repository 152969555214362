<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fill-rule="evenodd">
        <g class="fill-brand-light" fill-rule="nonzero">
            <g>
                <g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <g>
                                                <path
                                                    d="M6.313 13.538c-1.285 0-2.492-.337-3.539-.927v2.927c0 .157.08.303.211.388.131.085.297.098.44.033l2.888-1.299 2.887 1.3c.06.027.125.04.19.04.087 0 .174-.025.25-.074.132-.085.211-.23.211-.388v-2.927c-1.047.59-2.254.927-3.539.927zM8.12 6.09l.636-1.193-1.352-.047c-.153-.005-.294-.086-.375-.216l-.716-1.147-.717 1.147c-.081.13-.222.211-.375.216l-1.352.047.636 1.194c.072.135.072.298 0 .433L3.87 7.718l1.35.047c.154.005.295.087.376.217l.716 1.147.717-1.147c.081-.13.222-.212.375-.217l1.351-.047-.635-1.194c-.072-.135-.072-.298 0-.433z"
                                                    transform="translate(-428 -1179) translate(240 829) translate(32 49) translate(0 90) translate(64) translate(92 211)" />
                                                <path
                                                    d="M6.313 0C2.833 0 .005 2.83.005 6.308c0 2.193 1.126 4.129 2.83 5.26.017.01.033.022.05.033l.062.04.052.032.062.038.083.05.079.044.056.032.063.034.058.03.06.032.098.049.068.032.065.03.061.028.067.029.058.025.115.047.05.02.078.03.058.02c.025.01.05.018.076.027l.055.019.122.04.042.013.092.027.053.015.086.023c.017.005.034.01.05.013l.13.032.032.007.106.024.05.01.095.018.046.008.137.023.02.003.122.017.046.006.102.011.044.005c.047.005.095.01.143.013h.01l.134.01.045.001.106.004.044.002.147.001c.049 0 .097 0 .146-.002h.044l.107-.005.044-.002.135-.009h.01l.142-.013.044-.005.102-.011.046-.006.122-.017.02-.003.137-.023.046-.008.095-.018.05-.01.106-.024.032-.007c.043-.01.087-.02.13-.032l.05-.013.086-.023.053-.015.092-.027.042-.013.123-.04.054-.019.076-.027.058-.02.077-.03.051-.02.115-.047.059-.025.066-.03.06-.027.066-.03c.023-.01.045-.022.068-.033l.097-.048.061-.032.058-.03.063-.034.056-.032.079-.045.083-.05.062-.037.052-.033.062-.04c.017-.01.034-.022.05-.033 1.704-1.13 2.83-3.066 2.83-5.26C12.62 2.83 9.79 0 6.313 0zm3.605 7.937c.074.14.071.31-.008.448-.08.137-.225.225-.384.23l-1.844.064-.978 1.565c-.084.135-.232.217-.391.217-.16 0-.308-.082-.392-.217L4.943 8.68 3.1 8.615c-.16-.005-.304-.093-.384-.23-.08-.138-.082-.307-.008-.448l.867-1.63-.867-1.629c-.074-.14-.071-.31.008-.447.08-.138.225-.225.384-.23l1.844-.065.978-1.565c.084-.135.232-.217.392-.217.159 0 .307.082.391.217l.978 1.565L9.526 4c.16.006.304.093.384.23.08.139.082.308.008.448l-.867 1.63.867 1.63z"
                                                    transform="translate(-428 -1179) translate(240 829) translate(32 49) translate(0 90) translate(64) translate(92 211)" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>