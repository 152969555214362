<div>
    <form [formGroup]="invitationForm" (ngSubmit)="checkDataAndSendInvite()">
        <div class="invite-member" [ngClass] = "{'mat-field-error':errorInWhatsAppList}">
            <mat-form-field class="example-chip-list" appearance="fill">
                <mat-chip-list #chipList class="chip-list" aria-label="Fruit selection" (click)="setFoucusOnInput()">
                    <mat-chip
                    
                      *ngFor="let whatsAppNo of whatsAppList"
                      (removed)="remove(whatsAppNo)"
                      (click) = "onChipClick($event)"
                      [ngClass] = "{'mat-chip-error':!checkIfMobileNumberisValid(whatsAppNo)}"
                      >
                      {{whatsAppNo}}
                      <mat-icon matChipRemove [ngClass] = "{'icon-error-style':!checkIfMobileNumberisValid(whatsAppNo)}" >cancel</mat-icon>
                    </mat-chip>
                    <input
                        matInput class="email-input " 
                        [placeholder]="showPlaceHolderText()"
                        formControlName="whatsAppString"
                        #whatsAppInput
                        [formControl]="whatsAppCtrl"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="add($event)"
                        type="tel"
                        autocomplete="off"
                      >
                  </mat-chip-list>
            </mat-form-field>
            <!-- <input matInput class="email-input mt-1 pt-2" placeholder="Add upto 100 Mobile no. here."
                       formControlName="whatsAppString" type="text" autocomplete="off" /> -->
            <div class='upload-download-wrapper'>
                <span class="download-csv" (click)="downloadSampleWhatsAppCsvFile()">
                    Download Sample CSV
                </span>
                <span class="upload-csv" (click)="uploadWhatsapp.click()">
                    <input hidden  type="file" accept=".csv" (change)="convertFile($event)" (click)="$event.target.value=null" #uploadWhatsapp>
                    Upload CSV
                </span>
            </div>      
        </div>
        <div>
            <!-- <textarea matInput  rows="7" class="text-area-input form-control" id="aboutInput" formControlName="invitationText"
            placeholder="Write invitation message here."></textarea> -->
            <div class="invite-message_box">
                <p>
                    Hello!
                </p>
                <p>
                    I’d like to invite you to our community, {{communityName}} .
                </p>
                <p>
                    It takes less than a minute to join and together we’re sharing our stories, experiences, and ideas.
                </p>
                <p>
                    I know you’ll love it.
                </p>
                <p>
                    See you here!
                </p>
            </div>
        </div>
        <div class="send-btn-wrapper">
            <button class='send-btn-cta' type="submit">Send</button>
        </div>
    </form>
    
</div>