export const GET_TERMS_AND_CONDITIONS = '[Terms & Condition] Get Terms and Conditions';
export const GET_TERMS_AND_CONDITIONS_SUCCESS = '[Terms & Condition] Get Terms and Conditions Success';
export const GET_TERMS_AND_CONDITIONS_FAILURE = '[Terms & Condition] Get Terms and Conditions Failure';
export const CLEAR_TERMS_AND_CONDITIONS = '[Terms & Condition] Clear Terms and Conditions';
export const START_LOADING = '[App] Start Loading';
export const STOP_LOADING = '[App] Stop Loading';

export const GET_PRIVACY_POLICY = '[Privacy Policy] Get Privacy Policy';
export const GET_PRIVACY_POLICY_SUCCESS = '[Privacy Policy] Get Privacy Policy Success';
export const GET_PRIVACY_POLICY_FAILURE = '[Privacy Policy] Get Privacy Policy Failure';
export const CLEAR_PRIVACY_POLICY = '[Privacy Policy] Clear Privacy Policy';

export const GET_HEADERS = '[Headers] Get Headers Data';
export const SET_HEADER = '[Headers] Set Header';
export const CLEAR_HEADER = '[Headers] Clear Header';
export const GET_HEADERS_SUCCESS = '[Headers] Get Headers Data Success';
export const GET_HEADERS_FAILURE = '[Headers] Get Headers Data Failure';


export const GET_COUNTRY_CODES = '[Login] Get Country Codes';
export const GET_COUNTRY_CODES_SUCCESS = '[Login] Get Country Codes Success';
export const GET_COUNTRY_CODES_FAILURE = '[Login] Get Country Codes Failure';
export const CLEAR_COUNTRY_CODES = '[Login] Clear Country Codes';

export const SET_MESSAGE = '[App] Set Message';
export const CLEAR_MESSAGE = '[App] Clear Message';
export const SET_REDIRECT_URL = '[App] Set Redirect URL';
export const CLEAR_REDIRECT_URL = '[App] Clear Redirect URL';