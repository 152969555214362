<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g>
                    <path fill="#FFF" fill-opacity="0" d="M0 0H28V28H0z" transform="translate(-128 -238) translate(112 226) translate(16 12)"/>
                    <g class="fill-brand-light" fill-rule="nonzero">
                        <path d="M17.563 6.25H.483C.218 6.25 0 6.04 0 5.781V4.375C0 3.34.866 2.5 1.934 2.5h1.933V.469c0-.258.218-.469.484-.469h1.61c.267 0 .484.21.484.469V2.5h5.157V.469c0-.258.217-.469.483-.469h1.611c.266 0 .484.21.484.469V2.5h1.933c1.068 0 1.934.84 1.934 1.875v1.406c0 .258-.218.469-.484.469zM.483 7.5h17.08c.266 0 .484.21.484.469v10.156c0 1.035-.866 1.875-1.934 1.875H1.933C.867 20 0 19.16 0 18.125V7.969C0 7.71.218 7.5.483 7.5zm4.673 7.969c0-.258-.217-.469-.483-.469H3.062c-.266 0-.484.21-.484.469v1.562c0 .258.218.469.484.469h1.61c.267 0 .484-.21.484-.469V15.47zm0-5c0-.258-.217-.469-.483-.469H3.062c-.266 0-.484.21-.484.469v1.562c0 .258.218.469.484.469h1.61c.267 0 .484-.21.484-.469V10.47zm5.157 5c0-.258-.218-.469-.484-.469H8.218c-.266 0-.484.21-.484.469v1.562c0 .258.218.469.484.469h1.611c.266 0 .483-.21.483-.469V15.47zm0-5c0-.258-.218-.469-.484-.469H8.218c-.266 0-.484.21-.484.469v1.562c0 .258.218.469.484.469h1.611c.266 0 .483-.21.483-.469V10.47zm5.156 5c0-.258-.218-.469-.484-.469h-1.611c-.266 0-.483.21-.483.469v1.562c0 .258.217.469.483.469h1.611c.266 0 .484-.21.484-.469V15.47zm0-5c0-.258-.218-.469-.484-.469h-1.611c-.266 0-.483.21-.483.469v1.562c0 .258.217.469.483.469h1.611c.266 0 .484-.21.484-.469V10.47z" transform="translate(-128 -238) translate(112 226) translate(16 12) translate(5 4)"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
