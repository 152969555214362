<div class="aop-wrapper">
    <div class="aop-header">
        Available only on App
    </div>
    <div class="cross close">
        <img src="assets/images/svg/cross-dark-gray.svg" (click)="close()" class="cursor-pointer" alt="Close">
    </div>
    <div class="aop-content">
        Please use mobile apps (Android or iOS) to make any changes in the Commnity Settings.
    </div>
    <div (click)="close()" class="aop-cta">
        OK
    </div>
</div>