import { Component, OnInit} from '@angular/core';
import { DeviceService } from 'src/app/core/services/device.service';
import {ActivatedRoute} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import { STORAGE_KEY } from '../../shared/enums/storage-keys.enum';
import { truncate } from 'lodash';


@Component({
  selector: 'app-email-unsubscribed',
  templateUrl: './email-unsubscribed.component.html',
  styleUrls: ['./email-unsubscribed.component.scss']
})

export class EmailUnsubscribedComponent implements OnInit {
  screen:any = 1;
  eventNotification: boolean;
  chatroomNotification: boolean;
  allNotification: boolean;
  communityId : number;
  result : any;

  constructor(private deviceService:DeviceService,
    private route: ActivatedRoute,
    private cookieService:CookieService) { }
  
  ngOnInit(): void {
    this.communityId = parseInt(this.route.snapshot.queryParamMap.get('communityId'));
    this.cookieService.set(STORAGE_KEY.EMAIL_UNSUB_USER_ID,this.route.snapshot.queryParamMap.get('memberId'));
    this.eventNotification = true;
    this.chatroomNotification = true;
    this.fetchFlags();
  }

  filterData(data:any, flagName:string) {
    var filteredData =  data.notification_flags.filter(object => {
      return object['code'] == flagName;
    });
    if (filteredData.length != 0){
      return filteredData[0].flag
    }
    else{
      return true;
    }
  }

  fetchFlags(){
    const param = {
      "community_id" : this.route.snapshot.queryParamMap.get('communityId')
    }
    this.deviceService.fetchNotificationFlags(param).subscribe((res)=>{
      this.result = res;
      this.checkBox();
    })

  }


  checkBox(){
    let eventFlag = this.filterData(this.result, "mail_event_notifications");
    let chtroomFlag = this.filterData(this.result, "mail_chatroom_or_dm");
    this.eventNotification = eventFlag;
    this.chatroomNotification = chtroomFlag;
    if (eventFlag == false && chtroomFlag == false) 
    {
      this.allNotification = true;
    }
    if (eventFlag == true && chtroomFlag == true) 
    {
      this.allNotification = false;

    }
    if (this.eventNotification == true || this.chatroomNotification == true){
      this.allNotification = false;
    }
  }

  onUnsubscribed(param){
    this.deviceService.emailUnsubscribe(param).subscribe((res)=>{
    })
  }

  checkboxUncheck(event){
    if(!this.allNotification){
      this.chatroomNotification = false;
      this.eventNotification = false;
    }
  
  }


  changeScreenToSubscribePage(event){
    this.screen = 1;
    this.fetchFlags();
  }


  callingUnsubscribeEmail(event) {
    if (!this.allNotification && !this.eventNotification && !this.chatroomNotification){
      alert("Please select any option");
      return;
    }
    let param:Object
    if (this.eventNotification){
      param = {"community_id": this.communityId ,"code_flag": {"mail_event_notifications":true,"mail_chatroom_or_dm":false}};
    }
    if (this.chatroomNotification){
      param = {"community_id": this.communityId ,"code_flag": {"mail_event_notifications":false,"mail_chatroom_or_dm":true}};
    }
    if (this.eventNotification && this.chatroomNotification){
      param = {"community_id": this.communityId ,"code_flag": {"mail_event_notifications":true,"mail_chatroom_or_dm":true}};
    }
    if(this.allNotification){
      param = {"community_id": this.communityId ,"code_flag": {"mail_event_notifications":false,"mail_chatroom_or_dm":false}};
    }
    this.onUnsubscribed(param);
    this.screen=2;

  }

}
