<div class="emoji-list-dialog">
    <div class="header">
        <div class="header__top">
            <div class="heading">Reactions ({{ messageReactions?.length }})</div>
            <img [mat-dialog-close]="false" class="emoji-list-btn-close" src="../../../../assets/images/svg/cross.svg" alt="close" />
        </div>
        <div class="emoji-details">
            <div
                class="emoji-details__box"
                (click)="sortMessageReactions($event)"
                [ngClass]="{ 'selected-emoji': selectedEmoji === 'All' }"
            >
                <div class="emoji-details__box-info">
                    <div class="value">All {{ messageReactions.length }}</div>
                </div>
                <div class="box-bottom-border"></div>
            </div>
            <div
                #emojiDetailsBox
                *ngFor="let sortedReaction of sortedReactionsList"
                class="emoji-details__box"
                (click)="sortMessageReactions($event)"
                [ngClass]="{ 'selected-emoji': sortedReaction?.emoji === selectedEmoji }"
            >
                <div class="emoji-details__box-info">
                    <div class="emoji">{{ sortedReaction?.emoji }}</div>
                    <div class="value">{{ sortedReaction?.value }}</div>
                </div>
                <div class="box-bottom-border"></div>
            </div>
            <div
                *ngIf="moreEmojisValue"
                class="emoji-details__box"
                (click)="sortMessageReactions($event)"
                [ngClass]="{ 'selected-emoji': selectedEmoji === 'More' }"
            >
                <div class="emoji-details__box-info">
                    <div class="emoji"><img src="../../../../assets/images/svg/more-emoji-dialog.svg" /></div>
                    <div class="value">{{ moreEmojisValue }}</div>
                </div>
                <div class="box-bottom-border"></div>
            </div>
        </div>
    </div>

    <div class="emoji-list">
        <div *ngFor="let item of sortedMessageReactions" class="emoji-list__details">
            <div class="emoji-list__details--left">
                <img *ngIf="item?.member?.image_url; else fallbackImage" [src]="item?.member?.image_url" class="member-image" />
                <ng-template #fallbackImage>
                    <div class="fallback-image" [style.background-color]="item?.bgColor">
                        {{ item?.nameInitials }}
                    </div>
                </ng-template>
                <div class="reaction-info">
                    <div class="member-name">{{ item?.member?.name }}</div>
                    <div *ngIf="item?.member?.id === user?.id" class="emoji-list-remove-btn" [mat-dialog-close]="true">Click to remove</div>
                </div>
            </div>
            <div class="emoji-list__details--right">{{ item?.reaction }}</div>
        </div>
    </div>
</div>
