<svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10">
    <g fill="none" fill-rule="evenodd">
        <g class="fill-brand" fill-rule="nonzero">
            <g>
                <g>
                    <g>
                        <g>
                            <path
                                d="M61.026 17.551L60 16.527 66.699 9.756 60.002 3.027 61.024 2 68.74 9.753 61.026 17.551"
                                transform="translate(-740 -1233) translate(288 368) translate(0 273) translate(0 303) translate(396 284) rotate(90 64.37 9.776)" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>