
<div *ngIf="isLoading" class="loader-container position-fixed d-flex justify-content-center w-100 h-100">
    <div class="overlay w-100 h-100 position-absolute"></div>
    <div class="loader spinner-border align-self-center"></div>
</div>

<main>
    <router-outlet></router-outlet>
</main>
<app-size-detector></app-size-detector>

<!-- <div *ngIf="isLoading" class="loader-container position-fixed d-flex justify-content-center w-100 h-100">
    <div class="overlay w-100 h-100 position-absolute"></div>
    <div class="loader spinner-border align-self-center"></div>
</div>

<main>
    <router-outlet></router-outlet>
</main>
<app-size-detector></app-size-detector> -->
