import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'newlineToBr',
})
export class NewlineToBrPipe implements PipeTransform {
    transform(input: string): string {
        if (!input) {
            return '';
        }

        // Replace newline characters with HTML line breaks
        return input.replace(/\n/g, '<br>');
    }
}
