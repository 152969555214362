import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar-square-icon',
  templateUrl: './calendar-square.component.html',
  styleUrls: ['./calendar-square.component.scss']
})
export class CalendarSquareComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
