import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare let fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class FacebookPixelService {

  constructor() { }

  /**
   * @function registerPixelEvent
   * @description Service to register events on facebook pixel
   */

  registerPixelEvent(eventName: string, eventValue: string, params?: object): void {
    if (fbq && environment.production) {
      fbq(eventName, eventValue, params);
    }
  }

}
