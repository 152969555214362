<div class="eventContent">
    <img src="../../../../../../assets/images/svg/cross.svg" alt="Cancel" width="20" height="20" class="cancelBtn" (click)=close()>
    <div class="eventContent__header mt-3">
        <h2>Guidelines for online event url</h2>
        <p class="eventContent--subHeading">Use the following guidelines to best use the online event url:</p>
    </div>
    <div class="eventContent__header">
        <h2>What are online events</h2>
        <p class="eventContent--subHeading">Online events are the events that can be performed via web video conferencing tools. There are plenty of video conferencing tools out there like Zoom, Hangout, Skype etc.</p>
    </div>
    <div class="eventContent__header">
        <h2>Recommended online platforms</h2>
        <p class="eventContent--subHeading">Recommended tools are those where joining the conference is easier and can handle the number of expected participants joining your event online.</p>
    </div>
    <div class="eventContent__header">
        <h2>Link to online event</h2>
        <p class="eventContent--subHeading">Make sure that you provide the video conferencing urls and not the event description page from other platforms.</p>
    </div> 
</div>