import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from '../services/localstorage.service';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { STORAGE_KEY } from 'src/app/shared/enums/storage-keys.enum';
import { catchError, switchMap } from 'rxjs/operators';
import { AUTH_PATH, ROOT_PATH } from 'src/app/shared/constants/routes.constant';

@Injectable({
    providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private snackbar: MatSnackBar,
        private localStorageService: LocalStorageService,
        private router: Router,
        private auth: AuthService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                //access_token expired
                // console.log('error ', error.error);
                if (error.status === 401 && error.error?.error_message === 'Invalid LTM!') {
                    return this.auth.refreshToken().pipe(
                        switchMap((response) => {
                            if (response.success) {
                                this.localStorageService.setSavedState(response?.data?.access_token, STORAGE_KEY.ACCESS_TOKEN_LTM);
                                this.localStorageService.setSavedState(response?.data?.refresh_token, STORAGE_KEY.REFRESH_TOKEN_RTM);

                                request = request.clone({
                                    headers: request.headers.set(
                                        'Authorization',
                                        `Bearer ${this.localStorageService.getSavedState(STORAGE_KEY.ACCESS_TOKEN_LTM)}`
                                    ),
                                });

                                return next.handle(request);
                            }
                            return throwError(() => new Error(error.error));
                        })
                    );
                } else if (error.status === 401 && error.error?.error_message === 'Invalid OTM!') {
                    return this.auth.oneTimeTokent({ token_type: 'otm' }).pipe(
                        switchMap((response) => {
                            if (response.success) {
                                localStorage.setItem(STORAGE_KEY.REFRESH_TOKEN_OTM, JSON.stringify(response.data.access_token));

                                request = request.clone({
                                    headers: request.headers.set(
                                        'Authorization',
                                        `Bearer ${this.localStorageService.getSavedState(STORAGE_KEY.REFRESH_TOKEN_OTM)}`
                                    ),
                                });

                                return next.handle(request);
                            }
                            return throwError(() => new Error(error.error));
                        })
                    );
                } else if (error.status === 401 && error.error?.error_message === 'Invalid VTM!') {
                    return this.auth.oneTimeTokent({ token_type: 'vtm' }).pipe(
                        switchMap((response) => {
                            if (response.success) {
                                localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN_VTM, JSON.stringify(response?.data?.access_token));

                                request = request.clone({
                                    headers: request.headers.set(
                                        'Authorization',
                                        `Bearer ${this.localStorageService.getSavedState(STORAGE_KEY.ACCESS_TOKEN_LTM)}`
                                    ),
                                });

                                return next.handle(request);
                            }
                            return throwError(() => new Error(error.error));
                        })
                    );
                }
                //refresh_token expired
                else if (error.status === 401 && error.error?.error_message === 'Invalid RTM!') {
                    // console.log(2);
                    // localStorage.clear();
                    this.router.navigate([`/${AUTH_PATH}`]);
                }
                //No project found redirect
                else if (error.status === 500 && error.error?.error_message === 'No projects found') {
                    this.router.navigate([`/${ROOT_PATH}`], {
                        queryParams: {
                            new_user: true,
                        },
                    });
                }
                //No project found redirect
                else if (error.error?.error_message === 'Feed feature is disabled in this community') {
                    // console.log(error.error?.error_message);
                }
                // this.snackbar.open(error.status === 0 ? error.message : error.error?.error_message, 'Close', {
                //     duration: 5000,
                //     panelClass: ['error-alert-snackbar'],
                //     horizontalPosition: 'right',
                // });
                else return throwError(() => error);
            })
        );
    }
}
