<div class="verify-otp-container col-md-12 text-center">
    <div class="row">
        <p class="hide-sm w-100 my-4 c0 fs20">{{header?.title}}</p>
        <div class="card mx-auto rounded pb-5 px-3">
            <section class="pt-4">
                <div class="container text-center">
                    <h4 class="mt-3 fs20 fw-bold c0">{{ 'verifyOtp.enterVerificationOtp' | translate }}</h4>
                    <p class="c6 fs14">{{ 'verifyOtp.verifyOtpText' | translate }}</p>
                    <p class="c0 fs16">+{{ otpInfo?.country_code }} {{ otpInfo?.mobile_no }}</p>
                    <div class="mx-auto">
                        <ng-otp-input [ngClass]="{ 'invalid': invalidOtp }" #ngOtpInput
                            (onInputChange)="onOtpChange($event)" *ngIf="showOtpComponent" [config]="config">
                        </ng-otp-input>
                    </div>
                    <button (click)="submitVerifyOtpForm()" class="btn cF mt-4 br24 fs13 fw500 mx-auto text-uppercase"
                        type="submit">{{ 'verifyOtp.verify' | translate }}</button>
                    <div class="text-center">
                        <p class="timer mt-4" *ngIf="counter > 0">{{ counter | countdown }}</p>
                        <span class="timer mt-4 d-inline-block cursor-pointer" (click)="resendOtp()" *ngIf="counter === 0">Resend
                            verification
                            code</span>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>