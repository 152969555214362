import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar-round-icon',
  templateUrl: './calendar-round.component.html',
  styleUrls: ['./calendar-round.component.scss']
})
export class CalendarRoundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
