import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { Subject } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { STORAGE_KEY } from '../../enums/storage-keys.enum';
import { takeUntil } from 'rxjs/operators';
import { StartLoading, StopLoading } from '../../store/actions/app.action';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { AuthService } from 'src/app/core/services/auth.service';
import { Question } from '../../models/question.model';
import { memberService } from '../../../core/services/members.service';

const MAX_FILE_SIZE_IN_MBS = 16;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_IN_MBS * 1024 * 1024;

@Component({
    selector: 'app-ch-edit-profile',
    templateUrl: './ch-edit-profile.component.html',
    styleUrls: ['./ch-edit-profile.component.scss'],
})
export class ChEditProfileComponent implements OnInit {
    private destroy$$ = new Subject();
    @Input() user: any;
    formData: any[] = [];
    image_source: string;
    form: any;
    formSubmitted = false;
    aboutDataObj: any;

    designationOptions: string[] = [];
    sepcializationOptions: string[] = [];

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            aboutObj: any;
        },
        private profileService: ProfileService,
        private localStorageService: LocalStorageService,
        private store: Store<State>,
        private snackbar: MatSnackBar,
        private dialog: MatDialog,
        private authService: AuthService,
        private memberService: memberService
    ) {}

    ngOnInit(): void {
        this.user = this.localStorageService?.getSavedState(STORAGE_KEY.LIKEMINDS_USER);
        this.profileQuestions();
    }

    onSubmit() {
        const index = this.formData.findIndex((obj) => {
            return obj.question_title === 'Your Name';
        });

        this.formData[index].answer = this.user.name;

        const params = {
            question_answers: this.formData,
        };
        // return;
        this.profileService.editMemberProfileCh(params).subscribe((resData) => {
            if (resData.success) {
                this.confirm();
                this.getProfileData(this.user.id);
            }
        });
    }

    /**
     * @function getProfileData
     * @param params
     * @description This function is used to logout user
     */
    getProfileData(userId: any) {
        this.profileService.memberProfile(userId).subscribe((resData) => {
            this.user.name = resData.data.member.name;
            this.user = this.localStorageService.setSavedState(this.user, STORAGE_KEY.LIKEMINDS_USER);
            // Updating the user object
            this.memberService.members$$.next(JSON.parse(localStorage.getItem(STORAGE_KEY.LIKEMINDS_USER)));
        });
    }

    close() {
        this.dialogRef.close();
    }

    confirm() {
        this.dialogRef.close('updated');
    }

    /**
     * @function onDesignationChange
     * @description This function is used to filter the questions
     */
    // Method to handle MatSelect changes
    onDesignationChange(event: MatSelectChange): void {
        const userGroup = this.form.get('user') as FormGroup;
        userGroup.get('designation').setValue(event.value);
    }

    checkIfErrorInFiles(files): boolean {
        const fileArray: any[] = Array.from(files);
        if (fileArray.find((file) => file.size > MAX_FILE_SIZE_BYTES)) {
            this.snackbar.open(`Maximum allowed size is ${MAX_FILE_SIZE_IN_MBS}Mbs.`, undefined, {
                panelClass: ['snackbar'],
                duration: 3000,
            });
            return true;
        }
        return false;
    }

    uploadImage(event) {
        if (this.checkIfErrorInFiles(event.target.files)) {
            return;
        }

        this.dialog
            .open(ImageCropperComponent, {
                data: {
                    member_id: this.user?.id,
                    event: event,
                },
            })
            .afterClosed()
            .subscribe((response) => {
                if (response) {
                    this.image_source = 'gallery';
                    this.updateProfile(response);
                }
            });
    }

    updateProfile(event) {
        const payload = new HttpParams().set('type', 'image').set('value', event);

        this.store.dispatch(StartLoading());
        this.profileService
            .updateProfileImage(payload)
            .pipe(takeUntil(this.destroy$$))
            .subscribe(
                (response) => {
                    if (response?.success) {
                        let updatedUser = this.user;
                        updatedUser.image_url = event;
                        this.localStorageService.setSavedState(updatedUser, STORAGE_KEY.LIKEMINDS_USER);
                        this.user = updatedUser;
                        // Updating the user object
                        this.memberService.members$$.next(JSON.parse(localStorage.getItem(STORAGE_KEY.LIKEMINDS_USER)));
                    } else
                        this.snackbar.open(`Error updating profile.`, null, {
                            duration: 3000,
                            panelClass: ['black-bottom-left-snackbar'],
                        });
                    this.store.dispatch(StopLoading());
                },
                (error) => {
                    this.store.dispatch(StopLoading());
                    this.snackbar.open(`Error updating profile.`, null, {
                        duration: 3000,
                        panelClass: ['black-bottom-left-snackbar'],
                    });
                }
            );
    }

    /**
     * @function profileQuestions
     * @description This function is used to get profileQuestions
     */
    profileQuestions(): void {
        this.authService
            .getProfileQuestions()
            .pipe(takeUntil(this.destroy$$))
            .subscribe((resData) => {
                // let aboutDataObj;
                this.aboutDataObj = this.filterQuestionsStepOne(resData?.data?.questions, 'basic');

                if (this.data.aboutObj.length > 0) {
                    const secObj = this.aboutDataObj.map((item: any) => ({ question: item, question_answer: {} }));
                    const combinedObjects = [...this.data.aboutObj, ...secObj];

                    const uniqueCombineObj = combinedObjects.filter(
                        (obj, index, self) =>
                            self.findIndex((item) => item.question.question_title === obj.question.question_title) === index
                    );

                    const newObject = {};
                    uniqueCombineObj.forEach((item) => {
                        const questionId = item.question?.id || item.question_id || '';
                        if (!newObject[questionId]) {
                            newObject[questionId] = {
                                question_title: item.question?.question_title || '',
                                question_id: questionId?.toString(),
                                answer: item.question_answer?.answer || '',
                                question_answer: item.question_answer || {},
                                options: item.question?.value ? JSON.parse(item.question.value) : [],
                                rank: item.question.rank,
                            };
                        }
                        let aboutDataObj;
                        aboutDataObj = Object.values(newObject);
                        this.formData = aboutDataObj.sort((a, b) => a.rank - b.rank);
                    });
                }
            });
    }

    /**
     * @function filterQuestionsStepOne
     * @description This function is used to filter the questions
     */
    filterQuestionsStepOne(questions: Question[], type?: string): Question[] {
        if (type === 'basic') {
            return questions.filter((question) => {
                return question.tag === 'basic';
                // return question.tag === 'basic' && question.state !== 7;
            });
        }
    }

    ngOnDestroy(): void {
        this.destroy$$.next(null);
        this.destroy$$.complete();
    }
}
