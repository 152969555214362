<div class="eventContentNotify"> 
    <div class="close">
        <img class="cursor=pointer" src="assets/images/svg/back-navigation-arrow.svg" alt="cancel">
    </div>
    <div class="eventContent__header mt-3">
        <h2>Allow notifications</h2>
        <p class="eventContent--subHeading">Don't miss any updates. Subscribe to notification</p>
    </div>
    <div class="btn-box d-flex">
        <button class="btn btn-link text-success" (click)="sure(true)">Ok, Got it</button>
    </div>
</div>