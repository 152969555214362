<ng-container *ngIf="user['task'] === 'leaveCommunityPopup'">
    <div class="w-100 close">
        <img src="assets/images/svg/cross.svg" (click)="close()" class="float-end cursor-pointer" alt="Close" />
    </div>
    <div class="p-4">
        <div *ngIf="user['state'] === 3">
            <h2><b>Cancel membership request?</b></h2>
            <h3>Are you sure you want to cancel your request to join this community</h3>
            <div class="d-flex justify-content-end">
                <div class="btn" (click)="submit()">CANCEL</div>
                <div (click)="cancelSubscription(user['commmunityId'])" class="confirm-btn btn">CONFIRM</div>
            </div>
        </div>
        <div *ngIf="user['state'] === 4 || user['state'] === 0">
            <h4><b>Leave commununity?</b></h4>
            <p>
                Are you sure you want to leave this community permanently? Your community profile will be removed whereas any content
                created you would remain.
            </p>
            <div class="d-flex justify-content-end">
                <div class="btn" (click)="submit()">CANCEL</div>
                <div class="confirm-btn btn me-2" (click)="leaveCommunity(user['commmunityId'])">OK, LEAVE NOW</div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="user['task'] === 'cancelMembershipPop'">
    <div class="w-100 close">
        <img src="assets/images/svg/cross.svg" (click)="close()" class="float-end cursor-pointer" alt="Close" />
    </div>
    <div class="p-4">
        <div>
            <h2><b>Cancel membership request?</b></h2>
            <h3>Are you sure you want to cancel your request to join this community</h3>
            <div class="d-flex justify-content-end">
                <div class="btn" (click)="submit()">CANCEL</div>
                <div (click)="cancelSubscription(user['commmunityId'])" class="confirm-btn btn">CONFIRM</div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="user['task'] === 'shareUrlPopupMobile'">
    <div class="seperatorNew"></div>
    <div class="p-3">
        <div *ngIf="user['state'] !== 1">
            <h2 class="invite-heading">Invite Members to your community 1</h2>
            <div class="d-flex flex-column">
                <div class="invite-content-container margin-mobile">
                    <div class="d-flex align-items-center invite-via_container" (click)="copyInviteLinkByAMember()">
                        <div class="invite-via_image">
                            <img class="cursorPointer" src="assets/images/svg/copy.svg" alt="" />
                        </div>
                        <div class="invite-via">Copy invite link</div>
                    </div>
                </div>
                <a class="invite-anchor-style" [href]="getFacebookLinkForMember()" target="_blank">
                    <div class="invite-content-container margin-mobile">
                        <div class="d-flex align-items-center invite-via_container">
                            <div class="invite-via_image">
                                <img class="cursorPointer" src="assets/images/svg/facebook.svg" alt="" />
                            </div>
                            <div class="invite-via">Facebook</div>
                        </div>
                    </div>
                </a>
                <a class="invite-anchor-style" [href]="getLinkedInLinkForMember()" target="_blank">
                    <div class="invite-content-container margin-mobile">
                        <div class="d-flex align-items-center invite-via_container">
                            <div class="invite-via_image">
                                <img class="cursorPointer" src="assets/images/svg/linkedin.svg" alt="" />
                            </div>
                            <div class="invite-via">LinkedIn</div>
                        </div>
                    </div>
                </a>

                <a class="invite-anchor-style" [href]="getTwitterLinkForMember()" target="_blank">
                    <div class="invite-content-container margin-mobile">
                        <div class="d-flex align-items-center invite-via_container">
                            <div class="invite-via_image">
                                <img class="cursorPointer" src="assets/images/svg/twitter.svg" alt="" />
                            </div>
                            <div class="invite-via">Twitter</div>
                        </div>
                    </div>
                </a>
                <a class="invite-anchor-style" [href]="getWhatsAppLinkForMember()" target="_blank">
                    <div class="invite-content-container margin-mobile">
                        <div class="d-flex align-items-center invite-via_container">
                            <div class="invite-via_image">
                                <img class="cursorPointer" src="assets/images/svg/whatsapp-logo.svg" alt="" />
                            </div>
                            <div class="invite-via">Whatsapp</div>
                        </div>
                    </div>
                </a>
            </div>
        </div>

        <div *ngIf="user['state'] === 1">
            <ng-container *ngIf="!toggle1 && !toggle2">
                <h2 class="invite-heading">Invite members to your community 2</h2>

                <div class="invite-div d-flex flex-column">
                    <div class="d-flex justify-content-between align-items-center ms-1">
                        <div class="d-flex align-items-center">
                            <input id="radio1" (click)="setInvitationTypeMobile(0)" type="radio" />
                            <div class="mx-4">
                                <h2>Invite Free Members</h2>
                                <h4>For your inner circle whom you want to invite for free.</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="invite-div d-flex flex-column">
                    <div class="d-flex justify-content-between align-items-center ms-1">
                        <div class="d-flex align-items-center">
                            <input id="radio2" (click)="setInvitationTypeMobile(1)" type="radio" />
                            <div class="mx-4">
                                <h2>Invite Paid Members</h2>
                                <h4>Payment will be mandatory for members joining via this invitation.</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="share-btn-container">
                    <button
                        class="share-btn"
                        [ngClass]="{ 'btn-disabled': !invitationTypeSelected }"
                        (click)="clickedShared(invitationType)"
                    >
                        Share
                    </button>
                </div>
            </ng-container>
            <ng-container *ngIf="showPaidMemberToggleView">
                <ng-container *ngIf="toggle1">
                    <div class="dummy_modal">
                        <div *ngIf="showUpgradeYourPlanModal">
                            <h2 class="invite-header">Upgrade Your Plan</h2>
                            <div class="invite-content-container no-plan">
                                <div class="no-plan-wrapper">
                                    <div class="no-plan__image">
                                        <img src="assets/images/svg/upgradeAdd.svg" height="175px" alt="fb" />
                                    </div>
                                    <div class="no-plan__content">
                                        You can invite a member with paid membership after you upgrade your plan. Get in touch with us at
                                        <a class="mail contact" href="mailto:hi@likeminds.community">hi@likeminds.community</a> to upgrade
                                        or click below.
                                    </div>
                                    <div class="share-btn-container no-plan_footer">
                                        <a class="share-btn no-plan_footer_btn mail" href="mailto:hi@likeminds.community"> Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="showAddMembershipPlanModal">
                            <h2 class="invite-header">Add Membership Plans</h2>
                            <div class="invite-content-container">
                                <div class="no-plan-wrapper">
                                    <div class="no-plan__image">
                                        <img src="assets/images/svg/upgradeAdd.svg" height="175px" alt="fb" />
                                    </div>
                                    <div class="no-plan__content">
                                        You have not created any membership plans yet. To invite members to join your community via paid
                                        invitation, Connect with the LikeMinds team(<a
                                            class="mail contact"
                                            href="mailto:hi@likeminds.community"
                                            >hi@likeminds.community</a
                                        >
                                        ).
                                    </div>

                                    <div class="share-btn-container no-plan_footer">
                                        <a class="share-btn no-plan_footer_btn mail" href="mailto:hi@likeminds.community"> Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="toggle2">
                    <h2 class="invite-header-mobile">Invite via Paid Invitation</h2>
                    <div class="invite-content-container margin-mobile">
                        <div class="d-flex align-items-center invite-via_container" (click)="copyToClipBoard(1)">
                            <div class="invite-via_image">
                                <img class="cursorPointer" src="assets/images/svg/copy.svg" alt="" />
                            </div>
                            <div class="invite-via">Copy invite link</div>
                        </div>
                    </div>
                    <div class="invite-content-container margin-mobile">
                        <div
                            class="d-flex align-items-center invite-via_container"
                            (click)="showInviteMemberViaEmailMobile(inivte_link_type_paid)"
                        >
                            <div class="invite-via_image">
                                <img src="assets/icons/mail-icon.png" />
                            </div>
                            <div class="invite-via">Invite via email</div>
                        </div>
                    </div>
                    <div class="invite-content-container margin-mobile">
                        <div
                            class="d-flex invite-via_container align-items-center"
                            (click)="showInviteMemberViaWhatsAppMobile(inivte_link_type_paid)"
                        >
                            <div class="invite-via_image">
                                <img height="34px" src="assets/images/svg/whatsapp-logo.svg" />
                            </div>
                            <div class="invite-via">Invite via Whatsapp</div>
                        </div>
                    </div>
                    <a
                        class="invite-anchor-style"
                        [href]="getFacebookLink(1)"
                        target="_blank"
                        (click)="facebookClicked(inivte_link_type_paid)"
                    >
                        <div class="invite-content-container margin-mobile">
                            <div class="d-flex align-items-center invite-via_container">
                                <div class="invite-via_image">
                                    <img class="cursorPointer" src="assets/images/svg/facebook.svg" alt="" />
                                </div>
                                <div class="invite-via">Facebook</div>
                            </div>
                        </div>
                    </a>
                    <a
                        class="invite-anchor-style"
                        [href]="getLinkedInLink(1)"
                        target="_blank"
                        (click)="linkedInClicked(inivte_link_type_paid)"
                    >
                        <div class="invite-content-container margin-mobile">
                            <div class="d-flex align-items-center invite-via_container">
                                <div class="invite-via_image">
                                    <img class="cursorPointer" src="assets/images/svg/linkedin.svg" alt="" />
                                </div>
                                <div class="invite-via">LinkedIn</div>
                            </div>
                        </div>
                    </a>

                    <a
                        class="invite-anchor-style"
                        [href]="getTwitterLink(1)"
                        target="_blank"
                        (click)="twitterClicked(inivte_link_type_paid)"
                    >
                        <div class="invite-content-container margin-mobile">
                            <div class="d-flex align-items-center invite-via_container">
                                <div class="invite-via_image">
                                    <img class="cursorPointer" src="assets/images/svg/twitter.svg" alt="" />
                                </div>
                                <div class="invite-via">Twitter</div>
                            </div>
                        </div>
                    </a>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="showFreeMemberToggleView">
                <ng-container *ngIf="toggle1">
                    <h2 class="invite-header-mobile">Invite via Free Invitation</h2>
                    <div class="invite-content-container margin-mobile">
                        <div
                            class="d-flex align-items-center invite-via_container"
                            (click)="showInviteMemberViaEmailMobile(inivte_link_type_free)"
                        >
                            <div class="invite-via_image">
                                <img src="assets/icons/mail-icon.png" />
                            </div>
                            <div class="invite-via">Invite via email</div>
                        </div>
                    </div>
                    <div class="invite-content-container margin-mobile">
                        <div
                            class="d-flex invite-via_container align-items-center"
                            (click)="showInviteMemberViaWhatsAppMobile(inivte_link_type_free)"
                        >
                            <div class="invite-via_image">
                                <img height="34px" src="assets/images/svg/whatsapp-logo.svg" />
                            </div>
                            <div class="invite-via">Invite via whatsapp</div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="toggle2">
                    <h2 class="invite-header-mobile">Invite via Free Invitation</h2>
                    <div class="invite-content-container margin-mobile">
                        <div class="d-flex align-items-center invite-via_container" (click)="copyToClipBoard(0)">
                            <div class="invite-via_image">
                                <img class="cursorPointer" src="assets/images/svg/copy.svg" alt="" />
                            </div>
                            <div class="invite-via">Copy invite link</div>
                        </div>
                    </div>
                    <div class="invite-content-container margin-mobile">
                        <div
                            class="d-flex align-items-center invite-via_container"
                            (click)="showInviteMemberViaEmailMobile(inivte_link_type_free)"
                        >
                            <div class="invite-via_image">
                                <img src="assets/icons/mail-icon.png" />
                            </div>
                            <div class="invite-via">Invite via email</div>
                        </div>
                    </div>
                    <div class="invite-content-container margin-mobile">
                        <div
                            class="d-flex invite-via_container align-items-center"
                            (click)="showInviteMemberViaWhatsAppMobile(inivte_link_type_paid)"
                        >
                            <div class="invite-via_image">
                                <img height="34px" src="assets/images/svg/whatsapp-logo.svg" />
                            </div>
                            <div class="invite-via">Invite via Whatsapp</div>
                        </div>
                    </div>
                    <a
                        class="invite-anchor-style"
                        [href]="getFacebookLink(0)"
                        target="_blank"
                        (click)="facebookClicked(inivte_link_type_free)"
                    >
                        <div class="invite-content-container margin-mobile">
                            <div class="d-flex align-items-center invite-via_container">
                                <div class="invite-via_image">
                                    <img class="cursorPointer" src="assets/images/svg/facebook.svg" alt="" />
                                </div>
                                <div class="invite-via">Facebook</div>
                            </div>
                        </div>
                    </a>
                    <a
                        class="invite-anchor-style"
                        [href]="getLinkedInLink(0)"
                        target="_blank"
                        (click)="linkedInClicked(inivte_link_type_free)"
                    >
                        <div class="invite-content-container margin-mobile">
                            <div class="d-flex align-items-center invite-via_container">
                                <div class="invite-via_image">
                                    <img class="cursorPointer" src="assets/images/svg/linkedin.svg" alt="" />
                                </div>
                                <div class="invite-via">LinkedIn</div>
                            </div>
                        </div>
                    </a>

                    <a
                        class="invite-anchor-style"
                        [href]="getTwitterLink(1)"
                        target="_blank"
                        (click)="twitterClicked(inivte_link_type_free)"
                    >
                        <div class="invite-content-container margin-mobile">
                            <div class="d-flex align-items-center invite-via_container">
                                <div class="invite-via_image">
                                    <img class="cursorPointer" src="assets/images/svg/twitter.svg" alt="" />
                                </div>
                                <div class="invite-via">Twitter</div>
                            </div>
                        </div>
                    </a>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="user['task'] === 'shareUrlPopup'">
    <div class="back" (click)="resetStateToDefaultView()" *ngIf="toggle1 || toggle2">
        <img src="assets/images/png/backArrow.png" class="cursor-pointer" alt="Close" />
    </div>
    <div class="close">
        <img src="assets/images/svg/crossInvite.svg" (click)="close()" class="cursor-pointer" alt="Close" />
    </div>

    <div class="invite-content-wrapper">
        <div *ngIf="user['state'] !== 1">
            <h2 class="invite-heading">Invite Members</h2>
            <div class="d-flex flex-column">
                <div class="d-flex flex-column copy-link">
                    <div class="d-flex align-items-center cursor-pointer" (click)="copyInviteLinkByAMember()">
                        <img class="cursorPointer" src="assets/images/svg/ch-img/ch-clip.svg" alt="link" />
                        <h3 class="head-new">Copy invite Link</h3>
                    </div>
                    <div class="seperatorLine"></div>
                    <div class="d-flex align-items-center">
                        <img class="cursorPointer" src="assets/images/svg/ch-img/ch-email.svg" alt="Email" />
                        <h3 class="head-new">
                            <a [href]="getMailtoLink()"> Share via email </a>

                            <!-- <a [href]="'mailto:?subject=' + '' | encodeURIComponent + '&body=' + emailText | encodeURIComponent">
                                Share via email
                            </a> -->
                        </h3>
                    </div>
                    <div class="seperatorLine"></div>
                    <p class="text-muted-new no-left-margin">Send on social platforms</p>
                    <div class="media-container media-container-new no-left-margin">
                        <a [href]="getFacebookLinkForMember()" target="_blank">
                            <img src="assets/images/svg/facebook.svg" alt="fb" />
                        </a>
                        <a [href]="getLinkedInLinkForMember()" target="_blank">
                            <img src="assets/images/svg/linkedin.svg" alt="linkedIn" />
                        </a>
                        <a [href]="getTwitterLinkForMember()" target="_blank">
                            <img src="assets/images/svg/twitter.svg" alt="twitter" />
                        </a>
                        <!-- <a [href]="getWhatsAppLinkForMember()" target="_blank">
                            <img src="assets/images/svg/whatsapp-logo.svg" height="57px" alt="whatsapp" />
                        </a> -->
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="user['state'] === 1">
            <ng-container *ngIf="!toggle1 && !toggle2">
                <h2 class="invite-header">Invite Members</h2>
                <div class="invite-content-container" (click)="showInviteFreeMemberView()">
                    <div class="invite-content_option">Invite Free Members</div>
                    <div class="invite-content_option_info">For your inner circle whom you want to invite for free.</div>
                    <div class="invite-content_arrow_icon"><img src="assets/icons/Shape.png" /></div>
                </div>
                <div class="line"></div>
                <div class="invite-content-container" (click)="showInvitePaidMemberView()">
                    <div class="invite-content_option">Invite Paid Members</div>
                    <div class="invite-content_option_info">Payment will be mandatory for members joining via this invitation.</div>
                    <div class="invite-content_arrow_icon"><img src="assets/icons/Shape.png" /></div>
                </div>
            </ng-container>
            <ng-container *ngIf="showPaidMemberToggleView">
                <ng-container *ngIf="showInviteViaMailView">
                    <h2 class="invite-header no-margin-bottom">Invite Members Via Email</h2>
                    <h3 class="invite-sub-header">Invitation link sent can be used only once</h3>
                    <app-invite-member-via-email
                        (inviteSendComplete)="close()"
                        [communityName]="communityName"
                        [inivte_link_type]="inivte_link_type_paid"
                        [communityId]="user?.communityId"
                    ></app-invite-member-via-email>
                </ng-container>
                <ng-container *ngIf="showInviteViaWhatsAppView">
                    <h2 class="invite-header no-margin-bottom">Invite Members Via Whatsapp</h2>
                    <h3 class="invite-sub-header">Invitation link sent can be used only once</h3>
                    <app-invite-member-via-whatsapp
                        (inviteSendComplete)="close()"
                        [communityName]="communityName"
                        [inivte_link_type]="inivte_link_type_paid"
                        [communityId]="user?.communityId"
                    ></app-invite-member-via-whatsapp>
                </ng-container>
                <ng-container *ngIf="toggle1">
                    <div *ngIf="showUpgradeYourPlanModal">
                        <h2 class="invite-header">Upgrade Your Plan</h2>
                        <div class="invite-content-container no-plan">
                            <div class="no-plan-wrapper">
                                <div class="no-plan__image">
                                    <img src="assets/images/svg/upgradeAdd.svg" height="175px" alt="UpgradePlan" />
                                </div>
                                <div class="no-plan__content">
                                    You can invite a member with paid membership after you upgrade your plan. Get in touch with us at
                                    <a class="mail contact" href="mailto:hi@likeminds.community">hi@likeminds.community</a> to upgrade or
                                    click below.
                                </div>
                                <div class="share-btn-container no-plan_footer">
                                    <a class="share-btn no-plan_footer_btn mail" href="mailto:hi@likeminds.community"> Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="showAddMembershipPlanModal">
                        <h2 class="invite-header">Add Membership Plans</h2>
                        <div class="invite-content-container">
                            <div class="no-plan-wrapper">
                                <div class="no-plan__image">
                                    <img src="assets/images/svg/upgradeAdd.svg" height="175px" alt="AddMembershipPlan" />
                                </div>
                                <div class="no-plan__content">
                                    You have not created any membership plans yet. To invite members to join your community via paid
                                    invitation, Connect with the LikeMinds team(<a class="mail contact" href="mailto:hi@likeminds.community"
                                        >hi@likeminds.community</a
                                    >
                                    ).
                                </div>
                                <div class="share-btn-container no-plan_footer">
                                    <a class="share-btn no-plan_footer_btn mail" href="mailto:hi@likeminds.community"> Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="toggle2">
                    <ng-container *ngIf="!showInviteViaWhatsAppView && !showInviteViaMailView">
                        <h2 class="invite-header">Invite Paid Members</h2>
                        <div class="invite-content-container">
                            <div class="d-flex align-items-center invite-via_container" (click)="copyToClipBoard(1)">
                                <div class="invite-via_image">
                                    <img class="cursorPointer" src="assets/images/svg/copy.svg" alt="" />
                                </div>
                                <div class="invite-via">Copy invite link</div>
                            </div>
                        </div>
                        <div class="line line-margin"></div>
                        <div class="invite-content-container" (click)="showInviteViaEmail()">
                            <div class="d-flex align-items-center invite-via_container">
                                <div class="invite-via_image">
                                    <img src="assets/icons/mail-icon.png" />
                                </div>
                                <div class="invite-via">Invite via email</div>
                            </div>
                        </div>
                        <div class="line line-margin"></div>
                        <div class="invite-content-container" (click)="showInviteViaWhatsapp()">
                            <div class="d-flex invite-via_container align-items-center">
                                <div class="invite-via_image">
                                    <img height="34px" src="assets/images/svg/whatsapp-logo.svg" />
                                </div>
                                <div class="invite-via">Invite via whatsapp</div>
                            </div>
                        </div>
                        <div class="line line-margin"></div>
                        <p class="social-platforms">Send on social platforms</p>
                        <div class="d-flex align-items-center social-media-container">
                            <a [href]="getFacebookLink(1)" target="_blank" (click)="facebookClicked(inivte_link_type_paid)">
                                <img src="assets/images/svg/facebook.svg" height="36px" alt="fb" />
                            </a>
                            <a [href]="getLinkedInLink(1)" target="_blank" (click)="linkedInClicked(inivte_link_type_paid)">
                                <img src="assets/images/svg/linkedin.svg" height="36px" alt="linkedIn" />
                            </a>
                            <a [href]="getTwitterLink(1)" target="_blank" (click)="twitterClicked(inivte_link_type_paid)">
                                <img src="assets/images/svg/twitter.svg" height="36px" alt="twitter" />
                            </a>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="showFreeMemberToggleView">
                <ng-container *ngIf="showInviteViaMailView">
                    <h2 class="invite-header no-margin-bottom">Invite Members Via Email</h2>
                    <h3 class="invite-sub-header">Invitation link sent can be used only once</h3>
                    <app-invite-member-via-email
                        (inviteSendComplete)="close()"
                        [communityName]="communityName"
                        [inivte_link_type]="inivte_link_type_free"
                        [communityId]="user?.communityId"
                    ></app-invite-member-via-email>
                </ng-container>
                <ng-container *ngIf="showInviteViaWhatsAppView">
                    <h2 class="invite-header no-margin-bottom">Invite Members Via Whatsapp</h2>
                    <h3 class="invite-sub-header">Invitation link sent can be used only once</h3>
                    <app-invite-member-via-whatsapp
                        (inviteSendComplete)="close()"
                        [communityName]="communityName"
                        [inivte_link_type]="inivte_link_type_free"
                        [communityId]="user?.communityId"
                    ></app-invite-member-via-whatsapp>
                </ng-container>
                <ng-container *ngIf="toggle1">
                    <ng-container *ngIf="!showInviteViaWhatsAppView && !showInviteViaMailView">
                        <h2 class="invite-header">Invite Free Members</h2>
                        <div class="invite-content-container" (click)="showInviteViaEmail()">
                            <div class="d-flex align-items-center invite-via_container">
                                <div class="invite-via_image">
                                    <img src="assets/icons/mail-icon.png" />
                                </div>
                                <div class="invite-via">Invite via email</div>
                            </div>
                        </div>
                        <div class="line line-margin"></div>
                        <div class="invite-content-container" (click)="showInviteViaWhatsapp()">
                            <div class="d-flex invite-via_container align-items-center">
                                <div class="invite-via_image">
                                    <img height="34px" src="assets/images/svg/whatsapp-logo.svg" />
                                </div>
                                <div class="invite-via">Invite via whatsapp</div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="toggle2">
                    <ng-container *ngIf="!showInviteViaWhatsAppView && !showInviteViaMailView">
                        <h2 class="invite-header">Invite Free Members</h2>
                        <div class="invite-content-container">
                            <div class="d-flex align-items-center invite-via_container" (click)="copyToClipBoard(0)">
                                <div class="invite-via_image">
                                    <img class="cursorPointer" src="assets/images/svg/copy.svg" alt="" />
                                </div>
                                <div class="invite-via">Copy invite link</div>
                            </div>
                        </div>
                        <div class="line line-margin"></div>
                        <div class="invite-content-container" (click)="showInviteViaEmail()">
                            <div class="d-flex align-items-center invite-via_container">
                                <div class="invite-via_image">
                                    <img src="assets/icons/mail-icon.png" />
                                </div>
                                <div class="invite-via">Invite via email</div>
                            </div>
                        </div>
                        <div class="line line-margin"></div>
                        <div class="invite-content-container" (click)="showInviteViaWhatsapp()">
                            <div class="d-flex invite-via_container align-items-center">
                                <div class="invite-via_image">
                                    <img height="34px" src="assets/images/svg/whatsapp-logo.svg" />
                                </div>
                                <div class="invite-via">Invite via whatsapp</div>
                            </div>
                        </div>
                        <div class="line line-margin"></div>
                        <p class="social-platforms">Send on social platforms</p>
                        <div class="d-flex align-items-center social-media-container">
                            <a [href]="getFacebookLink(0)" target="_blank" (click)="facebookClicked(inivte_link_type_free)">
                                <img src="assets/images/svg/facebook.svg" height="36px" alt="fb" />
                            </a>
                            <a [href]="getLinkedInLink(0)" target="_blank" (click)="linkedInClicked(inivte_link_type_free)">
                                <img src="assets/images/svg/linkedin.svg" height="36px" alt="linkedIn" />
                            </a>
                            <a [href]="getTwitterLink(0)" target="_blank" (click)="twitterClicked(inivte_link_type_free)">
                                <img src="assets/images/svg/twitter.svg" height="36px" alt="twitter" />
                            </a>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="user['task'] === 'openDownloadAppPopup'">
    <section class="send-response-section w-100">
        <div class="text-center" *ngIf="isSheet">
            <img src="assets/images/svg/rectangle.svg" alt="" />
        </div>
        <div class="w-100 close">
            <img src="assets/images/svg/cross.svg" (click)="close()" class="float-end cursor-pointer" alt="Close" />
        </div>
        <div class="content mx-4 mb-5">
            <p class="heading fs24 fw-bold c0 mt-2">Your response</p>
        </div>

        <div class="joined-community-section">
            <p class="content fs13 my-3 px-3">
                You have joined the community and created your community profile. You should download the app to access all the chat rooms
                of this community.
            </p>
            <div class="download-links">
                <div class="appstore d-flex justify-content-start mt-4 ms-3" (click)="downloadApp('appstore')">
                    <img src="assets/images/svg/appstore-grey.svg" class="cursor-pointer" alt="AppStore" />
                    <p class="my-2 ms-2 fs13 fw500 cursor-pointer">DOWNLOAD iOS APP</p>
                </div>
                <div class="playstore d-flex justify-content-start mt-4 ms-3" (click)="downloadApp('playstore')">
                    <img src="assets/images/svg/playstore.svg" class="cursor-pointer" alt="PlayStore" />
                    <p class="my-2 ms-2 fs13 fw500 cursor-pointer">DOWNLOAD ANDROID APP</p>
                </div>
            </div>
        </div>
    </section>
</ng-container>

<ng-container *ngIf="user['task'] === 'openShareInfoPopup'">
    <section class="send-response-section w-100 p-3">
        <div *ngIf="user['data']['index'] === 1">
            <h3><b>Free invitation</b></h3>
            <h4>
                Members joining via these links would not need to make a payment before joining your community. Use this link to invite
                volunteers, mentors or team members who would always be on Free membership plan.
            </h4>
        </div>

        <div *ngIf="user['data']['index'] === 2">
            <h3><b>Payment required</b></h3>
            <h4>
                Members joining via these links would need to make a payment before joining your community. Use this link to invite new
                members who would need to renew their membership plan when it expires.
            </h4>
        </div>

        <div class="d-flex justify-content-end close-share-info-btn">
            <mat-dialog-actions mat-button mat-dialog-close>
                <div (click)="closeShareInfoPopup()" class="cursor-pointer">CONTINUE</div>
            </mat-dialog-actions>
        </div>
    </section>
</ng-container>
