import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

import { STORAGE_KEY } from '../../shared/enums/storage-keys.enum';
import { AUTH_SCREEN_TYPE, COMMMUNITY_OPENED } from '../../shared/constants/app-constant';
import { IOtpInfo } from '../../shared/models/auth.model';
import { AuthService } from '../../core/services/auth.service';
import { State } from '../../shared/store/reducers';
import { ClearRedirectUrl, StopLoading } from '../../shared/store/actions/app.action';
import { HomeFeedService } from '../../core/services/home-feed.service';
import { LocalStorageService } from '../../core/services/localstorage.service';
import { SetUserAction } from '../../shared/store/actions/auth.action';
import { CommunityService } from '../../core/services/community.service';
import { FEED } from '../../shared/constants/routes.constant';

@Component({
    selector: 'app-blocker',
    templateUrl: './blocker.component.html',
    styleUrls: ['./blocker.component.scss'],
})
export class BlockerComponent implements OnInit {
    private destroy$$ = new Subject();
    loggedInMemberUUID: string;
    loggedInMemberId: string;

    SCREEN_TYPE = AUTH_SCREEN_TYPE;
    page = this.SCREEN_TYPE.GENERATE_OTP;
    displayContent = false;
    blockerData;
    otpInfo: IOtpInfo;
    screenType: string;
    openRenewalMembershipComponent = false;
    removedMember;
    isRemovedMember = false;
    isRemovedMemberMobile = false;

    community: any = {};
    slides = [
        {
            img: 'assets/images/svg/intro_slide_1.svg',
            title: 'Welcome to LikeMinds',
            desc: 'Engage in conversations and build meaningful relationships in premium communities.',
        },
        {
            img: 'assets/images/png/tutorial-2.svg',
            title: 'Chat rooms',
            desc: 'Group chats around a particular topic, event or and opinion poll.',
        },
        {
            img: 'assets/images/png/tutorial-3.png',
            title: 'Community Feed',
            desc: 'Feed contains all the chat rooms of the community. Follow the relevant ones to track and participate in real time.',
        },
    ];

    constructor(
        private router: Router,
        private authService: AuthService,
        private store: Store<State>,
        private homeFeedService: HomeFeedService,
        private snackbar: MatSnackBar,
        private localStorageService: LocalStorageService,
        private communityService: CommunityService,
        private cookieService: CookieService
    ) {}

    ngOnInit(): void {
        this.store.dispatch(StopLoading());
        this.screenType = window.innerWidth <= 465 ? 'mobile' : window.innerWidth <= 768 ? 'tab' : 'desktop';

        this.fetchAppAccess();
        this.getUserStatue();
    }

    /**
     * @function getUserStatue
     * @param params
     * @description This function is used to get the user loggedin status
     */
    getUserStatue() {
        this.loggedInMemberUUID = this.localStorageService.getSavedState(STORAGE_KEY.LOGGED_IN_MEMBER_UUID);
        this.loggedInMemberId = this.localStorageService.getSavedState(STORAGE_KEY.LOGGED_IN_MEMBER_ID);

        if (this.loggedInMemberUUID) {
            // write some code here
            this.initiateUser(this.loggedInMemberUUID);
        } else if (this.loggedInMemberId) {
            this.getUUID();
        } else {
            this.onLogout();
        }
    }

    /**
     * @function getUUID
     * @param params
     * @description This function is used to get the uuid of the logged in user
     */
    getUUID() {
        this.authService.uuid().subscribe((resData) => {
            if (resData) this.initiateUser(resData?.user_meta?.uuid);
        });
    }

    /**
     * @function initiateUser
     * @param params
     * @description This function is used to initiate user call
     */

    initiateUser(userUniqueId: string, name?: string, isGuest?: boolean) {
        const initReqParams = {
            user_unique_id: userUniqueId,
        };
        this.authService.initiateUser(initReqParams).subscribe((resData) => {
            if (resData) {
                this.localStorageService.setSavedState(resData?.data?.access_token, STORAGE_KEY.ACCESS_TOKEN_LTM);
                this.localStorageService.setSavedState(resData?.data?.refresh_token, STORAGE_KEY.REFRESH_TOKEN_RTM);
                this.localStorageService.setSavedState(resData?.data?.user, STORAGE_KEY.LIKEMINDS_USER);
                localStorage.setItem(STORAGE_KEY.USER_EXIST, JSON.stringify({ access: resData?.data?.app_access }));
                localStorage.setItem(STORAGE_KEY.ACCESS, JSON.stringify({ access: resData?.data?.app_access }));

                this.cookieService.set(COMMMUNITY_OPENED, resData.data.community.id);

                this.getMemberState();

                if (resData?.data?.app_access) {
                    this.store.dispatch(SetUserAction({ payload: resData?.data?.user }));
                    if (resData?.data?.user) {
                        this.authService.setConfig();
                    }
                    this.store.dispatch(ClearRedirectUrl()); // Clear saved redirect url
                }
            }
        });
    }

    /**
     * @function getMemberState
     * @param params
     * @description This function is used to get the member state
     */
    getMemberState() {
        this.communityService.getMemberState().subscribe((resData) => {
            // console.log('Blocker ', resData);
            if (resData.data.state === 3) {
                // console.log('block');
            } else if (resData.data.state === 4 || resData.data.state === 1) {
                this.router.navigate([FEED]);
            } else {
                this.onLogout();
            }
        });
    }

    /**
     * @function onLogout
     * @param params
     * @description This function is used to logout user
     */
    onLogout() {
        this.homeFeedService.communityGroup$$.next([]);
        const loginType = JSON.parse(localStorage.getItem('login_type'));
        if (loginType?.google) this.authService.signOut();
        // console.log('blocker', loginType);
        this.authService
            .logout()
            .pipe(takeUntil(this.destroy$$))
            .subscribe(
                (res) => {
                    this.clearLocalData();
                },
                (err) => {
                    this.clearLocalData();
                }
            );
    }

    /**
     * @function clearLocalData
     * @param
     * @description This function is used to clear all local, session, cookies storage
     */
    clearLocalData() {
        localStorage.clear();
        sessionStorage.clear();
        this.cookieService.deleteAll();
        this.router.navigate(['auth']);
    }

    openRenewMembership() {
        this.authService.openRenewMembership$$.subscribe((res) => {
            if (res) this.openRenewalMembershipComponent = true;
        });
    }

    fetchAppAccess() {
        this.authService.fetchAppAccess().subscribe((res) => {
            localStorage.setItem(STORAGE_KEY.ACCESS, JSON.stringify({ access: res?.access }));
            if (res.access) {
                localStorage.setItem(STORAGE_KEY.ACCESS, JSON.stringify({ access: res?.access }));
                this.store.dispatch(StopLoading());
                // this.router.navigate([`${ROOT_PATH}`]);
            } else {
                let path = window.location.pathname;

                // if (path?.split('/')[2] == 'collabcard') {
                //     if (this.isNumeric(path.split('/')[3])) {
                //         this.router.navigate([`/${path.split('/')[1]}/${CHATROOM_PATH}/${path.split('/')[3]}`]);
                //     } else {
                //         this.router.navigate([`${PAGE_NOT_FOUND_PATH}`]);
                //     }
                //     this.store.dispatch(StopLoading());
                //     return;
                // }

                this.store.dispatch(StopLoading());
                this.blockerData = res;
                this.displayContent = true;
                this.checkIfMemberRemoved(this.blockerData);
            }
        });
    }

    setCommunity(community) {
        this.community = community;
    }

    openSnackbar(msg): void {
        this.snackbar.open(msg, null, {
            duration: 4000,
            panelClass: ['snackbar'],
        });
    }

    isNumeric(value) {
        return /^-?\d+$/.test(value);
    }

    checkIfMemberRemoved(blockerData) {
        this.homeFeedService.memberRemovedFromCommunityData$$.subscribe((result: any) => {
            this.removedMember = result?.community;
            if (Object.keys(result).length > 0) {
                if (!(blockerData?.pending_communities?.length || blockerData?.membership_expired_communities?.length)) {
                    this.isRemovedMember = true;
                    if (this.screenType == 'mobile') {
                        this.isRemovedMemberMobile = true;
                        this.isRemovedMember = false;
                    }
                } else {
                    this.openSnackbar('You have been removed from this community.');
                }
            } else {
                this.isRemovedMember = false;
                this.isRemovedMemberMobile = false;
            }
        });
    }

    closeRenewal(event) {
        this.openRenewalMembershipComponent = false;
    }

    openRenewal(event) {
        this.setCommunity(event);
        this.openRenewalMembershipComponent = true;
    }

    backToLogin() {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['auth']);
    }
}
