import { IUser } from './user.model';

/**
 * @class GenerateOtpModel
 * @description Used to create object for generate otp request
 */
export class GenerateOtpModel {
    otp_mode?: string;
    mobile_no: number;
    country_code: any;
    user_id?: number;
    is_retry?: number;
    constructor(otp_mode?: string, country_code?: any, mobile_no?: number, is_retry?: number, user_id?: number) {
        this.otp_mode = otp_mode;
        this.country_code = country_code;
        this.mobile_no = mobile_no;
        this.user_id = user_id;
        this.is_retry = is_retry;
    }
}

export class GenerateOtpEmailModel {
    otp_mode?: string;
    email_id: string;  
    is_retry?: number;
    constructor(otp_mode?: string, email_id?: string, is_retry?: number) {
        this.otp_mode = otp_mode; 
        this.email_id = email_id;  
        this.is_retry = is_retry;
    }
}

/**
 * @class GenerateOtpForMergeAccountModel
 * @description Used to create object for generate otp request
 */
export class GenerateOtpForMergeAccountModel {
    user_id: number;
    constructor(user_id: number) {
        this.user_id = user_id;
    }
}

/**
 * @interface IOtpInfo
 * @description Interface to define generate/verify otp related information
 */
export interface IOtpInfo {
    email?: string; 
    country_code: any;
    mobile_no: number;
    user_id?: number;
    success?: boolean;
    error_message?: string | null;
}

/**
 * @interface IGenerateOtpResponse
 * @description Interface to define generate otp success result
 */
export interface IGenerateOtpResponse {
    success: boolean;
    error_message: string | null;
}

/**
 * @class VerifyOtpModel
 * @description Used to create object for verify otp request
 */
export class VerifyOtpModel {
    otp_mode: string;
    otp: number | string;
    country_code: number;
    mobile_no: number;
    user_id?: number;
    constructor(otpMode: string, otp: number | string, country_code: number, mobile_no: number, user_id?: number) {
        this.otp_mode = otpMode;
        this.country_code = country_code;
        this.mobile_no = mobile_no;
        this.user_id = user_id;
        this.otp = otp;
    }
}
export class VerifyOtpEmailModel {
    otp_mode: string;
    email_id: string;
    otp: number | string; 
     
    constructor(otpMode: string, otp: number | string, email_id: string) {
        this.otp_mode = otpMode; 
        this.otp = otp;
        this.email_id = email_id; 
    }
}

export class VerifyOtpForMergeAccountModel {
    otp: number | string;
    user_id?: number;
    constructor(otp: number | string, user_id?: number) {
        this.user_id = user_id;
        this.otp = otp;
    }
}

/**
 * @interface IVerifyOtpResponse
 * @description Interface to define verify otp success result
 */
export interface IVerifyOtpResponse {
    success: boolean;
    error_message: string | null;
    profile_exists: boolean;
    access: boolean;
    user: IUser | null;
}

export interface IUrlParams {
    aj?: string | number;
    source?: any;
    source_analytics?: any;
    intro_source_analytics?: any;
    source_id?: any;
    shared_by?: any;
    utm_campaign?: any;
    utm_medium?: any;
    utm_source?: any;
    utm_content?: any;
    page?: string;
    source_community_id?: string;
    source_chatroom_id?: string;
    source_chatroom_type?: string;
    source_chatroom_name?: string;
    payment_id?: string;
}
