<div class='upgrade-plan-wrapper'>
    <div class="upgrade-plan-header"> 
        Upgrade your plan
    </div>
    <div class="upgrade-plan-content">
        You can invite a member with paid membership after you upgrade your plan. Get in touch with us at <a class="mail contact" href="mailto:hi@likeminds.community">hi@likeminds.community</a>  to upgrade or click below.
    </div>
    <div class="upgrade-plan-btn-wrapper">
        <div class="upgrade-plan-btn cancel_color" mat-dialog-close>Cancel</div>
        <div class="upgrade-plan-btn"><a class="mail" href="mailto:hi@likeminds.community">Contact Us</a></div>
    </div>
</div>