import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-add-role',
    templateUrl: './add-role.component.html',
    styleUrls: ['./add-role.component.scss'],
})
export class AddRoleComponent implements OnInit {
    branding: any;
    role: any;
    constructor(private dialogRef: MatDialogRef<any>) {}

    ngOnInit(): void {}

    onSubmit() {
        if (this.role) this.dialogRef.close(this.role);
    }

    close() {
        this.dialogRef.close();
    }
}
