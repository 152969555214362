import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registered-icon',
  templateUrl: './registered-icon.component.html',
  styleUrls: ['./registered-icon.component.scss']
})
export class RegisteredIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
