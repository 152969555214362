import { R } from '@angular/cdk/keycodes';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommunityService } from 'src/app/core/services/community.service';
import { ResizeService } from 'src/app/core/services/resize.service';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { PaymentModalDialogComponent } from 'src/app/shared/entryComponents/payment-modal-dialog/payment-modal-dialog.component';
import { Store, select } from '@ngrx/store';
import { State } from 'src/app/shared/store/reducers';
import { StartLoading, StopLoading } from 'src/app/shared/store/actions/app.action';
import { HomeFeedService } from 'src/app/core/services/home-feed.service';

@Component({
    selector: 'app-invite-only',
    templateUrl: './invite-only.component.html',
    styleUrls: ['./invite-only.component.scss'],
})
export class InviteOnlyComponent implements OnInit {
    showPaymentModal: boolean = false;
    screenType: string;
    @Inject(PLATFORM_ID) private platformId: object;
    @Input() community;
    @Input() blockerData;
    joiningCode: any = '';
    errorMessage: string = '';
    private destroy$$ = new Subject();

    constructor(
        private dialog: MatDialog,
        private resizeService: ResizeService,
        private subscriptionService: SubscriptionService,
        private communityService: CommunityService,
        private router: Router,
        private store: Store<State>,
        private homeFeedService: HomeFeedService
    ) {}

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.screenType = window.innerWidth <= 470 ? 'mobile' : window.innerWidth <= 768 ? 'tab' : 'desktop';
        }

        this.resizeService.onResize$.pipe(takeUntil(this.destroy$$)).subscribe((response) => {
            this.screenType = window.innerWidth <= 470 ? 'mobile' : window.innerWidth <= 768 ? 'tab' : 'desktop';
        });
    }

    submitCode() {
        this.joiningCode = this.joiningCode.trim();

        if (this.joiningCode === '') {
            this.errorMessage = 'Invalid invite code or already used!';
            return;
        }

        this.store.dispatch(StartLoading());

        this.subscriptionService.fetchCommunityMeta({ payment_id: this.joiningCode }).subscribe(
            (res) => {
                if (!res.success) {
                    this.communityService.postAjToGetCommunityIdAndSharedBy(`?aj=${this.joiningCode}`).subscribe(
                        (res) => {
                            if (res.success) {
                                this.store.dispatch(StopLoading());
                                this.router.navigate([`/community/${res?.community_id}`], {
                                    queryParams: { aj: this.joiningCode, shared_by: res?.shared_by },
                                });
                            }
                        },
                        (err) => {
                            this.subscriptionService.fetchCommunity({ community_id: this.joiningCode }).subscribe(
                                (res) => {
                                    if (res) {
                                        this.store.dispatch(StopLoading());
                                        this.router.navigate([`/community/${this.joiningCode}`]);
                                    } else {
                                        this.errorMessage = 'Invalid invite code or already used!';
                                    }
                                },
                                (err) => {
                                    this.store.dispatch(StopLoading());
                                    this.errorMessage = 'Invalid invite code or already used!';
                                }
                            );
                        }
                    );
                } else {
                    this.store.dispatch(StopLoading());
                    this.router.navigate([`/community/${res?.community_id}`], { queryParams: { payment_id: this.joiningCode } });
                }
            },
            (err) => {
                this.errorMessage = 'Something went wrong!';
            }
        );
    }

    openPaymentModal() {
        this.dialog.open(PaymentModalDialogComponent, {
            data: {
                showExpiredCommunityPopup: false,
                community: this.community,
            },
        });
    }
}
