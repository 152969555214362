<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g>
                    <g>
                        <g>
                            <path fill="#FFF" d="M0 0H24V24H0z"
                                transform="translate(-136.000000, -590.000000) translate(112.000000, 125.000000) translate(24.000000, 356.000000) translate(0.000000, 32.000000) translate(0.000000, 77.000000)" />
                            <g class="fill-brand" fill-rule="nonzero"
                                transform="translate(-136.000000, -590.000000) translate(112.000000, 125.000000) translate(24.000000, 356.000000) translate(0.000000, 32.000000) translate(0.000000, 77.000000) translate(1.000000, 1.000000)">
                                <circle cx="3.233" cy="16.776" r="2.829" />
                                <circle cx="18.767" cy="16.776" r="2.829" />
                                <circle cx="10.856" cy="3.239" r="2.829" />
                                <path
                                    d="M15.234 20.251c-.099-.202-.341-.287-.543-.188-1.163.56-2.416.848-3.718.848-1.971 0-3.87-.678-5.392-1.912.548-.58.884-1.36.884-2.218 0-1.783-1.45-3.233-3.232-3.233C1.45 13.548 0 14.998 0 16.781c0 1.782 1.45 3.232 3.233 3.232.624 0 1.212-.18 1.706-.49 1.688 1.424 3.816 2.2 6.034 2.2 1.428 0 2.797-.313 4.072-.929.202-.098.288-.34.189-.543zM.813 16.776c0-1.333 1.086-2.42 2.42-2.42 1.333 0 2.42 1.087 2.42 2.42 0 1.334-1.087 2.42-2.42 2.42-1.334 0-2.42-1.086-2.42-2.42zM1.62 11.918c-.008.225.167.413.396.422h.013c.22 0 .4-.175.409-.395.099-2.914 1.648-5.55 4.14-7.053.39-.234.799-.44 1.216-.611.436 1.27 1.644 2.191 3.058 2.191 1.782 0 3.232-1.45 3.232-3.233 0-1.782-1.45-3.232-3.232-3.232-1.783 0-3.233 1.45-3.233 3.232 0 .081.005.158.01.234-.508.197-1.007.44-1.473.723-2.73 1.643-4.427 4.53-4.535 7.722zM10.857.82c1.334 0 2.42 1.087 2.42 2.42 0 1.334-1.086 2.416-2.42 2.416-1.333 0-2.42-1.087-2.42-2.42 0-1.334 1.082-2.416 2.42-2.416zM18.767 13.543c-1.782 0-3.232 1.45-3.232 3.233 0 1.783 1.45 3.233 3.232 3.233 1.783 0 3.233-1.45 3.233-3.233 0-1.253-.718-2.344-1.764-2.878.09-.53.139-1.068.139-1.607 0-3.188-1.599-6.129-4.275-7.87-.188-.122-.44-.068-.56.12-.122.189-.068.44.12.562 2.447 1.589 3.907 4.274 3.907 7.188 0 .444-.036.889-.104 1.329-.224-.05-.458-.077-.696-.077zm2.42 3.233c0 1.334-1.086 2.42-2.42 2.42-1.333 0-2.42-1.086-2.42-2.42 0-1.333 1.087-2.42 2.42-2.42 1.334 0 2.42 1.087 2.42 2.42z" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>