export const GET_MEMBER_STATE = '[Community] Get Members State';
export const GET_MEMBER_STATE_SUCCESS = '[Community] Get Members State Success';
export const GET_MEMBER_STATE_FAILURE = '[Community] Get Members State Failure';

export const GET_QUESTIONS = '[Community] Get Questions';
export const GET_QUESTIONS_SUCCESS = '[Community] Get Questions Success';
export const GET_QUESTIONS_FAILURE = '[Community] Get Questions Failure';

export const JOIN_COMMUNITY = '[Community] Join Community';
export const JOIN_COMMUNITY_SUCCESS = '[Community] Join Community Success';
export const JOIN_COMMUNITY_FAILURE = '[Community] Join Community Failure';

export const SKIP_COMMUNITY = '[Community] Skip Community';
export const SKIP_COMMUNITY_SUCCESS = '[Community] Skip Community Success';
export const SKIP_COMMUNITY_FAILURE = '[Community] Skip Community Failure';

export const GET_COMMUNITY_ADMIN_LIST = '[Community] Get Community Admin List';
export const GET_COMMUNITY_ADMIN_LIST_SUCCESS = '[Community] Get Community Admin List Success';
export const GET_COMMUNITY_ADMIN_LIST_FAILURE = '[Community] Get Community Admin List Failure';

export const GET_ALL_MEMBERS = '[Community] Get All Members';
export const GET_ALL_MEMBERS_SUCCESS = '[Community] Get All Members Success';
export const GET_ALL_MEMBERS_FAILURE = '[Community] Get All Members Failure';

export const GET_INTRO_EXAMPLES = '[Community] Get Intro Examples';
export const GET_INTRO_EXAMPLES_SUCCESS = '[Community] Get Intro Examples Success';
export const GET_INTRO_EXAMPLES_FAILURE = '[Community] Get Intro Examples Failure';

export const GET_COMMUNITY_DETAILS = '[Community] Get Community Details';
export const GET_COMMUNITY_DETAILS_SUCCESS = '[Community] Get Community Details Success';
export const GET_COMMUNITY_DETAILS_FAILURE = '[Community] Get Community Details Failure';

export const GET_COMMUNITY_CHAT_ROOM_FEED = '[Community] Get Community Chat Room Feed';
export const GET_COMMUNITY_CHAT_ROOM_FEED_SUCCESS = '[Community] Get Community Chat Room Feed Success';
export const GET_COMMUNITY_CHAT_ROOM_FEED_FAILURE = '[Community] Get Community Chat Room Feed Failure';