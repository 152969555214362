<div class="lm-wrapper" *ngIf="formData.length > 0">
    <div class="wrapper-header">
        Edit social info

        <div class="close cursor-pointer" (click)="close()">
            <img src="../../../../assets/images/svg/ch-img/close.svg" alt="close" />
        </div>
    </div>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="wrapper-body" *ngFor="let question of formData; let i = index">
            <!-- Insta and Twitter -->
            <div
                class="form-group"
                *ngIf="
                    question.options[0].profile_platform === 'Instagram' || question.options[0].profile_platform === 'Twitter';
                    else typeURL
                "
            >
                <label for="question-{{ i }}">{{ question.question_title }}</label>
                <input
                    type="text"
                    [formControlName]="'question_' + i"
                    class="form-control"
                    id="question-{{ i }}"
                    [(ngModel)]="question.answer"
                    [placeholder]="question.options[0].profile_platform === 'Instagram' ? '@username' : '@handle'"
                />
                <div *ngIf="form.get('question_' + i).hasError('pattern')" class="error-message">Invalid URL format.</div>
            </div>
            <!-- Insta and Twitter -->

            <!-- Type URL -->
            <ng-template #typeURL>
                <div class="form-group">
                    <label for="question-{{ i }}">{{ question.question_title }}</label>
                    <input
                        type="text"
                        [formControlName]="'question_' + i"
                        class="form-control"
                        id="question-{{ i }}"
                        [(ngModel)]="question.answer"
                        placeholder="Type URL"
                    />
                    <div *ngIf="form.get('question_' + i).hasError('pattern')" class="error-message">Invalid URL format.</div>
                </div>
            </ng-template>
            <!-- Type URL -->
        </div>

        <div class="wrapper-footer">
            <button type="submit">Save</button>
        </div>
    </form>
</div>
