<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g>
                    <g>
                        <g>
                            <g>
                                <g transform="translate(-309 -257) translate(97 201) translate(15 42) translate(68) translate(129 14)">
                                    <circle cx="12" cy="12" r="12" class="fill-brand-light"/>
                                    <g fill="#FFF">
                                        <path fill-rule="nonzero" d="M11.618 1.95c-.181-.167-.396-.252-.643-.252h-.915v-.636c0-.292-.112-.542-.336-.75C9.5.104 9.231 0 8.917 0H8.46c-.315 0-.584.104-.808.312-.224.208-.336.458-.336.75v.636H4.573v-.636c0-.292-.112-.542-.336-.75C4.013.104 3.744 0 3.43 0h-.458c-.314 0-.583.104-.807.312-.224.208-.336.458-.336.75v.636H.915c-.248 0-.462.085-.643.253C.09 2.119 0 2.318 0 2.548v8.492c0 .23.09.429.272.597.18.168.395.252.643.252h10.06c.247 0 .462-.084.642-.252.181-.168.272-.367.272-.597V2.548c0-.23-.09-.43-.271-.597zM8.23 1.063c0-.062.021-.113.064-.153.043-.04.098-.06.164-.06h.458c.066 0 .121.02.164.06.043.04.064.09.064.153v1.91c0 .062-.021.113-.064.153-.043.04-.098.06-.164.06h-.458c-.066 0-.121-.02-.164-.06-.043-.04-.064-.09-.064-.153v-1.91zm-5.487 0c0-.062.021-.113.064-.153.043-.04.098-.06.164-.06h.458c.066 0 .121.02.164.06.043.04.064.09.064.153v1.91c0 .062-.021.113-.064.153-.043.04-.098.06-.164.06h-.458c-.066 0-.121-.02-.164-.06-.043-.04-.064-.09-.064-.153v-1.91zm8.23 9.978H.914V4.246h10.06v6.794z" transform="translate(6 6)"/>
                                        <path d="M7 7H9V9H7z" transform="translate(6 6)"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
