import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chatroom-preview-click-icon',
  templateUrl: './chatroom-preivew-click-icon.component.html',
  styleUrls: ['./chatroom-preivew-click-icon.component.scss']
})
export class ChatroomPreivewClickIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
