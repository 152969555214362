<div class="popup-wrapper">
    <div class="popup-wrapper__header">Pin this chatroom?</div>
    <div class="popup-wrapper__body">Are you sure you want to pin this chat room for all community members?</div>
    <div class="popup-wrapper__post-body">
        <input type="checkbox" [checked]="notifyAll" /> 
        <label (click)="toggleValue()">Notify all members</label>
    </div>
    <div class="popup-wrapper__footer">
        <div class="popup-wrapper__footer__cancel" (click)="cancel()">NOT NOW</div>
        <div class="popup-wrapper__footer__confirm" (click)="confirm()">CONFIRM</div>
    </div>
</div>