<section class="popup">

  <div class="cross-div">
    <span class="close-icon"><img src="../../../../../assets/images/svg/cross.svg" mat-dialog-close /></span>
  </div>

  <div class="heading">
    <p class="text-heading">{{data.title}}</p>
  </div>

  <div class="body">
    <p class="text-body">{{data.desc}}</p>
    <p class="text-body">In the meantime, you can see our <a href="/terms">terms</a> & <a href="/privacy">privacy policies.</a></p>
  </div>

  <div class="button-div">
    <button mat-dialog-close>OK</button>
  </div>
</section>
