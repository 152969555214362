import { ChangeDetectorRef } from '@angular/core';
// Login.ts

import { Component, OnInit, ViewChild, EventEmitter, ElementRef, Output, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { map, takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';

import { SetHeaderAction, StartLoading, StopLoading } from '../../../../shared/store/actions/app.action';
import { getOtpInfoSelector, getCommunityIdSelector, getUrlParamsSelector } from '../../../../shared/store/selectors/auth.selector';
import { getHeader, getRedirectUrl } from '../../../../shared/store/selectors/app.selector';
import { getAuthSelector } from '../../../../shared/store/selectors/auth.selector';
import { BaseHeaderData } from '../../../../shared/models/header.model';
import { HEADER_TYPE } from '../../../../shared/enums/header-type.enum';
import { StoreService } from '../../../../core/services/store.service';
import { Payload } from '../../../../shared/models/app.model';
import { GenerateOtpForMergeAccountAction } from '../../../../shared/store/actions/auth.action';
import { MergeAccountConfirmationComponent } from '../entryComponents/merge-account-confirmation/merge-account-confirmation.component';
import { IUser } from '../../../../shared/models/user.model';
import { IOtpInfo, GenerateOtpForMergeAccountModel, IUrlParams } from '../../../../shared/models/auth.model';
import { VerifyMergeAccountComponent } from '../entryComponents/verify-merge-account/verify-merge-account.component';
import { CanComponentDeactivate } from '../../../../shared/guards/leave-page.guard';
import { LeavePageComponent } from '../../../../shared/entryComponents/leave-page/leave-page.component';
import { COMMUNITY_QUESTION_PATH, BLOCKER, PAGE_NOT_FOUND_PATH } from '../../../../shared/constants/routes.constant';
import { MIXPANEL } from '../../../../shared/enums/mixpanel.enum';
import { AuthState } from '../../../../shared/store/reducers/auth.reducer';
import { CommunityService } from '../../../../core/services/community.service';
import { STORAGE_KEY } from '../../../../shared/enums/storage-keys.enum';
import { AnalyticsService } from '../../../../core/services/analytics.service';
import { AuthService } from '../../../../core/services/auth.service';
import { ChoiceDialogData } from '../../../models/choice.model';
import { ChoiceSheetComponent } from '../../../entryComponents/choice-sheet/choice-sheet.component';
import { ChoiceDialogComponent } from '../../../entryComponents/choice-dialog/choice-dialog.component';
import { LocalStorageService } from '../../../../core/services/localstorage.service';
import { HintModalComponent } from '../../../../shared/entryComponents/hint-modal/hint-modal.component';
import { HomeFeedService } from '../../../../core/services/home-feed.service';
import { AddRoleComponent } from '../entryComponents/add-role/add-role.component';
import { ProfileService } from '../../../../core/services/profile.service';
import { AwsS3BucketService } from '../../../../core/services/aws-s3-bucket.service';

interface Question {
    noWhitespaceValidator: any;
    pattern: any;
    can_add_options: boolean;
    community_id: number;
    field: boolean;
    help_text: string;
    id: number;
    is_answer_editable: boolean;
    is_compulsory: boolean;
    is_hidden: boolean;
    optional: boolean;
    question_title: string;
    rank: number;
    state: number;
    tag: string | null;
    value: string | null;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, CanComponentDeactivate {
    private destroy$$ = new Subject();
    header: BaseHeaderData;
    subscriptions: Subscription[] = [];
    formSubmitted = false;
    formEdited = false;
    headerData: BaseHeaderData;
    form: FormGroup;
    formAbout: FormGroup;
    communityInfo: any;
    user: IUser;
    otpInfo: IOtpInfo;
    imageUrl: string;
    isSocialLogin: boolean;
    socialLoginType: string;
    authState: AuthState;
    urlParams: IUrlParams;
    lnUserDataObj: any;
    stepOne: boolean = true;
    stepTwo: boolean = false;
    proQuestion: any;
    filteredQuestions: any[];
    filteredQuestionsAbout: any[];
    introduction: any;
    branding: any;
    valueforselect = 'rishteydaar bc';
    // Define an array of designation options
    designationOptions: any = [];
    showDesignationField: boolean = false;
    @Output() linkedInUserData = new EventEmitter();
    @ViewChild('emailInput') emailInput: ElementRef;
    constructor(
        private formBuilder: FormBuilder,
        private store: Store,
        private storeService: StoreService,
        private router: Router,
        private storage: AngularFireStorage,
        private dialog: MatDialog,
        private analyticsService: AnalyticsService,
        private communityService: CommunityService,
        private authService: AuthService,
        private sheet: MatBottomSheet,
        private localStorageService: LocalStorageService,
        private snackbar: MatSnackBar,
        private homeFeedService: HomeFeedService,
        private cookieService: CookieService,
        private cdr: ChangeDetectorRef,
        private profileService: ProfileService
    ) {}

    ngOnInit(): void {
        this.getOtpInfo();
        this.getCommunityInfo();
        this.getApp();
        this.store.pipe(select(getUrlParamsSelector)).subscribe((response) => (this.urlParams = response));
        this.profileQuestions();
        this.getIntroductionSamples();
        this.communityService.currentCommunityBranding$$.pipe(takeUntil(this.destroy$$)).subscribe((branding) => {
            this.branding = branding;
        });

        this.buildForm();
    }

    @HostListener('window:beforeunload', ['$event'])
    saveFormData(event: Event): void {
        // Save your form data to local storage here
        localStorage.setItem(STORAGE_KEY.FORM_DATA_START, JSON.stringify({ newRegStart: true }));
        const formData = this.form.value;
        localStorage.setItem(STORAGE_KEY.FORM_DATA, JSON.stringify(formData));
    }

    /**
     * @function createForm
     * @description This function is used to create forms
     */
    buildForm(): void {
        this.form = this.formBuilder.group({
            user: this.formBuilder.group({
                image_url: [null, { validators: Validators.required, updateOn: 'blur' }],
                name: [null, { validators: [Validators.required, Validators.pattern(/([A-Za-z0-9_.]+\s?)/g)], updateOn: 'blur' }],
                org: [null, { validators: Validators.required, updateOn: 'blur' }],
                designation: [null, { validators: Validators.required, updateOn: 'blur' }],
                about: [''],
            }),
        });

        // Load form data from local storage if available
        const savedFormData = localStorage.getItem(STORAGE_KEY.FORM_DATA);

        if (savedFormData) {
            // console.log('Form Data', savedFormData);
            if (savedFormData) {
                const userGroup = this.form.get('user');
                const gdata = JSON.parse(savedFormData).user;
                console.log(gdata);
                if (gdata.image_url) this.imageUrl = gdata.image_url;
                userGroup?.get('name')?.setValue(gdata.name);
                userGroup?.get('designation')?.setValue(gdata.designation);
                userGroup?.get('org')?.setValue(gdata.org);
                userGroup?.get('image_url')?.setValue(gdata.image_url);
                // userGroup?.get('about')?.setValue(gdata.about);
                // console.log(userGroup);

                // Update validity and trigger change detection
                userGroup?.get('name')?.updateValueAndValidity();
                userGroup?.get('designation')?.updateValueAndValidity();
                userGroup?.get('org')?.updateValueAndValidity();
                userGroup?.get('image_url')?.updateValueAndValidity();

                this.cdr.detectChanges();
            }
        }
        // else if (gmailUserData) {
        //     console.log('saved gmail data', gmailUserData);
        //     if (gmailUserData) {
        //         const userGroup = this.form.get('user') as FormGroup;
        //         if (gmailUserData.user.image_url) this.imageUrl = gmailUserData.user.image_url;
        //         userGroup.get('name')?.setValue(gmailUserData.user.name);
        //         userGroup?.get('image_url')?.setValue(gmailUserData.user.image_url);
        //     }
        // }
    }

    getStrokeColour() {
        return this.branding?.advanced?.buttons_icons_colour || this.branding?.basic?.primary_colour || '#00CAB3';
    }

    getApp(): void {
        this.store.pipe(select(getAuthSelector)).subscribe((authState: AuthState) => {
            this.authState = authState;
        });
    }

    /**
     * @function goTo
     * @description This function is used to go next step
     */
    goTo(step: string) {
        if (step === 'stepTwo') {
            if (!this.imageUrl || !this.form.value.user.name || !this.form.value.user.org || !this.form.value.user.designation) {
                this.formSubmitted = true;
                this.snackbar.open('Please add mandatory field(s)', 'Close', {
                    duration: 5000,
                    panelClass: ['error-alert-snackbar'],
                    horizontalPosition: 'right',
                });
                return;
            } else {
                this.formSubmitted = false;
                this.stepOne = step !== 'stepTwo';
                this.stepTwo = !this.stepOne;
            }
        }
    }

    /**
     * @function objIsNotNull
     * @description This function is used to objIsNotNull
     */
    objIsNotNull(obj: any) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
                    return false;
                }
            }
        }
        return true;
    }

    /**
     * @function profileQuestions
     * @description This function is used to get profileQuestions
     */
    profileQuestions(): void {
        this.authService.getProfileQuestions().subscribe(
            (resData) => {
                this.proQuestion = resData;
                const filteredQuestions = this.filterQuestionsStepOne(this.proQuestion.data.questions);
                this.filteredQuestions = filteredQuestions.sort((a, b) => a.rank - b.rank);

                // Step one
                if (this.filteredQuestions) {
                    // Loop through the filtered questions and dynamically add form controls
                    this.filteredQuestions.forEach((question) => {
                        if (question.question_title === 'Your Name') {
                            const userGroup = this.form.get('user') as FormGroup;
                            userGroup.addControl(
                                'name',
                                this.formBuilder.control(null, [Validators.required, Validators.pattern(/([A-Za-z0-9_.]+\s?)/g)])
                            );
                        } else if (
                            question.question_title === 'Organization/ Community Name' ||
                            question.question_title === 'Organisation/Community Name'
                        ) {
                            const userGroup = this.form.get('user') as FormGroup;
                            userGroup.addControl(
                                'org',
                                this.formBuilder.control(null, [Validators.required, Validators.pattern(/([A-Za-z0-9_.]+\s?)/g)])
                            );
                        } else if (question.question_title === 'Designation/Role') {
                            const userGroup = this.form.get('user') as FormGroup;
                            userGroup.addControl(
                                'designation',
                                this.formBuilder.control(null, [Validators.required, Validators.pattern(/([A-Za-z0-9_.]+\s?)/g)])
                            );
                        }
                    });

                    const designationQuestion = this.filteredQuestions.find((q) => q.question_title === 'Designation/Role');

                    if (designationQuestion && designationQuestion.value) {
                        this.designationOptions = JSON.parse(designationQuestion.value);
                        this.designationOptions.push({ value: 'Other' });
                    }

                    this.showDesignationField = this.filteredQuestions.some((q) => q.question_title === 'Designation/Role');
                }

                this.filteredQuestionsAbout = this.filterQuestionsStepOne(this.proQuestion.data.questions, 'about');
                // Step two
                if (this.filteredQuestionsAbout) {
                    // Loop through the filtered questions and dynamically add form controls
                    this.filteredQuestionsAbout.forEach((question) => {
                        if (question.question_title === 'About You') {
                            const userGroup = this.form.get('user') as FormGroup;
                            userGroup.addControl(
                                'name',
                                this.formBuilder.control(null, [Validators.required, Validators.pattern(/([A-Za-z0-9_.]+\s?)/g)])
                            );
                        }
                    });
                }
            },
            (err) => {
                console.log(err);
                this.router.navigateByUrl('/auth');
            }
        );
    }

    getQuestionId(title: string, titleOptions?: string) {
        let titleMappings;
        if (titleOptions) {
            titleMappings = {
                'Your Name': 'Your Name',
                'Organization/ Community Name': ['Organization/ Community Name', 'Organisation/Community Name'],
                'Organisation/Community Name': ['Organization/ Community Name', 'Organisation/Community Name'],
                'Designation/Role': 'Designation/Role',
                'About You': 'About You',
            };

            for (const question of this.filteredQuestions) {
                if (titleMappings[titleOptions] instanceof Array) {
                    if (titleMappings[titleOptions].includes(question.question_title)) {
                        return question.id;
                    }
                } else {
                    if (question.question_title === titleMappings[titleOptions]) {
                        return question.id;
                    }
                }
            }

            // Return a default value or handle cases where the title doesn't match
            return null;
        } else {
            titleMappings = {
                'Your Name': 'Your Name',
                'Organization/ Community Name': 'Organization/ Community Name',
                'Designation/Role': 'Designation/Role',
                'About You': 'About You',
            };

            for (const question of this.filteredQuestions) {
                if (titleMappings[title] instanceof Array) {
                    if (titleMappings[title].includes(question.question_title)) {
                        return question.id;
                    }
                } else {
                    if (question.question_title === titleMappings[title]) {
                        return question.id;
                    }
                }
            }

            // Return a default value or handle cases where the title doesn't match
            return null;
        }
    }

    getAboutQuestionId(title: string) {
        const titleMappings = {
            'About You': 'About You',
        };
        for (const question of this.filteredQuestionsAbout) {
            if (titleMappings[title] instanceof Array) {
                if (titleMappings[title].includes(question.question_title)) {
                    return question.id;
                }
            } else {
                if (question.question_title === titleMappings[title]) {
                    return question.id;
                }
            }
        }

        // Return a default value or handle cases where the title doesn't match
        return null;
    }

    /**
     * @function onDesignationChange
     * @description This function is used to filter the questions
     */
    // Method to handle MatSelect changes
    onDesignationChange(event): void {
        const userGroup = this.form.get('user') as FormGroup;
        if (event.target.value === 'Other') {
            this.roleModel();
        } else {
            userGroup.get('designation').setValue(event.target.value);
        }
    }

    roleModel() {
        const dialogRef = this.dialog.open(AddRoleComponent, {
            panelClass: 'hint-modal',
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                const userGroup = this.form.get('user') as FormGroup;
                const length = this.designationOptions.length;
                this.designationOptions[length - 1] = { value: res };
                this.designationOptions.push({ value: 'Other' });
                userGroup?.get('designation')?.setValue(res);
            }
        });
    }

    /**
     * @function filterQuestionsStepOne
     * @description This function is used to filter the questions
     */
    filterQuestionsStepOne(questions: Question[], type?: string): Question[] {
        if (type === 'about') {
            return questions.filter((question) => {
                return question.optional && question.tag === 'about' && question.state === 7;
            });
        } else {
            return questions.filter((question) => {
                return !question.optional && question.tag === 'basic' && question.state !== 7;
            });
        }
    }

    calculatePercentage(aboutText: string | null): number {
        if (!aboutText) {
            return 0;
        }
        return Math.min((aboutText.length / 50) * 100, 100);
    }

    /**
     * @function getHeader
     * @description This function is used to get header for login page
     */
    getHeader(): void {
        this.subscriptions.push(
            this.store.pipe(select(getHeader, { type: HEADER_TYPE.LOGIN })).subscribe((header) => {
                this.header = header;
                this.store.dispatch(SetHeaderAction({ payload: header }));
            })
        );
    }

    /**
     * @function getOtpInfo
     * @description This function is used to get otp info
     */
    getOtpInfo(): void {
        this.subscriptions.push(
            this.store.pipe(select(getOtpInfoSelector)).subscribe((response) => {
                this.otpInfo = response;
            })
        );
    }

    /**
     * @function getCommunityInfo
     * @description This function fetches community info from store
     */
    getCommunityInfo(): void {
        this.subscriptions.push(this.store.pipe(select(getCommunityIdSelector)).subscribe((response) => (this.communityInfo = response)));
    }

    /**
     * @function uploadImage
     * @description This function is used to upload image
     */
    uploadImage() {
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';

        input.onchange = (e: any) => {
            const file = e.target.files[0];
            const auth = localStorage.getItem(STORAGE_KEY.AUTH) && JSON.parse(localStorage.getItem(STORAGE_KEY.AUTH));

            this.profileService
                .uploadUserImage(auth?.otpInfo?.mobile_no || auth?.otpInfo?.email_id || 'gmail', file)
                .then((downloadURL: string) => {
                    this.form.get('user')?.get('image_url')?.setValue(downloadURL);
                    this.imageUrl = downloadURL;
                });
        };
        input.click();
    }

    showAddPhotoPopup(): void {
        const data: ChoiceDialogData = {
            title: 'Add Profile Pic',
            subTitle: 'LikeMinds is a real identity network. Confirm your real identity by adding a profile photo.',
            choices: ['Ok'],
        };
        if (window.innerWidth <= 470) {
            const sheetRef = this.sheet.open(ChoiceSheetComponent, {
                data,
            });
        } else {
            const dialogRef = this.dialog.open(ChoiceDialogComponent, {
                panelClass: 'attend-event-modal',
                data,
            });
        }
    }

    /**
     * @function getIntroductionSamples
     * @description This function is used to get getIntroductionSamples
     */
    getIntroductionSamples(): void {
        this.authService
            .introductionSamples()
            .pipe(takeUntil(this.destroy$$))
            .subscribe((resData) => {
                this.introduction = resData.data;
            });
    }

    openHint() {
        const dialogRef = this.dialog.open(HintModalComponent, {
            data: {
                introduction: this.introduction,
            },
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe();
    }

    /**
     * @function submitLoginForm
     * @description This form is used to login
     */
    submitLoginForm(event: any): void {
        // console.log('login');
        const idOfButton = event.target.id;
        if (idOfButton !== 'skip') {
            if (this.form?.value?.user?.about?.length < 50) {
                this.snackbar.open(`Please enter minimum ${50 - this.form?.value?.user?.about?.length} more characters.`, 'Close', {
                    duration: 5000,
                    panelClass: ['error-alert-snackbar'],
                    horizontalPosition: 'right',
                });
                return;
            }
        } else {
            const userGroup = this.form.get('user');
            userGroup?.get('about')?.setValue('');
        }

        // console.log('login');

        this.formSubmitted = true;
        // console.log(this.form);
        // console.log(this.form?.value);
        // console.log(this.form.valid);
        // console.log(this.imageUrl);

        if (!this.form.valid) return;
        if (!this.imageUrl) {
            this.showAddPhotoPopup();
            return;
        }

        // this.store.dispatch(StartLoading());
        this.form.value.user['image_url'] = this.imageUrl;

        let urlPattern = JSON.parse(localStorage.getItem(STORAGE_KEY.URL_PATTERN));
        let user_acquisition_url = window.location.href;
        if (urlPattern !== null && urlPattern?.fullUrl) {
            user_acquisition_url = urlPattern?.fullUrl;
        }

        const params = {
            user: {
                name: this.form.value.user.name,
                image_url: this.form.value.user.image_url,
            },
            question_answers: [
                {
                    question_id: this.getQuestionId('Your Name'),
                    answer: this.form.value.user.name,
                },
                {
                    question_id: this.getQuestionId('Organization/ Community Name', 'Organisation/Community Name'),
                    answer: this.form.value.user.org,
                },
                {
                    question_id: this.getQuestionId('Designation/Role'),
                    answer: this.form.value.user.designation,
                },
                {
                    question_id: this.getAboutQuestionId('About You'),
                    answer: this.form.value.user.about,
                },
            ],
            shared_by: sessionStorage.getItem('shared_by'),
        };

        // console.log(params);

        this.authService
            .initiateUser(params)
            .pipe(takeUntil(this.destroy$$))
            .subscribe((resData) => {
                this.store.dispatch(StopLoading());
                if (resData) {
                    const {
                        data: { access_token, refresh_token },
                    } = resData;
                    this.localStorageService.setSavedState(resData.data.access_token, STORAGE_KEY.ACCESS_TOKEN_LTM);
                    this.localStorageService.setSavedState(resData.data.refresh_token, STORAGE_KEY.REFRESH_TOKEN_RTM);
                    this.localStorageService.setSavedState(resData?.data?.user?.uuid, STORAGE_KEY.LOGGED_IN_MEMBER_UUID);

                    const communityId = resData?.data?.community?.id;
                    sessionStorage.removeItem('shared_by');
                    // localStorage.removeItem(STORAGE_KEY.FORM_DATA);
                    localStorage.removeItem(STORAGE_KEY.GMAIL_USER_DATA);
                    this.navigateAfterLoginSuccess(resData?.data?.community?.id, resData?.data?.user?.uuid, resData?.data?.app_access);
                }
            });
    }

    generateOtp(): void {
        this.subscriptions.push(
            this.storeService
                .waitForEffectSuccess(GenerateOtpForMergeAccountAction(new Payload(new GenerateOtpForMergeAccountModel(this.user.id))))
                .subscribe()
        );
    }

    openMergeAccountConfirmation() {
        const dialog = this.dialog.open(MergeAccountConfirmationComponent, {
            data: this.user.mobiles,
            panelClass: 'merge-account-modal',
            disableClose: true,
        });

        dialog.afterClosed().subscribe((response) => {
            if (response === 'RESET') {
                this.form.get('user').patchValue({ email: null });
                this.emailInput.nativeElement.focus();
                this.analyticsService.sendEvent(MIXPANEL.ENTERED_NEW_EMAIL, {
                    distinct_id: this.user && this.user.id,
                });
            } else {
                this.generateOtp();
                this.openVerifyMergeAccount();
            }
        });
    }

    openVerifyMergeAccount() {
        const data = {
            user: this.user,
            otpInfo: this.otpInfo,
        };
        const dialog = this.dialog.open(VerifyMergeAccountComponent, {
            data,
            panelClass: 'verify-otp-modal',
            disableClose: true,
        });
        dialog.afterClosed().subscribe((response) => {
            if (response) {
                this.formEdited = false;
                this.navigateAfterLoginSuccess(this.communityInfo.communityId, this.user.id, response?.access);
            }
        });
    }

    /**
     * @function formControls
     * @description Getter function to get form controls
     */
    get formControls() {
        return this.form.controls;
    }

    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (this.formEdited) {
            const data = {
                heading: 'Leave without account creation?',
                content:
                    'Are you sure, you want to move away from this screen? Your login details with your mobile number would be lost and you would have to re-do the same.',
                successBtnText: 'Leave',
                cancelBtnText: 'Stay',
            };
            return this.dialog
                .open(LeavePageComponent, {
                    data,
                    panelClass: 'leave-page-modal',
                    disableClose: true,
                })
                .afterClosed()
                .pipe(map((res) => res));
        } else return true;
    }

    isNumeric(value) {
        return /^-?\d+$/.test(value);
    }

    // To match urlPath to /collabcard/<number>
    checkUrlPathExactMatch(urlPath: string): boolean {
        if (!urlPath) return false;
        const splitValues = urlPath.split('/');
        return splitValues[2] === 'collabcard' && !isNaN(+splitValues[3]) && !splitValues[4];
    }

    navigateAfterLoginSuccess(community_id, member_id, access?: boolean) {
        if (!access) {
            let urlPattern = JSON.parse(localStorage.getItem(STORAGE_KEY.URL_PATTERN));

            if (urlPattern !== null && Object.keys(urlPattern)?.length !== 0) {
                let path = urlPattern.path;
                let qparams = urlPattern.queryParams;
                if (path.includes('/renewal/') && qparams.renew === 'true') {
                    this.router.navigate([`${path}`], { queryParams: qparams });
                    return;
                }
                // paid event page
                if (urlPattern.path === '/event_pay') {
                    this.router.navigate([`${urlPattern.path}`], { queryParams: urlPattern.queryParams });
                } else if (this.checkUrlPathExactMatch(urlPattern?.path)) {
                    this.router.navigate([`${urlPattern.path}`], { queryParams: urlPattern.queryParams });
                }

                // community path
                if (path.split('/')[1] === 'community') {
                    if (this.isNumeric(path.split('/')[2])) {
                        this.store.dispatch(StartLoading());
                        if (qparams.payment_id || qparams.shared_by || qparams.aj) {
                            this.router.navigate([`${COMMUNITY_QUESTION_PATH}` + '/' + path.split('/')[2]], { queryParams: qparams });
                            return;
                        } else {
                            this.store.dispatch(StartLoading());
                            this.router.navigate([`${PAGE_NOT_FOUND_PATH}`]);
                            return;
                        }
                    } else {
                        this.router.navigate([`${PAGE_NOT_FOUND_PATH}`]);
                        return;
                    }
                } else {
                    this.router.navigate(['/']);
                }
                this.store.dispatch(StopLoading());
                return;
            } else {
                this.router.navigate([`/${BLOCKER}`]);
                return;
            }
        } else if (JSON.parse(localStorage.getItem(STORAGE_KEY.LOGIN_LINK))) {
            localStorage.removeItem(STORAGE_KEY.LOGIN_LINK);
            this.store.pipe(select(getRedirectUrl)).subscribe((url) => {
                this.router.navigateByUrl(url);
            });
        } else {
            if (!community_id) {
                this.router.navigate([`/`]);
            } else {
                this.getMemberState();
            }
        }
    }

    getMemberState() {
        // console.log(0);
        this.communityService
            .getMemberState()
            .pipe(takeUntil(this.destroy$$))
            .subscribe((resData) => {
                if (resData.data.state === 3) {
                    this.router.navigate(['/blocker']);
                } else if (resData.data.state === 4 || resData.data.state === 1) {
                    this.router.navigate([`/feed`]);
                } else {
                    // Logout the user by clearing locatStorage, cookies and call logout api
                    this.onLogout();
                }
            });
    }

    /**
     * @function onLogout
     * @param params
     * @description This function is used to logout user
     */
    onLogout() {
        this.homeFeedService.communityGroup$$.next([]);
        const loginType = JSON.parse(localStorage.getItem('login_type'));
        if (loginType?.google) this.authService.signOut();
        this.authService
            .logout()
            .pipe(takeUntil(this.destroy$$))
            .subscribe(
                (res) => {
                    this.clearLocalData();
                },
                (err) => {
                    this.clearLocalData();
                }
            );
    }

    /**
     * @function clearLocalData
     * @param
     * @description This function is used to clear all local, session, cookies storage
     */
    clearLocalData() {
        localStorage.clear();
        sessionStorage.clear();
        this.cookieService.deleteAll();
        this.router.navigate(['auth']);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());

        // Save the form data to local storage when the component is destroyed
        localStorage.setItem('formData', JSON.stringify(this.form.value));
    }
}
