<section>
    <main>
        <form [formGroup]="eventRoomForm" (ngSubmit)="onCreateEventRoom()">
            <div class="mb-4">
                <div class="text-area d-flex flex-column" [ngClass]="{ 'is-invalid': submitted && formObject.eventTitle.errors }">
                    <textarea class="form-control" formControlName="eventTitle" placeholder="Enter event title*" rows="1"></textarea>

                    <div class="d-flex justify-content-between text-muted">
                        <!-- Secretc: Chatroom Type -->
                        <ng-container *ngIf="is_cm">
                            <div class="crType me-1 suffix align-self-end" (click)="secretChatroomDialog()">
                                <span class="me-1">
                                    <img
                                        src="../../../../../assets/images/svg/open.svg"
                                        alt="Icon"
                                        *ngIf="crType === 'open'; else lockIcon"
                                        width="18px"
                                        height="16px"
                                    />
                                    <ng-template #lockIcon>
                                        <img src="../../../../../assets/images/svg/secret_lock.svg" alt="Icon" />
                                    </ng-template>
                                </span>
                                <span class="me-1">
                                    {{ crType }}
                                </span>
                                <img src="assets/images/svg/secret_down_arrow.svg" alt="down arrow" />
                            </div>
                        </ng-container>
                        <!-- Secretc: Chatroom Type -->

                        <div>&nbsp;</div>
                        <div class="mt-2">
                            <span *ngIf="eventRoomForm.value.eventTitle?.length < 10; else counts"
                                >Min {{ 10 - eventRoomForm.value.eventTitle?.length }} characters</span
                            >
                            <ng-template #counts>
                                <span>{{ eventRoomForm.value.eventTitle?.length }} characters</span>
                            </ng-template>
                            <circle-progress
                                class="circle"
                                [percent]="(eventRoomForm.value.eventTitle?.length || 0) * 10"
                                [radius]="4"
                                [outerStrokeWidth]="2"
                                [innerStrokeWidth]="2"
                                [space]="-2"
                                [innerStrokeColor]="getStrokeColour()"
                                [outerStrokeColor]="getStrokeColour()"
                                [titleFontSize]="2"
                                [unitsFontSize]="2"
                                [showTitle]="false"
                                [showUnits]="false"
                                [showSubtitle]="false"
                                [animation]="true"
                                [animationDuration]="300"
                                [startFromZero]="false"
                                [responsive]="true"
                            >
                            </circle-progress>
                        </div>
                    </div>
                </div>
                <div *ngIf="submitted && formObject.eventTitle.errors" class="invalid-feedback text-start">
                    <div *ngIf="formObject.eventTitle.errors.required">Please enter event title</div>
                </div>
            </div>
            <div class="mb-4">
                <div class="text-area d-flex" [ngClass]="{ 'is-invalid': submitted && formObject.startDate.errors }">
                    <div><img src="assets/images/svg/calendar.svg" alt="calendar" class="calendarIcon" /></div>

                    <div class="dateTime d-flex flex-column">
                        <mat-form-field floatLabel="never">
                            <input
                                readonly
                                matInput
                                [ngxMatDatetimePicker]="pickerFrom"
                                placeholder="Start date & time*"
                                (click)="selectStartDate('startRef')"
                                formControlName="startDate"
                                [min]="minDate"
                            />
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                            <ngx-mat-datetime-picker
                                (opened)="selectStartDate(startRef)"
                                (closed)="selectStartDate(startRef)"
                                #startRef
                                #pickerFrom
                                [enableMeridian]="true"
                            >
                            </ngx-mat-datetime-picker>
                        </mat-form-field>

                        <div class="border-0">
                            <mat-form-field floatLabel="never">
                                <input
                                    readonly
                                    matInput
                                    [ngxMatDatetimePicker]="pickerTo"
                                    placeholder="End date & time*"
                                    formControlName="endDate"
                                    [min]="minEndDate"
                                />
                                <mat-datepicker-toggle matSuffix [for]="pickerTo" (click)="selectEndDate('ref')"> </mat-datepicker-toggle>
                                <ngx-mat-datetime-picker
                                    (opened)="selectEndDate(ref)"
                                    (closed)="selectEndDate(ref)"
                                    #ref
                                    #pickerTo
                                    [enableMeridian]="true"
                                ></ngx-mat-datetime-picker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div *ngIf="submitted && formObject.startDate.errors" class="invalid-feedback text-start">
                    <div *ngIf="formObject.startDate.errors.required">Please enter start, end date and time</div>
                </div>
            </div>
            <div class="mb-4">
                <div class="text-area d-flex justify-content-between">
                    <div><img src="assets/images/svg/description.svg" alt="about event" /></div>
                    <textarea rows="1" class="form-control ps-2" formControlName="aboutEvent" placeholder="About event"></textarea>
                </div>
            </div>
            <div class="mb-4">
                <div class="text-area d-flex justify-content-between">
                    <div><img src="assets/images/svg/co-hosts.svg" alt="Co-Host" /></div>
                    <div class="hostBox">
                        <ng-container *ngIf="selectedMembers.length > 0">
                            <div *ngFor="let member of selectedMembers; let i = index" class="selectedMember">
                                {{ member.name }}
                                <img
                                    src="../../../../../assets/images/svg/green-cross.svg"
                                    alt="remove"
                                    (click)="removeMember(i)"
                                    class="remove"
                                />
                            </div>
                        </ng-container>
                        <input type="text" placeholder="Co-hosts" matInput formControlName="coHost" [matAutocomplete]="auto" />
                    </div>
                </div>
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let member of memberList" [value]="member.name">
                        <div class="member" (click)="select(member)">
                            <div class="imgBox">
                                <img
                                    [src]="member.image_url"
                                    alt="profile"
                                    onerror="this.src='../../../../../assets/images/svg/profile-img-bg-gray.svg';"
                                    *ngIf="member.image_url; else placeholder"
                                />
                                <ng-template #placeholder>
                                    <img src="../../../../../assets/images/svg/profile-img-bg-gray.svg" alt="profile" />
                                </ng-template>
                            </div>
                            <div class="name">{{ member.name }}</div>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </div>
            <div class="mb-4">
                <div class="text-area d-flex justify-content-between">
                    <div>
                        Make this an online event
                        <img
                            src="assets/images/svg/info.svg"
                            alt="informations"
                            class="ms-2"
                            *ngIf="onLineUrlInput"
                            (click)="onlineUrlGuide()"
                        />
                    </div>
                    <mat-slide-toggle formControlName="eventType" (click)="onLocationSelect()"></mat-slide-toggle>
                </div>
                <div class="text-area d-flex location">
                    <img src="assets/images/svg/location.svg" alt="about event" />
                    <div class="w-100 locationInputBox">
                        <ng-container *ngIf="offLineInput; else onlineLinkInput">
                            <a href="javascript:void(0)" class="addLocationLable ms-2" (click)="offLine = true" *ngIf="!offLine"
                                >Add location*</a
                            >
                            <location-offline
                                class="ps-2"
                                placeholder="Add location*"
                                *ngIf="offLine"
                                (setFieldValue)="setFieldValue(i, $event)"
                                (geometry)="geometry($event)"
                            >
                            </location-offline>
                        </ng-container>

                        <ng-template #onlineLinkInput>
                            <input type="text" class="form-control ps-2" formControlName="onlineLink" placeholder="Add URL*" />
                        </ng-template>
                    </div>
                </div>
            </div>
            <!-- <div class="mb-4">
                <div class="text-area d-flex justify-content-between">
                    <div>
                        Make this a public event
                        <img src="assets/images/svg/info.svg" alt="infomation" class="ms-2" (click)="openPublicEvent()" />
                    </div>
                    <mat-slide-toggle></mat-slide-toggle>
                </div>
            </div> -->

            <div class="mb-4">
                <div class="text-area d-flex justify-content-between">
                    <div><img src="assets/images/svg/banner.svg" alt="about event" /></div>
                    <div class="d-flex bannerBlock justify-content-between">
                        <label class="addLocationLable ms-2">
                            Add banners
                            <input
                                multiple
                                #imageFileUpload
                                type="file"
                                accept="image/*"
                                (change)="uploadFiles($event, 'image')"
                                (click)="imageFileUpload.value = ''"
                            />
                        </label>

                        <img src="assets/images/svg/info.svg" alt="informations" class="ms-2" (click)="bannerGuide()" />
                    </div>
                </div>
                <div class="selectBanner" *ngIf="fileToBeUpload.length > 0">
                    <div class="imgBox" *ngFor="let item of fileToBeUpload; let i = index">
                        <img [src]="item.blob" alt="banner" class="bannerImg" />
                        <img
                            src="../../../../../assets/images/svg/cross-img.svg"
                            alt="remove image"
                            class="removeIcon"
                            width="32"
                            height="32"
                            (click)="removeImg(i)"
                        />
                    </div>
                </div>
            </div>

            <div class="mb-3 d-flex justify-content-center">
                <button type="button" class="btn btn-lm-round" (click)="onCreateEventRoom()" [disabled]="!eventRoomForm.valid">
                    <img src="assets/images/svg/completed.svg" alt="back arrow" />
                </button>
            </div>
        </form>
    </main>
</section>

<!-- Secret chatroom participants list -->
<ng-container *ngIf="isSecretCR">
    <div class="memberList">
        <!-- participant header -->
        <div class="memberList__header" *ngIf="!searchHeader; else showSearchHeader">
            <div class="d-flex justify-content-between">
                <div class="d-flex">
                    <div class="arrowBox mt-2" (click)="back()">
                        <img
                            [src]="
                                !branding?.advanced?.header_colour
                                    ? '../../../../../assets/images/svg/secret_search_back_icon.svg'
                                    : '../../../../../assets/images/svg/secret_right_arrow.svg'
                            "
                            alt="icon"
                            class="arrow"
                        />
                    </div>
                    <div>
                        <div class="title">Add Participants</div>
                        <div class="subTitle">{{ selectedMemberList.length || 0 }} Selected</div>
                    </div>
                </div>
                <!-- <div (click)="searchBtn()">
                    <img src="../../../../../assets/images/svg/secret_search_icon.svg" alt="icon" class="searchArea" />
                </div> -->
            </div>
        </div>
        <!-- participant header -->

        <ng-template #showSearchHeader>
            <div class="memberList__header search">
                <div class="d-flex justify-content-between">
                    <div class="d-flex">
                        <div class="arrowBox mt-2" (click)="searchBtn()">
                            <img
                                [src]="
                                    !branding?.advanced?.header_colour
                                        ? '../../../../../assets/images/svg/secret_search_back_icon.svg'
                                        : '../../../../../assets/images/svg/secret_right_arrow.svg'
                                "
                                alt="icon"
                                class="arrow"
                            />
                        </div>
                        <div>
                            <input type="text" placeholder="search..." />
                        </div>
                    </div>
                    <div>
                        <img
                            src="../../../../../assets/images/svg/secret_search_icon.svg"
                            alt="icon"
                            height="18px"
                            width="18px"
                            class="mt-2"
                        />
                    </div>
                </div>
            </div>
        </ng-template>
        <!-- participant search header -->

        <!-- Participant list -->
        <div
            class="memberList__body"
            infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            (scrolled)="onScroll()"
            [scrollWindow]="false"
            *ngIf="memberList"
        >
            <!-- <div class="memberList__body--totleMembers">{{ addMemberList?.total_only_members }} members</div> -->
            <div class="memberList__body--totleMembers">{{ memberListObj.length - 1 }} members</div>

            <ng-container *ngFor="let member of memberListObj">
                <div class="memberList__body__box" (click)="selectMember(member.id)" *ngIf="user.id != member.id">
                    <div class="memberList__body__box--userImg">
                        <img [src]="member.image_url" alt="user" onerror="this.src='../../../../../assets/images/png/avatar.png'" />
                        <div [ngClass]="selectedClass(member.id)"></div>
                    </div>
                    <div class="memberList__body__box__content">
                        <div class="memberList__body__box__content--userName">{{ member.name }}</div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="memberList__footer" *ngIf="selectedMemberList.length > 0">
            <button type="button" (click)="onCreateSecretChatroom()">
                <img src="assets/images/svg/right_arror_btn.svg" alt="back arrow" />
            </button>
        </div>
    </div>
</ng-container>
