<div class="hint-container">
    <div class="d-block text-center mb-2 show-sm">
        <img src="assets/images/svg/rectangle.svg" />
    </div>
    <div class="d-block hide-sm">
        <img (click)="closeModal()" class="position-absolute cursor-pointer" style="top: -8px; right: -8px;" src="assets/images/svg/cross.svg" />
    </div>
    <div class="heading text-center">{{ selectedIntro?.header || header }}</div>
    <div class="sub-heading mb-2">
        {{ selectedIntro?.sub_header || subHeader }}
    </div>

    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li
                data-target="#carouselExampleIndicators"
                [ngClass]="{ active: i === 0 }"
                data-slide-to="{{ i }}"
                *ngFor="let intro of introExamples; let i = index"
            ></li>
        </ol>

        <div class="carousel-inner">
            <div class="hint" (swipe)="onSwipe($event)">
                <ng-container *ngFor="let intro of introExamples; let i = index">
                    <div class="carousel-item" [ngClass]="{ active: selectedIndex === i }">
                        <p class="hint-heading fs14 fw500">{{ intro?.title }}</p>
                        <read-more [classes]="'hint-content'" [characterCount]="30" [data]="intro?.sub_title"> </read-more>
                    </div>
                </ng-container>
            </div>
        </div>

        <!-- href="#carouselExampleIndicators" href="#carouselExampleIndicators" -->
        <a class="carousel-control-prev" #prevButton (click)="changeSelectedIndex('previous')" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon-old carousel-control-icon-box" aria-hidden="true">
                <img class="cursor-pointer" src="assets/images/svg/ch-img/hint-prev.svg" alt="prev" />
            </span>
            <!-- <span class="sr-only">Previous</span> -->
        </a>
        <a class="carousel-control-next" #nextButton (click)="changeSelectedIndex('next')" role="button" data-slide="next">
            <span class="carousel-control-next-icon-old carousel-control-icon-box" aria-hidden="true">
                <img class="cursor-pointer" src="assets/images/svg/ch-img/hint-next.svg" alt="next" />
            </span>
            <!-- <span class="sr-only">Next</span> -->
        </a>
    </div>

    <div class="navigation-detail text-center my-3">
        <!-- <p class="fs14 c6">{{ selectedIndex + 1 }} of {{ introExamples.length }}</p> -->
        <p class="next-example fs14 fw500 show-sm" (click)="next()">See another example</p>
    </div>
</div>
