<div class="eventContent"> 
    <div class="close"><img class="cursor=pointer" (click)="dialogRef.close()"
        src="assets/images/svg/cross.svg" alt="cancel"></div>
    <div class="eventContent__header mt-3">
        <h2>Allow notifications</h2>
        <p class="eventContent--subHeading">Allow notifications to keep track of all updates</p>
    </div>
    <div class="btn-box d-flex">
        <button class="btn btn-link text-custom" (click)="sure('false')">Not now</button>
        <button class="btn btn-link text-success" (click)="sure('true')">Sure</button>
    </div>
</div>