<ng-container>
    <label class="fs14 c6 float-start ms-2">{{ question?.question_title }} </label>
    <mat-form-field class="simple-input phone-number w-100 mb-4"
        [ngClass]="{ 'invalid': control.errors && formSubmitted }" floatLabel="never">
        <input matInput numberOnly [placeholder]="question?.help_text" maxlength="10" [type]="inputType"
            pattern="[0-9]*" [formControl]="control" name="mobile-no" autocomplete="off" [value]="question?.value" />
    </mat-form-field>
    <div class="invalid-feedback question-form-error ms-2 d-block text-start" *ngIf="control.errors && formSubmitted">
        This is a mandatory field
    </div>
</ng-container>