<div *ngIf="status !== 6 && !is_paying">
    <div *ngIf="status === 4" id="header">
        <nav class="navbar navbar-light bg-white mt-md-3">
          <div class="container-fluid">
            <a class="navbar-brand d-flex align-items-center mx-auto ms-md-5 text-center" href="#">
              <img
                [src]="community.image_url"
                alt="logo"
                height="32px"
                width="32px"
                class="d-inline-block rounded align-text-top object-fit-cover"
              />
              <div class="d-block d-md-inline-block px-2 fw-bold">
                {{community.name}}
              </div>
            </a>
          </div>
          <hr
            class="d-none d-md-block hr mx-auto"
          />
        </nav>
    </div>
    <div *ngIf="status === 0 || status === 3" class="h-100 bg-white wrapper">
        <img src="https://wallpaperaccess.com/full/846241.jpg" class="d-lg-block d-none image-bg" alt="">
        <div *ngIf="!is_paying" id="header" class="border-bottom z-index-100" >
            <nav class="navbar navbar-light bg-white py-lg-3">
              <div class="container-fluid">
                <a class="navbar-brand d-flex align-items-center mx-auto ms-lg-5 text-center" href="#">
                  <img
                    [src]="community.image_url"
                    alt="logo"
                    height="32px"
                    width="32px"
                    class="d-inline-block rounded align-text-top object-fit-cover"
                  />
                  <div class="d-block d-lg-inline-block px-2 fw-bold">
                    {{community.name}}
                  </div>
                </a>
              </div>
            </nav>
        </div>
        
          <div id="main h-100">
            <div class="container">
              <div *ngIf="!is_paying" class="row py-5 payment-row">
                <div  class="col-11 col-lg-4 px-5">
                    <div class="my-4 row">
                        <div class="col-12">
                            <span class="h5"><b>{{page.title}}</b></span>
                            <hr class="text-primary page-title" />
                        </div>
                        <div [innerHTML]="page.description" class="col-12 py-3">
                        </div>
                    </div>
                    <div class="row fw-bold mt-0 mt-md-5 color-gray">
                      <div class="col-12 mt-1 mt-md-5 mb-1">
                          <h5>Contact Us:</h5>
                      </div>
                    </div>  
                    <div *ngIf="page.contact_email" class="mb-3 row fw-bold color-gray">
                      <span class="col-1 col-form-label">
                        <img src="assets/images/svg/envelope-solid.svg" height="16px" width="16px" alt="">
                      </span>
                      <div class="col-11 my-auto">
                        <span>{{page.contact_email}}</span>
                      </div>
                    </div>
                    <div *ngIf="page.contact_mobile_no" class="mb-3 row fw-bold color-gray">
                      <span class="col-1 col-form-label">
                        <img src="assets/images/svg/phone-alt-solid.svg" height="16px" width="16px" alt="">
                      </span>
                      <div class="col-11 my-auto">
                        <span>{{page.contact_mobile_no}}</span>
                      </div>
                    </div>
                    <div class=" payment-button bg-white d-lg-none mt-30-vh">
                      <button (click)="toggle_paying()" class="btn btn-block text-small text-white w-100 btn-primary">
                        PROCEED TO PAY
                    </button>
                    </div>
                  </div>
                  <div class="col-12 col-xl-4 col-lg-5 mt-5 mt-md-0 d-none d-lg-block shadow-lg rounded payment-details">
                  <div class="my-md-4 mt-4 mb-0">
                  <div class="col-12 p-3">
                      <span class="ps-4 ps-md-0 px-2"><b>Payment Details</b></span>
                      <hr
                        class="d-md-none mx-auto hr-2"
                      />
                  </div>
                  </div>
                  <div class="pb-3 pt-1 row">
                    <label for="name" class="col-10 offset-1 col-md-3 col-form-label ms-lg-3">Name</label>
                    <div class="col-lg-7 col-10 col-md-6 offset-1">
                      <input type="text" [value]="data.name" (change)="change_data('name', $event.target.value)" class="form-control" id="name" />
                    </div>
                  </div>
                  <div class="py-3 row">
                    <label for="email" class="col-10 offset-1 col-md-3 col-form-label ms-lg-3">Email</label>
                    <div class="col-lg-7 col-10 col-md-6 offset-1">
                      <input type="email" [value]="data.email" (change)="change_data('email', $event.target.value)" class="form-control" id="email" />
                    </div>
                  </div>
                  <div class="py-3 row">
                    <label for="phone" class="col-10 offset-1 col-md-3 col-form-label ms-lg-3">Phone</label>
                    <div class="col-lg-7 col-10 col-md-6 offset-1">
                      <input type="tel" [value]="data.phone" (change)="change_data('phone', $event.target.value)" class="form-control" id="phone" />
                    </div>
                  </div>
                  <div *ngIf="page.amount_type === 'fixed'" class="py-3 mb-md-4 row">
                    <label for="amount" class="col-10 offset-1 col-md-3 col-form-label ms-lg-3">Amount(₹)</label>
                    <div class="col-lg-7 col-10 col-md-6 offset-1">
                      <input type="text" [value]="data.amount" class="form-control" id="amount" readonly />
                    </div>
                  </div>
                  <div *ngIf="page.amount_type === 'customers'" class="py-3 mb-md-4 row">
                    <label for="amount2" class="col-10 offset-1 col-md-3 col-form-label ms-lg-3">Amount(₹)</label>
                    <div class="col-lg-7 col-10 col-md-6 offset-1">
                      <input type="number" [value]="data.amount" (keyup)="change_data('amount', $event.target.value)" class="form-control" id="amount2" />
                    </div>
                  </div>
                  <div class="row d-none d-md-flex position-relative">
                      <div for="amount" class="col-7 bg-gray px-3 py-3">
                        <img src="https://cdn.razorpay.com/static/assets/pay_methods_branding.png" alt="payment-methods" height="10" width="180" 
                    class="d-inline-block align-text-top mt-7px"/>
                      </div>
                      <a (click)="createOrder()" class="rounded-0 col-5 text-white py-3 text-center bg-primary btn">
                          Pay  {{data.amount ? '₹' + data.amount : ''}}
                      </a>
                      <img src="assets/images/png/powered-by-lm.png" class="powered-by-lm">
                  </div>
              </div>
             
              </div>
            </div>
        </div>
    </div>
  
    <div class="wrapper" *ngIf="status === 1 || status === 2 || status === 4">
        
        <header *ngIf="status !== 4" class="header w-100 bg-theme1 text-center">
            <ng-container>
                <div class="header__likeminds-logo" href="">
                    <img class="logo cursor-pointer" src="assets/images/svg/logo-with-name.svg" alt="LikeMindsWeb" />
                </div>
        
                <div class="header__details-section">
                    <img
                        class="cursor-pointer header__details-section__logo"
                        src="assets/images/svg/logo.svg"
                        alt="logo"
                    />
                </div>
        
                <div class="header__download-link-section">
                    <span class="cF fs14">Try mobile apps: </span>
                    <img class="cursor-pointer mx-3" src="assets/images/svg/playstore.svg" alt="PlayStore" (click)="downloadApp('playstore')" />
                    <img class="cursor-pointer" src="assets/images/svg/appstore.svg" alt="AppStore" (click)="downloadApp('appstore')" />
                </div>
            </ng-container>
        </header>
        
        <div class="flex-column d-flex justify-content-center align-items-center body__section">
    
                <div class="d-flex flex-column justify-content-center align-content-between text-center body__section-inner">
                    <div class="body__container">
                        <div *ngIf="status === 1" class="tick">
    
                        </div>
                        <img *ngIf="status === 4" height="256px"  src="assets/images/png/expired.png" alt="tick">
                        <h4 *ngIf="status === 1" class="body_heading_paymentId">PAYMENT ID {{payment_id}}</h4>
                        <h1 *ngIf="status === 1" class="body_heading_renew"><b>Payment Successfully <br>Received</b></h1>
                        <h1 *ngIf="status === 4" class="body_heading_renew"><b>Uh Oh. This payment link has expired.</b></h1>
                        <h1 *ngIf="status === 2" class="body_heading_renew"><b>Payment Failed</b></h1>
                        <br>
                       <div class="d-flex justify-content-center">
                        <h3 *ngIf="status === 1" class="explore__heading col-lg-8 col-xl-6">
                          {{page.custom_success_message}}
                       </h3>
                       </div>
                        <h3 *ngIf="status === 4" class="explore__heading">
                            Please use active link you might have got or ask for the right link from the peer you received this link from.
                         </h3>
                         <h3 *ngIf="page.redirect_url" class="explore__heading mt-5">
                            Redirecting in 00:0{{redirect}}
                        </h3>
                        <!-- <p class="invoice__para">Print Invoice</p> -->
                    </div>
                </div>
                <div *ngIf="status !== 4" class="mail_holder">
                    <p class="mail_para">For further queries, contact us at <br> <a class="mail" href="mailto:hello@likeminds.community">hello@likeminds.community</a></p>
                </div>
        
        </div>
    </div>
    
</div>
<div *ngIf="is_paying" class="col-12 d-block d-lg-none payment-details-mobile">
  <div class="mb-4">
  <div class="row p-4 align-items-center border-bottom">
      <a (click)="toggle_paying()" class="col-1 curson-pointer"><img src="assets/images/svg/back-navigation-arrow.svg" height="24px" width="24px" alt=""></a>
      <span class="ps-4 ps-md-0 h6 px-2 col-11 mb-0">Payment Details</span>
  </div>
  </div>
  <div class="pb-3 pt-1 row">
    
    <div class="col-lg-12 col-10 offset-1">
      <label for="name" class="col-12">Name</label>
      <input type="text" [value]="data.name" (change)="change_data('name', $event.target.value)" class="form-control" id="name" />
    </div>
  </div>
  <div class="py-3 row">
    <label for="email" class="col-10 offset-1 col-md-3 col-form-label ms-lg-3">Email</label>
    <div class="col-lg-7 col-10 col-md-6 offset-1">
      <input type="email" [value]="data.email" (change)="change_data('email', $event.target.value)" class="form-control" id="email" />
    </div>
  </div>
  <div class="py-3 row">
    <label for="phone" class="col-10 offset-1 col-md-3 col-form-label ms-lg-3">Phone</label>
    <div class="col-lg-7 col-10 col-md-6 offset-1">
      <input type="tel" [value]="data.phone" (change)="change_data('phone', $event.target.value)" class="form-control" id="phone" />
    </div>
  </div>
  <div *ngIf="page.amount_type === 'fixed'" class="py-3 mb-md-4 row">
    <label for="amount" class="col-10 offset-1 col-md-3 col-form-label ms-lg-3">Amount(₹)</label>
    <div class="col-lg-7 col-10 col-md-6 offset-1">
      <input type="text" [value]="data.amount" class="form-control" id="amount" readonly />
    </div>
  </div>
  <div *ngIf="page.amount_type === 'customers'" class="py-3 mb-md-4 row">
    <label for="amount2" class="col-10 offset-1 col-md-3 col-form-label ms-lg-3">Amount(₹)</label>
    <div class="col-lg-7 col-10 col-md-6 offset-1">
      <input type="number" [value]="data.amount" (keyup)="change_data('amount', $event.target.value)" class="form-control" id="amount2" />
    </div>
  </div>
  <div class="row">
      <div for="amount" class="col-10 offset-1 py-3">
        <img src="assets/images/png/secure-payment.png" alt="payment-methods" height="48px" width="100%" 
    class="d-inline-block align-text-top secure-payment mt-3" />
      </div>
      <a (click)="createOrder()" class=" col-10 offset-1 btn-block text-white py-2 mt-5 text-center bg-primary btn">
          Pay  {{data.amount ? '₹' + data.amount : ''}}
      </a>
  </div>
</div>