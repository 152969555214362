<!-- <app-payment-modal *ngIf="showPaymentModal"></app-payment-modal> -->

<!-- ============================== NEW CODE FROM HERE ============================== -->

<div class="login-form d-flex flex-column px-5" *ngIf="blockerData as data">
    <div class="form-content d-flex flex-column justify-content-center align-items-center">
        <div class="invite-only-wrap d-flex flex-column w-100 h-100 align-items-center">
            <div class="logo">
                <img src="../../../../assets/images/svg/ch-img/chlog.svg" class="mw-100 mh-100" />
            </div>
            <div class="padlock">
                <img src="../../../../assets/images/svg/ch-img/blocker-icon.svg" class="mw-100 mh-100" />
            </div>
            <h3 class="title">You are on the waiting list! *</h3>

            <div class="form-holder text-center">
                <!-- JOIN COMMUNITY HOLDER -->
                <div class="form-holder__join-community">
                    <p class="form-holder__join-community__subpara">
                        Your application to join CommunityHood has been submitted. You will have access to community posts, events, chat
                        rooms and member profiles as soon as you are approved.
                    </p>
                </div>
            </div>
            <p class="bottom-mail-to c6" [innerHTML]="data.footer | splitString"></p>
        </div>
    </div>
</div>
