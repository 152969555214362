import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from 'src/app/core/services/subscription.service';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { STORAGE_KEY } from 'src/app/shared/enums/storage-keys.enum';
import { IUser } from 'src/app/shared/models/user.model';
import { CommunityService } from 'src/app/core/services/community.service';
import { Store} from '@ngrx/store';
import { State } from 'src/app/shared/store/reducers';
import { AUTH_PATH, ROOT_PATH } from 'src/app/shared/constants/routes.constant';
import { AuthService } from 'src/app/core/services/auth.service';
import { StartLoading, StopLoading } from 'src/app/shared/store/actions/app.action';
import { RENEWAL_FLOW } from 'src/app/shared/enums/mixpanel.enum';
import { AnalyticsService } from 'src/app/core/services/analytics.service';

declare var Razorpay : any;

@Component({
  selector: 'app-renewal-membership',
  templateUrl: './renewal-membership.component.html',
  styleUrls: ['./renewal-membership.component.scss']
})
export class RenewalMembershipComponent implements OnInit {

  public showSuccessPage$$ = new BehaviorSubject<boolean>(false);
  showSuccessPage : boolean = false;
  communityId = "";
  user : IUser ;
  res = {}
  plans : any = [];
  communityImageUrl : string;
  communityName : string;
  communityWebsiteUrl : string;
  showContent = false;
  @Input() community ;
  @Output() closeRenewalContent = new EventEmitter();
  screenType : string;

  constructor(
    private route: ActivatedRoute , 
    private subscriptionService : SubscriptionService,
    private router : Router,
    private localStorageService : LocalStorageService,
    private communityService : CommunityService,
    private authService : AuthService,
    private store: Store<State>,
    private analyticsService: AnalyticsService
    ) {}

    ngOnInit(): void {  
      this.store.dispatch(StartLoading())
      this.user =  this.localStorageService.getSavedState(STORAGE_KEY.LIKEMINDS_USER);
      this.screenType = window.innerWidth <= 480 ? 'mobile' : (window.innerWidth <= 768 ? 'tab' : 'desktop');
      this.communityId =this.community.id;
        this.subscriptionService.fetchCommunity({ community_id : this.communityId }).subscribe(communityObj=>{                     
          if(communityObj.community.is_paid){
            this.communityName = communityObj.community.name;
            this.communityImageUrl = communityObj.community.image_url;

            //CAPTURE ANALYTICS
            this.captureAnalytics(this.communityId , this.communityName);

              this.subscriptionService.fetchSubscriptionPlans(this.communityId).subscribe(
                res=>{                  
                this.plans = res["plans"]
                this.store.dispatch(StopLoading());
                this.showContent = true;                
                },
                err =>{      
                  this.store.dispatch(StopLoading())            
                  this.router.navigate(['/404'])         
                }        
              )
          }
          else{
            this.store.dispatch(StopLoading())
            this.router.navigate(['/404'])                   
          }
        });
      this.store.dispatch(StopLoading());
    }

  storeUrl(){
    this.route.queryParams.subscribe((res) => {
      this.localStorageService.setSavedState({'path': location.pathname, queryParams: res} , STORAGE_KEY.URL_PATTERN);
    })
  }

  captureAnalytics(communityId , communityName){
    this.analyticsService.sendEvent(
      RENEWAL_FLOW.SUBSCRIPTION_PLAN_PAGE_OPEN ,{
          community_id : communityId,
          community_name : communityName,
          page_type : "renewal"
      }
    )
  }

  isObjectOrNot(val): boolean { return typeof val === 'object'; }

  createOrder(index , planId , cost, duration_name , duration_in_months) : void {
    
    const body={
      "plan_id": planId,
      "payment_page_url": window.location.href,
      "renew" : "true" ,
      "user_id" : this.localStorageService.getSavedState(STORAGE_KEY.LIKEMINDS_USER).id
    }

    this.analyticsService.sendEvent(
      RENEWAL_FLOW.BUY_NOW_CLICKED ,{
          community_id : this.communityId,
          community_name : this.communityName,
          plan_duration : duration_in_months,
          plan_name : duration_name,
          plan_cost : cost
      }
    )

    this.subscriptionService.createRazorPayOrder(body).subscribe(res=>{
      let order_id = res['order']["order_id"];
      res['order']['handler'] = (response)=> {
        let data = {
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id : response.razorpay_payment_id,
          razorpay_signature : response.razorpay_signature,
          order_id : order_id
        }

        this.subscriptionService.verifyOrder(data).subscribe(res=>{
          if(res.success){

            this.analyticsService.sendEvent(
              RENEWAL_FLOW.PAYMENT_SUCCESSFUL ,{
                  community_id : this.communityId,
                  plan_duration : duration_in_months,
                  plan_name : duration_name,
                  plan_cost : cost,
                  payment_page_url : window.location.href,
                  payment_type : "renewal"
              }
            )

            this.communityService.getCommunityFeedUrl(this.communityId).subscribe(res=>{
              if(res.success){
                this.localStorageService.setSavedState(res.feed_url, "feed_url");
                this.localStorageService.setSavedState( {'payment_id' : response.razorpay_payment_id , 'community_id' : this.communityId , "community_name" : this.communityName} , STORAGE_KEY.PAYMENT_ID_SUCCESS);
                window.location.href = window.location.origin + `/renewal/success?payment_id=${response.razorpay_payment_id}&community_id=${this.communityId}`; 
              }  
              else{
                this.router.navigate([`${ROOT_PATH}`]);
              }                
            })

            // this.subscriptionService.renewCommunityMemebership(`community_id=${this.communityId}`).subscribe(res=>{
            //   if(res.success){
            //     this.communityService.getCommunityFeedUrl(this.communityId).subscribe(res=>{
            //       if(res.success){
            //         //console.log("This is the res : ", res);
            //         this.localStorageService.setSavedState(res.feed_url, "feed_url");
            //         this.localStorageService.setSavedState( {'payment_id' : response.razorpay_payment_id , 'community_id' : this.communityId , "community_name" : this.communityName} , STORAGE_KEY.PAYMENT_ID_SUCCESS);
            //         window.location.href = window.location.origin + `/renewal/success?payment_id=${response.razorpay_payment_id}&community_id=${this.communityId}`; 
            //       }  
            //       else{
            //         this.router.navigate([`${ROOT_PATH}`]);
            //       }                
            //     })
            //   }   
            //   else{
            //     // bring him to home page
            //     this.router.navigate([`${ROOT_PATH}`]);             
            //   }
            // })
          }
          else{

            this.analyticsService.sendEvent(
              RENEWAL_FLOW.PAYMENT_FAILED ,{
                  community_id : this.communityId,
                  plan_duration : duration_in_months,
                  plan_name : duration_name,
                  plan_cost : cost,
                  payment_page_url : window.location.href,
                  payment_type : "renewal"
              }
            )

            this.localStorageService.setSavedState( {'payment_id' : response.razorpay_payment_id , 'community_id' : this.communityId} , STORAGE_KEY.PAYMENT_ID_FAIL);
            window.location.href = window.location.origin + `/renewal/failure?payment_id=${response.razorpay_payment_id}&community_id=${this.communityId}`;
          }
      
        },
          err=>{
            this.localStorageService.setSavedState( {'payment_id' : response.razorpay_payment_id , 'community_id' : this.communityId} , STORAGE_KEY.PAYMENT_ID_FAIL);
            window.location.href = window.location.origin + `/renewal/failure?payment_id=${response.razorpay_payment_id}&community_id=${this.communityId}`;
          }
        )

      };

      var rzp1 = new Razorpay(res['order']);
      rzp1.on('payment.failed', function (response){
        
      });

      rzp1.open();
    })
  }
}



