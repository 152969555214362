<div class="container d-flex flex-column" *ngIf="!isRemovedMemberMobile">
    <div class="header-logo d-flex justify-content-center" 
    *ngIf="!(((blockerData?.pending_communities?.length == 1) && (blockerData?.membership_expired_communities?.length == null)) || ((blockerData?.pending_communities?.length == null) && (blockerData?.membership_expired_communities?.length == 1)))">
        <img src="assets/images/png/LIKEMINDS_LOGO.png" alt="">
    </div>
    <div class="header" 
        *ngIf="(((blockerData?.pending_communities?.length == 1) && (blockerData?.membership_expired_communities?.length == null)) || ((blockerData?.pending_communities?.length == null) && (blockerData?.membership_expired_communities?.length == 1)))">
        <div *ngIf="(blockerData?.membership_expired_communities?.length == 1)" (click)="openExpiredCommunityPopup(blockerData?.membership_expired_communities[0])" class="d-flex flex-column align-items-center">
            <p class="heading">Your community</p>
            <p class="sub-heading">{{blockerData?.header}}</p>
            <div class="card d-flex flex-row justify-content-evenly heading-card">
                <img class="card-image" [src]="blockerData?.membership_expired_communities[0]?.image_url" [alt]="blockerData?.membership_expired_communities[0]?.name">
                <div class="card-content">
                    <p class="card-heading mb-0">{{blockerData?.membership_expired_communities[0]?.name}}</p>
                    <p class="card-sub-heading mb-0">Managed by {{blockerData?.membership_expired_communities[0]?.created_by}}</p> 
                    <p class="card-sub-heading mb-0">{{blockerData?.membership_expired_communities[0]?.members_count}} Members</p>
                </div>
                <img class="arrow" src="assets/images/svg/right-arrow-white.svg" alt="right-arrow">
            </div>
        </div>

        <div *ngIf="(blockerData?.pending_communities?.length == 1)" [routerLink]="['/community_detail/' + blockerData?.pending_communities[0]?.id]" class="d-flex flex-column align-items-center">
            <p class="heading">Your community</p>
            <p class="sub-heading">{{blockerData?.header}}</p>
            <div class="card d-flex flex-row justify-content-evenly heading-card">
                <img class="card-image" [src]="blockerData?.pending_communities[0]?.image_url" [alt]="blockerData?.pending_communities[0]?.name">
                <div class="card-content">
                    <p class="card-heading mb-0">{{blockerData?.pending_communities[0]?.name}}</p>
                    <p class="card-sub-heading mb-0">Managed by {{blockerData?.pending_communities[0]?.created_by}}</p> 
                    <p class="card-sub-heading mb-0">{{blockerData?.pending_communities[0]?.members_count}} Members</p>
                </div>
                <img class="arrow" src="assets/images/svg/right-arrow-white.svg" alt="right-arrow">
            </div>
        </div>

    </div>
    <div class="content d-flex flex-column">
        <img class="email-img" src="assets/images/svg/email-success.svg" alt="Email">
        <p class="renew">
           {{blockerData.title_1 }}
        </p>

        <div *ngIf="(blockerData?.pending_communities?.length >= 1) && (blockerData?.membership_expired_communities?.length == 1)" class="details text-center">
            {{blockerData?.sub_title_1.split("<<")[0] }} <a (click)="openExpiredCommunityPopup(blockerData?.membership_expired_communities[0])" >renew your membership</a>
        </div>

        <div *ngIf="(blockerData?.pending_communities?.length >= 1) && (blockerData?.membership_expired_communities?.length > 1)" class="details text-center">
            {{blockerData?.sub_title_1}} 
        </div>

        <div *ngIf="(blockerData?.pending_communities?.length >= 1) && (blockerData?.membership_expired_communities?.length == null)" class="details text-center">
            {{blockerData?.sub_title_1}}
        </div>
        <div *ngIf="(blockerData?.membership_expired_communities?.length == 1) && (blockerData?.pending_communities?.length == null)" class="details text-center">
            Your subscription to "{{blockerData?.membership_expired_communities[0]?.name}}" community has expired. <a (click)="openExpiredCommunityPopup(blockerData?.membership_expired_communities[0])" >Buy a membership plan</a> of your  choice to regain access to community events, chat rooms and member profiles. Want to leave? do it <a (click)="openExpiredCommunityPopup(blockerData?.membership_expired_communities[0])" >here.</a>                 
        </div>
        <div (click)="openRenewal()" *ngIf="((blockerData?.pending_communities?.length == null) && (blockerData?.membership_expired_communities?.length == 1))" class="membership-button">
            <button>CHOOSE MEMBERSHIP PLAN</button>
        </div>
    </div>

    <div class="multiple-cards d-flex justify-content-center flex-column" 
        *ngIf="!(((blockerData?.pending_communities?.length == 1) && (blockerData?.membership_expired_communities?.length == null)) || ((blockerData?.pending_communities?.length == null) && (blockerData?.membership_expired_communities?.length == 1)))">
        <div class="divider"></div>
        <div class="communities">
            <div class="community-name" *ngIf="blockerData?.membership_expired_communities?.length >= 1">
                Membership expired
            </div>
            <div class="cards-container d-flex flex-column justify-content-center">
                <div class="card card-full  d-flex flex-row justify-content-evenly"
                    *ngFor="let community of blockerData?.membership_expired_communities" (click)="openExpiredCommunityPopup(community)">
                    <img class="card-image" [src]="community?.image_url" [alt]="community?.name">
                    <div class="card-content">
                        <p class="card-heading mb-0">{{community?.name}}</p>
                        <p class="card-sub-heading mb-0">Managed by {{community?.created_by}}</p> 
                        <p class="card-sub-heading mb-0">{{community?.members_count}} Members</p>
                    </div>
                    <img class="arrow" src="assets/images/svg/right-arrow-white.svg" alt="right-arrow">
                </div>
            </div>
        </div>
        <div class="communities">
            <div class="community-name" *ngIf="blockerData?.pending_communities?.length >= 1" >
                Pending Approval
            </div>
            <div class="cards-container d-flex flex-column justify-content-center">
                <div class="card card-full d-flex flex-row justify-content-evenly" [routerLink]="['/community_detail/' + community.id]" *ngFor="let community of blockerData?.pending_communities">
                    <img class="card-image" [src]="community?.image_url" [alt]="community?.name">
                    <div class="card-content">
                        <p class="card-heading mb-0">{{community?.name}}</p>
                        <p class="card-sub-heading mb-0">Managed by {{community?.created_by}}</p> 
                        <p class="card-sub-heading mb-0">{{community?.members_count}} Members</p>
                    </div>
                    <img class="arrow" src="assets/images/svg/right-arrow-white.svg" alt="right-arrow">
                </div>
            </div>
        </div>
    </div>

    <!-- FOOTER -->
    <div class="footer d-flex flex-column">
        <div *ngIf="!((blockerData?.pending_communities?.length == null) && (blockerData?.membership_expired_communities?.length == 1))" class="footer-text">
            **Usually It takes upto 48 hours to get approved. If you have paid for the membership and in case you are not approved, your payment would be refunded.
        </div>
        <div class="help-text">
            Need help? <a href="mailto:hello@likeminds.community">Get in touch.</a> 
        </div>
    </div>
</div>

<!--  FOR REMOVED MEMBERS  -->

<div class="container d-flex flex-column" *ngIf="isRemovedMemberMobile">
    <!-- <div class="header-logo d-flex justify-content-center">
        <img src="assets/images/png/LIKEMINDS_LOGO.png" alt="">
    </div> -->
    <div class="header">
        <div class="d-flex flex-column align-items-center">
            <p class="heading">Your community</p>
            <br>
            <div class="card d-flex flex-row justify-content-evenly heading-card">
                <img class="card-image" [src]="removedMember?.image_url" [alt]="removedMember?.name">
                <div class="card-content">
                    <p class="card-heading mb-0">{{removedMember?.name}}</p>
                    <p class="card-sub-heading mb-0">Managed by {{removedMember?.created_by}}</p> 
                    <p class="card-sub-heading mb-0">{{removedMember?.members_count}} Members</p>
                </div>
                <img class="arrow" src="assets/images/svg/right-arrow-white.svg" alt="right-arrow">
            </div>
        </div>
    </div>

    <div class="content d-flex flex-column">        
        <img class="email-img" src="../../../../assets/images/svg/membership-blocked.png" alt="Likeminds" style="margin-bottom: 24px;">
        <p class="renew">
           Membership blocked
        </p>

        <div class="detail text-center" style="color: #666666;">
            Your membership for this community has been terminated by the community managers for the reason: “Spam”.
        </div>

    </div>


    <!-- FOOTER -->
    <div class="footer d-flex flex-column" style="    
        position: fixed;
        bottom: 35px;
        width: 100%;">
        <div class="help-text">
            <span style="color: #666666;">Need help? </span> <a href="mailto:hello@likeminds.community">Get in touch.</a> 
        </div>
    </div>
</div>