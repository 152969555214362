import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-explore-feed-pinned-chip',
  templateUrl: './explore-feed-pinned-chip.component.html',
  styleUrls: ['./explore-feed-pinned-chip.component.scss']
})
export class ExploreFeedPinnedChipComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
