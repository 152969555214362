import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UNSUBSCRIBE_EMAIL_NOTIFICATIONS } from 'src/app/shared/constants/api.constant';
import {STORAGE_KEY} from '../../shared/enums/storage-keys.enum';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceService extends BaseService{

  constructor(private httpClient: HttpClient) {
    super(httpClient);
    this.registerDevice();
  }

  registerDevice(): void {
    const user = JSON.parse(localStorage.getItem(STORAGE_KEY.LIKEMINDS_USER));
    const deviceId = this.deviceID;
    if (user && !deviceId) {
      window.sessionStorage.setItem(STORAGE_KEY.DEVICE_ID, `${user.id}_${new Date().getTime()}`);
    }
  }

  public get deviceID() {
    return window.sessionStorage.getItem(STORAGE_KEY.DEVICE_ID);
  }
/**
     * @function emailUnsubscribe
     * @param params
     * @description Service to make users unsubscribe emails
     */

emailUnsubscribe(params): Observable<any> {
  return this.post(params,null, UNSUBSCRIBE_EMAIL_NOTIFICATIONS);
}

    /**
     * @function fetchNotificationFlags
     * @param params
     * @description Service to fetch info about whther to show dm button or not, with new tag and dm count info
     */

    fetchNotificationFlags(params): Observable<any> {
      return this.get(params, UNSUBSCRIBE_EMAIL_NOTIFICATIONS);
  }

}
