<ng-container *ngIf="user['task'] === 'shareUrlPopup'">
    <div class="w-100 close">
        <img src="assets/images/svg/cross.svg" (click)="close()" class="float-end cursor-pointer" alt="Close">
    </div>

    <div class="p-4">
        <div > 
            <h2 class="invite-heading">Share community</h2>            
            <div class="d-flex flex-column">
                <div class="d-flex flex-column copy-link">
                    <div class="d-flex align-items-center copy-link__top">
                        <img class="cursor-pointer" src="assets/images/svg/copy.svg" (click)="copyToClipBoard(0)" alt="">
                        <h3>Copy public invite Link</h3>
                    </div>
                    <div class="copy-link__separator"></div>
                    <p class=" copy-link__para">Send on social platforms</p>
                    <div class="d-flex justify-content-between align-items-center media-container">
                        <a href="https://www.facebook.com/" target="_blank">
                            <img src="assets/images/svg/facebook.svg"  height="42px" alt="fb">
                        </a> 
                        <a href="https://www.linkedin.com/" target="_blank">
                            <img src="assets/images/svg/linkedin.svg" height="42px" alt="linkedIn">
                        </a> 
                        <a href="https://www.twitter.com/" target="_blank">
                            <img src="assets/images/svg/twitter.svg" height="42px" alt="twitter">
                        </a> 
                        <a href="https://web.whatsapp.com/" target="_blank">
                            <img src="assets/images/svg/whatsapp-logo.svg" height="57px" alt="linkedIn">
                        </a> 
                    </div>
                </div>  
            </div>
        </div>
    </div>
</ng-container>
