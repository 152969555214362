import { AuthEffects } from './auth.effect';
import { AppEffects } from './app.effect';
import { CommunityEffects } from './community.effect';
import { ChatroomEffects } from './chatroom.effect';

export const effects = [
    AuthEffects,
    AppEffects,
    CommunityEffects,
    ChatroomEffects
];