<div class="wrapper">
    <div class="wrapper-header">
        Enter New Option
        <div class="close cursor-pointer" (click)="close()">
            <img src="../../../../assets/images/svg/ch-img/close.svg" alt="close" />
        </div>
    </div>

    <p>Seems like what you are looking for is not listed here. Type your option below.</p>

    <div class="wrapper-body">
        <div class="searchbox">
            <input type="text" class="form-control" placeholder="Enter new option" [(ngModel)]="newOption" />
        </div>
    </div>

    <div class="wrapper-footer">
        <button type="button" (click)="saveChanges()">Submit</button>
    </div>
</div>
