<ng-container>
    <label class="fs14 c6 float-start ms-2"
        >{{ question?.question_title }} <span *ngIf="!question?.optional" class="text-danger">*</span></label
    >
    <ng-container *ngIf="!file">
        <mat-form-field class="simple-input file-upload w-100 mb-4" [ngClass]="{ invalid: control.errors && formSubmitted }">
            <input
                matInput
                [placeholder]="question?.help_text"
                (click)="upload(question?.id)"
                [formControl]="control"
                name="file-upload"
                readonly
                type="text"
                autocomplete="off"
                [required]="!question?.optional"
            />
            <img
                class="mat-suffix cursor-pointer"
                matSuffix
                (click)="upload(question?.id)"
                src="assets/images/svg/upload-icon.svg"
                alt="Upload"
            />
        </mat-form-field>
        <div class="invalid-feedback question-form-error ms-2 d-block text-start" *ngIf="control.errors && formSubmitted">
            This is a mandatory field
        </div>
    </ng-container>

    <div
        *ngIf="file"
        class="uploaded-file d-flex justify-content-between mb-3 w-100"
        [ngClass]="{ 'bg-white py-2 px-2 br8': screenType === 'mobile' }"
        [ngStyle]="{ 'box-shadow': boxShadow }"
    >
        <div class="file d-flex">
            <img src="assets/images/svg/document.svg" alt="" />
            <div class="file-detail ms-2">
                <p class="file-name fs14 text-start m-0" [title]="file?.name">{{ file?.name | ellipsisWord: 20 }}</p>
                <p class="m-0 fs12 text-start" style="color: #9b9b9b">
                    {{ file?.size | fileSize: 2 }}
                </p>
            </div>
        </div>
        <mat-progress-bar class="small m-auto me-2" mode="determinate" [value]="progress"> </mat-progress-bar>
        <img class="cursor-pointer" (click)="removeFile()" src="assets/images/svg/remove.svg" alt="" />
    </div>
</ng-container>
